.enrolling {
	counter-reset: biba;

	&__item {
		display: grid;
		grid-template-columns: 3fr 9fr;
		grid-gap: 16px;
		padding: 32px 0;
		align-items: flex-start;
		border-top: 1px solid $base800;

		@media (max-width: $desktop-small) {
			padding: 24px 0;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr 7fr;
			grid-gap: 8px 26px;
			padding: 16px 0;
		}

		&:last-child {
			border-bottom: 1px solid $base800;
		}

		&::before {
			counter-increment: biba;
			@include manrope-2xl;
			color: $primary600;
			grid-row: span 2;

			@media (max-width: $desktop-small) {
				@include manrope-xl;
			}
		}

		&:nth-child(-n + 9) {
			&::before {
				content: "0" counter(biba);
			}
		}

		&:nth-child(n + 10) {
			&::before {
				content: counter(biba);
			}
		}

		&__title {
			@include manrope-xs;
			grid-column: 2;

			@media (max-width: $desktop-small) {
				@include manrope-2xs;
			}

			@media (max-width: $tablet) {
				@include manrope-3xs;
			}

			@media (max-width: $mobile) {
				@include manrope-4xs;
			}
		}

		&__text {
			@include circe-xl;
			grid-column: 2;

			@media (max-width: $desktop-small) {
				@include circe-l;
			}

			@media (max-width: $tablet) {
				@include circe-m;
			}

			@media (max-width: $mobile) {
				@include circe-s;
			}
		}
	}
}