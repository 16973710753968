$parent: ".spoiler";
$toggler: #{$parent}__toggler;
$content: #{$parent}__content;
$initial: #{$parent}__initial;
$final: #{$parent}__final;
$flipper: #{$parent}__flipper;

.spoiler {
	--initial-height: 0px;

	&:not(&--hard) {
		#{$content} {
			@include transition-base;
		}
	}

	#{$content} {
		overflow: hidden;

		&:not(.is-active) {
			max-height: var(--initial-height);
		}
	}

	.spoiler__toggler.is-active + .spoiler__content {
		max-height: initial;
	}

	&--before-mobile {
		> #{$content} {
			@media (min-width: $mobile) {
				overflow: visible !important;
				max-height: initial !important;
			}
		}
	}

	&--before-tablet {
		> #{$content} {
			@media (min-width: $tablet) {
				overflow: visible !important;
				max-height: initial !important;
			}
		}
	}

	&--only-tablet {
		> #{$content} {
			@media (min-width: $tablet) {
				overflow: visible !important;
				max-height: initial !important;
			}

			@media (max-width: $mobile) {
				overflow: visible !important;
				max-height: initial !important;
			}
		}
	}

	#{$toggler} {
		cursor: pointer;
		user-select: none;
	}

	#{$flipper} {
		@include transition-base;
	}
}

#{$parent},
#{$toggler} {

	&.is-active,
	& &.is-active,
	& & &.is-active {
		#{$flipper} {
			transform: scaleY(-1);
		}

		#{$initial} {
			display: none;
		}

		#{$final} {
			display: block;
		}
	}
}

#{$parent},
#{$toggler} {

	&:not(.is-active),
	& &:not(.is-active),
	& & &:not(.is-active) {
		#{$initial} {
			display: block;
		}

		#{$final} {
			display: none;
		}
	}
}