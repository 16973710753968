.payment {
	display: grid;
	grid-template-columns: 7fr 10fr;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
	}

	&__left {
		.img-banner {
			height: 100%;

			@media (max-width: $tablet) {
				grid-template-columns: 1fr;
				grid-template-rows: min-content 1fr;
			}

			@media (max-width: $mobile) {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			@media (min-width: $mobile) {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}

	&__form-footer {
		display: flex;
		justify-content: space-between;
		@include circe-2xl(true);

		@media (max-width: $mobile) {
			justify-content: flex-end;
			grid-gap: 8px;
		}
	}

	&__right {
		background: $primary50;
		border-radius: 0px 12px 12px 0px;
		padding: 48px;
		display: flex;
		flex-direction: column;

		@media (max-width: $mobile) {
			padding: 24px;
		}

		.form-group {
			@media (max-width: $tablet) {
				flex-direction: column;
			}
		}
	}

	&__paysystems {
		display: flex;
		grid-gap: 16px;
		flex-wrap: wrap;

		img {
			height: 54px;
			width: auto;

			@media (max-width: $mobile) {
				height: 32px;
			}
		}
	}
}