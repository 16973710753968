.stats-cards {
	display: flex;
	grid-gap: 24px;

	@media (max-width: $mobile) {
		flex-direction: column;
	}

	&--green {
		--accent: #{$othergreen100};
	}

	&__card {
		flex: 1 1 0;
		padding: 16px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 4px;
		background: var(--accent);

		&__title {
			@include manrope-m;

			@media (min-width: $mobile) and (max-width: $desktop-small) {
				@include manrope-s;
			}
		}

		&__text {
			max-width: 330px;
			@include circe-xl;

			@media (max-width: $mobile) {
				@include circe-s;
			}
		}
	}
}