.detail-text,
%detail-text {
	@include circe-2xl;

	@media (max-width: $desktop-small) {
		@include circe-xl;
	}

	@media (max-width: $tablet) {
		@include circe-l;
	}

	@media (max-width: $mobile) {
		@include circe-m;
	}

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}

	p,
	ul,
	ol,
	img {
		margin: 16px 0;

		@media (max-width: $tablet) {
			margin: 12px 0;
		}
	}

	ul {
		list-style-position: inside;

		li {
			display: flex;
			margin: 0;

			&::before {
				content: "•";
				text-align: center;
				width: 24px;
			}
		}
	}

	ol {
		list-style: none;
		counter-reset: biba;

		li {
			display: grid;
			grid-template-columns: 24px 1fr;
			counter-increment: biba;

			> * {
				grid-column: 2;
			}

			&::before {
				content: counter(biba) ".";
			}
		}
	}

	h2 {
		@include manrope-xs;

		@media (max-width: $desktop-small) {
			@include manrope-2xs;
		}

		@media (max-width: $tablet) {
			@include manrope-4xs;
		}

		@media (max-width: $mobile) {
			@include manrope-3xs;
		}

		margin: 32px 0 16px 0;
	}
}

.detail-text a,
.news-detail__body__text a,
.stock-detail__text a {
  text-decoration: underline;
}

.detail-text a:hover,
.news-detail__body__text a:hover,
.stock-detail__text a:hover {
  text-decoration: none;
}