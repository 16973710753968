.contacts-info {
	display: flex;
	flex-direction: row;

	list-style-type: none;

	justify-content: space-between;

	@media (max-width: $tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 24px;
	}

	@media (max-width: $mobile) {
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
	}

	> li {
		display: flex;
		flex-direction: column;
		grid-gap: 4px;

		.circe-m-regular {
			color: $base500;
		}

		> ul {
			display: flex;
			flex-direction: row;
			grid-gap: 24px;

			list-style-type: none;

			> li .icon {
				display: block;
				width: 40px;
				height: 40px;

				background-color: $primary600;
			}
		}
	}
}

.contacts-main-content {
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-gap: 48px;

	@media (max-width: $mobile) {
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
	}

	.left {
		display: flex;
		flex-direction: column;
		grid-gap: 24px;

		.contacts-radio {
			padding: 8px;
			border-radius: 4px;
			border: 1px solid #9CA3AF;
			display: flex;
			width: fit-content;
			max-width: 100%;
	
			input {
				appearance: none;
			}
	
			label {
				padding: 12px 16px;
				border-radius: 4px;
				display: block;
	
				cursor: pointer;
	
				transition: background-color .2s ease, color .2s ease;
	
				&:not(:first-of-type) {
					margin-left: 4px;
				}
			}
	
			input:checked + label {
				background-color: $primary600;
				color: $base0;
			}
		}
	
		> ul {
			list-style-type: none;
	
			display: flex;
			flex-direction: column;
			grid-gap: 16px;
	
			li {
				display: flex;
				align-items: center;
				grid-gap: 8px;
	
				&.contact-address {
					&::before {
						content: "";
						width: 24px;
						height: 24px;
						display: block;
	
						background: url(../static/img/contacts/location.svg) no-repeat;
						background-size: contain;
					}
				}
	
				&.contact-telephone {
					&::before {
						content: "";
						width: 24px;
						height: 24px;
						display: block;
	
						background: url(../static/img/contacts/call.svg) no-repeat;
						background-size: contain;
					}
				}
	
				&.contact-mail {
					&::before {
						content: "";
						width: 24px;
						height: 24px;
						display: block;
	
						background: url(../static/img/contacts/email.svg) no-repeat;
						background-size: contain;
					}
				}
			}
		}
	}	
}

.contacts-department {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	justify-content: space-between;

	list-style-type: none;

	@media (max-width: $tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 24px;
	}

	@media (max-width: $mobile) {
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
	}

	li {
		display: flex;
		flex-direction: column;
		
		.circe-m-regular + .manrope-2xs {
			margin-top: 4px;
		}

		.manrope-2xs + .manrope-2xs {
			margin-top: 16px;
		}

		.manrope-2xs + .circe-m-regular {
			margin-top: 16px;
		}
	}
}

.contacts-tour {
	height: 500px;
	width: 100%;

	iframe {
		border: 0;
	}
}