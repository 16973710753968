.custom-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	grid-gap: calc(var(--grid-gap) * 2);
	@include circe-l;

	@media (max-width: $mobile) {
		@include circe-s;
	}

	@media (min-width: $mobile) {
		--grid-gap: 18px;
	}

	@media (max-width: $mobile) {
		--grid-gap: 12px;
	}

	.form-title {
		@include manrope-2xs;
	}

	.form-group {
		display: flex;
		grid-gap: var(--grid-gap);

		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: stretch;
			grid-gap: calc(var(--grid-gap) * 2);
		}

		.form-col {
			flex: 1 1 0;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			grid-gap: var(--grid-gap);

			@media (max-width: $mobile) {
				grid-gap: 0;

				* + *:not(.custom-input__label) {
					margin-top: var(--grid-gap);
				}
			}

			&:empty {
				@media (max-width: $tablet) {
					display: none;
				}
			}

			.form-row {
				display: flex;
				grid-gap: var(--grid-gap);

				@media (max-width: $tablet) {
					flex-direction: column;
				}

				&__1 {
					
				}

				.form-cell {
					flex: 1 1 0;
					display: flex;
					flex-wrap: wrap;
					grid-gap: calc(var(--grid-gap) / 2);
					align-items: flex-end;

					&--centered {
						justify-content: center;
					}

					&--big {
						flex: 3 1 0;
					}

					> *:not([class^=button]) {
						width: 100%;
					}
				}
			}
		}
	}
}