.why-us {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 48px;
	justify-content: space-between;

	@media (max-width: $desktop-small) {
		grid-gap: 24px;
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 16px;
	}

	&__item {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: min-content 1fr;
		grid-gap: 12px 40px;

		@media (max-width: $tablet) {
			grid-gap: 8px 16px;
		}

		@media (max-width: $mobile) {
			grid-gap: 8px 16px;
		}

		&__img {
			width: 128px;
			height: 128px;
			grid-column: 1;
			grid-row: 1 / -1;

			@media (max-width: $desktop-small) {
				width: 92px;
				height: 92px;
			}

			@media (min-width: $mobile) and (max-width: $tablet) {
				width: 48px;
				height: 48px;
			}

			@media (max-width: $mobile) {
				width: 64px;
				height: 64px;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		&__title {
			grid-column: 2;
			@include manrope-2xs;

			@media (max-width: $desktop-small) {
				@include manrope-3xs;
			}

			@media (max-width: $tablet) {
				@include manrope-4xs;
			}

			@media (max-width: $mobile) {
				@include circe-xl(true);
			}
		}

		&__text {
			grid-column: 2;
			@include circe-l;

			@media (max-width: $tablet) {
				@include circe-s;
			}
		}
	}
}