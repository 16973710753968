@import "icons";

.icon,
%icon {
	display: inline-flex;
	border: none;
	mask-size: cover;
	mask-repeat: no-repeat;
	mask-position: center;
	flex-shrink: 0;
	@include transition-base;
	width: var(--size, 24px);
	height: var(--size, 24px);
	position: relative;
	background: var(--color, #{$base900});
}

.icon--white,
%icon--white {
	display: inline-flex;
	border: none;
	mask-size: cover;
	mask-repeat: no-repeat;
	mask-position: center;
	flex-shrink: 0;
	@include transition-base;
	width: var(--size, 24px);
	height: var(--size, 24px);
	position: relative;
	background: var(--color, #{$base0});
}

.icon32,
%icon32 {
	display: inline-flex;
	border: none;
	mask-size: cover;
	mask-repeat: no-repeat;
	mask-position: center;
	flex-shrink: 0;
	@include transition-base;
	width: var(--size, 32px);
	height: var(--size, 32px);
	background: var(--color, #{$primary600});
}

.icon--functional__pay {
	background: url(../static/img/icon/functional/pay-1.svg);
}
.icon--kurs__check {
	background: url(../static/img/icon/informational/kurs-check.svg);
}