.img-banner {
	padding: 48px;
	background: #004CE4;
	border-radius: 12px;
	color: $base0;
	display: grid;
	grid-template-columns: 3fr 2fr;
	grid-gap: 94px;

	&--is-vertical {
		grid-template-columns: 1fr;
		grid-gap: 24px;
	}

	@media (min-width: $mobile) and (max-width: $tablet) {
		padding: 32px;
		grid-gap: 16px;
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		padding: 16px;
	}

	&__info {
		display: flex;
		flex-direction: column;
		grid-gap: 56px;

		@media (max-width: $desktop-small) {
			grid-gap: 24px;
		}

		@media (max-width: $mobile) {
			grid-gap: 16px;
		}
	}

	&__title {
		@include manrope-m;

		@media (max-width: $desktop-small) {
			@include manrope-s;
		}

		@media (max-width: $tablet) {
			@include manrope-xs;
		}

		@media (max-width: $mobile) {
			@include manrope-2xs;
		}
	}

	&__text {
		@include circe-2xl;

		@media (max-width: $desktop-small) {
			@include circe-xl;
		}

		@media (max-width: $tablet) {
			@include circe-m;
		}
	}

	&__link {
		margin-top: auto;
		@include circe-xl;

		> *:not(.button) {
			text-decoration: none;
			display: inline-block;
			line-height: 1;
			position: relative;

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background-color: #fff;
			}
		}

		&:hover {
			text-decoration: none;
		}

		&:empty {
			display: none;
		}
	}

	&__img {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}