.switch {
	display: inline-flex;
	grid-gap: 12px;
	--height: 22px;
	--size: 16px;

	input[type=checkbox],
	input[type=radio] {
		visibility: hidden;
		width: 40px;
		height: var(--height);
		appearance: initial;
		cursor: pointer;
		position: relative;

		&::before {
			visibility: visible;
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			border-radius: calc(var(--height) / 2);
			@include transition-base;
		}

		&::after {
			--offset: calc((var(--height) - var(--size)) / 2);
			visibility: visible;
			position: absolute;
			top: var(--offset);
			content: "";
			width: var(--size);
			height: var(--size);
			background: #FFFFFF;
			border-radius: 50%;
			@include transition-base;
		}

		&:not(:checked) {
			&::before {
				background: #DDE6EA;
			}

			&::after {
				left: var(--offset);
			}
		}

		&:checked {
			&::before {
				background: $primary600;
			}

			&::after {
				left: calc(100% - var(--offset));
				transform: translateX(-100%);
			}
		}
	}
}