.coursegroup-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 40px;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 24px;
	}
}

.coursegroup-card {
	padding: 32px;
	border-radius: 4px;
	grid-template-rows:  min-content min-content 1fr;
	background: $base100;
	display: grid;
	grid-gap: 16px 24px;
	grid-template-columns: 1fr 144px;

	&:not(:has(.coursegroup-card__img)) {
		grid-template-columns: 1fr;

		.coursegroup-card__img {
			grid-column: 1;
		}
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		padding: 24px;
	}

	&__title {
		@include manrope-2xs;
		grid-column: 1;

		@media (max-width: $desktop-small) {
			@include manrope-3xs;
		}
	}

	&__text {
		@include circe-l;
		grid-column: 1;

		@media (max-width: $desktop-small) {
			@include circe-m;
		}
	}

	&__button {
		grid-column: 1;
		align-items: flex-end;
		margin-top: auto;
	}

	&__img {
		@include absolute-container(1, 1);
		grid-column: 2;
		grid-row: 1 / -1;

		@media (max-width: $mobile) {
			display: none;
		}
	}
}