.mini-banner {
	background-size: auto 100%;
	background-position: center;
	background-repeat: no-repeat;
	$parent: &;

	@media (max-width: $mobile) {
		background-image: none !important;
	}

	&--inverted {
		color: $base0;
	}

	&--type-1 {
		color: $base0;

		#{$parent}__block {
			&:nth-child(1) {
				align-self: center;
				@include manrope-s;

				@media (max-width: $desktop-small) {
					@include manrope-xs;
				}

				@media (min-width: $mobile)and (max-width: $tablet-small) {
					grid-column: 1 / -1;
				}

				@media (max-width: $mobile) {
					@include manrope-4xs;
				}
			}

			&:nth-child(2) {
				padding: 6px 0;
				text-align: left;
				@include circe-xl;

				@media (max-width: $tablet) {
					@include circe-l;
				}

				@media (max-width: $mobile) {
					@include circe-s;
					display: block;
				}
			}

			&:nth-child(3) {
				padding: 6px 0;
			}
		}

		#{$parent}__inner {
			height: auto;
			padding-top: 24px;
			padding-bottom: 24px;
			grid-template-columns: 50% 1fr auto;
			align-items: flex-start;

			@media (max-width: $tablet) {
				grid-gap: 24px;
			}

			@media (max-width: $tablet-small) {
				grid-template-columns: 1fr auto;
				grid-gap: 16px;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
				grid-gap: 8px;
			}
		}
	}

	&--type-2 {
		#{$parent}__block {
			flex-direction: column;

			&:nth-child(1) {
				@media (max-width: $mobile) {
					display: none;
				}
			}

			&:nth-child(2) {
				align-items: flex-start;
				text-align: left;

				@media (max-width: $mobile) {
					display: block;
				}
			}

			&__big {
				@include circe-xl(true);

				@media (max-width: $mobile) {
					@include circe-l(true);
				}
			}

			&__small {
				@include circe-xl;

				@media (max-width: $tablet) {
					@include circe-l;
				}

				@media (max-width: $mobile) {
					@include circe-s;
				}
			}
		}

		#{$parent}__inner {
			padding-top: 16px;
			padding-bottom: 16px;
			height: auto;
			grid-gap: 24px;
			grid-template-columns: auto 1fr min-content;

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}
		}
	}

	&--type-3 {
		@extend .mini-banner--type-1;

		#{$parent}__inner {
			grid-template-columns: auto minmax(0, 540px) auto;

			@media (max-width: $tablet-small) {
				grid-template-columns: 1fr auto;
			}

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}
		}
	}

	&__inner {
		height: 72px;
		@extend %container;
		display: grid;
		grid-template-columns: 2fr 1fr 1fr;
		align-items: center;
		grid-gap: 32px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr 1fr;
			grid-gap: 12px;
			height: 56px;
		}
	}

	&__timer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		grid-gap: 4px;
		color: #000;
		@include manrope-3xs;

		@media (max-width: $mobile) {
			justify-content: flex-start;
			@include manrope-2xs;
		}

		&__block {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid #000;
			width: 40px;
			height: 40px;
		}
	}

	&__block {
		display: flex;
		align-items: center;

		&:nth-child(1) {
			@include manrope-3xs;

			@media (max-width: $mobile) {
				@include manrope-4xs;
			}
		}

		&:nth-child(2) {
			text-align: center;
			@include manrope-3xs;

			img {
				height: 100%;
				width: auto;
			}

			@media (max-width: $mobile) {
				display: none;
			}
		}

		&--small:nth-child(2) {
			// @include circe-xl;
		}

		&:nth-child(3) {
			text-align: right;
			justify-content: flex-end;

			@media (max-width: $mobile) {}
		}
	}
}