.header__search_popup {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	opacity: 1;
	@include transition-base;
	box-shadow: 0 3px 3px 1px #00000040;

	&:not(.show) {
		opacity: 0;
	}

	&__inner {
		@extend %container;
		display: flex;
	}

	&__top {
		height: 100px;
		display: flex;
		align-items: center;
		background: $base0;

		@media (max-width: $mobile) {
			height: 70px;
		}
	}

	&__bot {
		background: #f6f7f8;
		height: calc(100vh - 200px);
		display: flex;
		width: 100vw;

		@media (max-width: $mobile) {
			position: fixed;
			top: 100px;
			height: calc(100vh - 100px);
		}
	}

	&:not(:has(.header__search_popup__afterBot)) {
		.header__search_popup__bot {	
			@media (max-width: $mobile) {
				top: 70px;
				height: calc(100vh - 70px);
			}
		}
	}

	&__afterBot {
		position: relative;
		width: 100%;
		height: 100px;

		bottom: 0;
		background: #fff;
		display: flex;

		@media (max-width: $mobile) {
			position: fixed;
			top: 70px;
			height: 30px;

			.header__search_popup__inner .header__search_popup__field-1 a {
				padding: 0;
				background-color: transparent;
				border-radius: unset;
				color: var(--accent-color);
				text-decoration: underline;

				&:active {
					opacity: .5;
				}

				.icon {
					display: none;
				}
			}
		}
	}

	&__field {
		display: flex;
		grid-gap: 32px;
		align-items: center;
		width: 100%;

		input {
			width: 100%;
			@include manrope-3xs;
			border: none;
			outline: none;
		}
	}

	&__field-1 {
		display: flex;
		align-items: center;
		justify-content: right;
		width: 100%;
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		grid-gap: 48px;
		overflow: auto;
		padding: 24px 0;

		&__item {
			display: flex;
			flex-direction: column;
			grid-gap: 16px;

			@media (max-width: $tablet) {
				grid-gap: 8px;
			}

			&__title {
				@include manrope-2xs;
				text-decoration: underline;
				@include text-line-clip(2);

				@media (max-width: $desktop-small) {
					@include manrope-3xs;
				}

				@media (max-width: $tablet) {
					@include manrope-4xs;
				}

				@media (max-width: $mobile) {}

				&:hover {
					color: $primary600;
				}
			}

			&__text {
				@include circe-xl;
				@include text-line-clip(2);

				@media (max-width: $desktop-small) {
					@include circe-l;
				}

				@media (max-width: $tablet) {
					@include circe-m;
				}

				@media (max-width: $mobile) {
					height: 3em;
    				overflow: hidden;
					isolation: isolate;
				}
			}
		}
	}
}

.header__search {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 100;
	opacity: 1;
	@include transition-base;
	box-shadow: 0 3px 3px 1px #00000040;

	&:not(.show) {
		opacity: 0;
	}

	&__inner {
		@extend %container;
		display: flex;
	}

	&__top {
		height: var(--header-height, 134px);
		display: flex;
		align-items: center;
		background: $base0;
	}

	&__field {
		display: flex;
		grid-gap: 32px;
		align-items: center;
		width: 100%;

		input {
			width: 100%;
			@include manrope-3xs;
			border: none;
			outline: none;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		grid-gap: 48px;
		overflow: auto;
		padding: 24px 0;

		&__item {
			display: flex;
			flex-direction: column;
			grid-gap: 16px;

			@media (max-width: $tablet) {
				grid-gap: 8px;
			}

			&__title {
				@include manrope-2xs;
				text-decoration: underline;
				@include text-line-clip(2);

				@media (max-width: $desktop-small) {
					@include manrope-3xs;
				}

				@media (max-width: $tablet) {
					@include manrope-4xs;
				}

				@media (max-width: $mobile) {}

				&:hover {
					color: $primary600;
				}
			}

			&__text {
				@include circe-xl;
				@include text-line-clip(2);

				@media (max-width: $desktop-small) {
					@include circe-l;
				}

				@media (max-width: $tablet) {
					@include circe-m;
				}

				@media (max-width: $mobile) {
					height: 3em;
    				overflow: hidden;
					isolation: isolate;
				}
			}
		}
	}
}