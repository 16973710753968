.chip {
	padding: 8px 24px;
	border: 2px solid #181743;
	color: #181743;
	border-radius: 32px;
	background: #FFFFFF;
	display: inline-flex;
	font-family: 'Circe';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	// cursor: pointer;
	// user-select: none;

	&--active {
		background: #181743;
		color: #FFFFFF;
	}
}