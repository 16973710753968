.partners {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 32px;

	@media (max-width: $desktop-small) {
		grid-gap: 24px;
	}

	@media (max-width: $mobile) {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 24px 48px;
	}

	&__item {
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
}