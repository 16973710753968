.custom-checkbox,
%custom-checkbox {
	display: flex;
	align-items: center;
	cursor: pointer;
	grid-gap: 8px;
	@include circe-l;
	user-select: none;

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	@media (max-width: $tablet) {
		@include circe-s;
	}

	@media (max-width: $mobile) {
		grid-gap: 0;
	}

	--main-color: #{$base700};
	--secondary-color: #{$base0};
	--active-color: #{$base700};

	&--inverted {
		--main-color: #{$base0};
		--secondary-color: #{$base700};
		--active-color: #{$base0};
	}

	&--blue {
		--active-color: #{$primary600};
	}

	input[type="checkbox"] {
		width: 28px;
		height: 28px;
		position: relative;
		@include transition-base;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		visibility: hidden;
		--size: 21px;

		&::before {
			visibility: visible;
			position: absolute;
			content: "";
			width: var(--size);
			height: var(--size);
			border-radius: 4px;
			transition: all 0.3s ease;
			border-width: 3px;
			border-style: solid;
			border-color: var(--main-color);
		}

		&::after {
			visibility: visible;
			position: absolute;
			content: "";
			opacity: 0;
			transition: all 0.3s ease;
			@extend %icon, %icon--functional__check;
			width: var(--size);
			height: var(--size);
			border-radius: 4px;
			--color: var(--secondary-color);
		}

		&:not(:disabled) {
			cursor: pointer;

			&:hover,
			&:focus {
				&::before {
					filter: drop-shadow(0px 0px 3px var(--main-color));
					border-color: var(--main-color);
				}
			}

			&:checked {
				&::before {
					border-color: var(--active-color);
					background: var(--active-color);
				}
			}
		}

		&:checked {
			&::after {
				opacity: 1;
			}
		}

		&:disabled {
			~ * {
				pointer-events: none;
			}

			&::before {
				border-color: $base300;
			}

			&:checked {
				&::before {
					background: $base300;
				}
			}
		}

		@media (max-width: $mobile) {
			margin-right: 8px;
		}
	}
}