.news-header {
	display: grid;
	grid-template-columns: 830fr 581fr;
	grid-template-rows: min-content 1fr;
	align-items: flex-start;
	grid-gap: 24px;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
	}

	&__card {
		position: relative;
		$parent: &;

		&:nth-of-type(1) {
			grid-row: 1 / -1;
			display: grid;
			grid-template-columns: 1fr auto;
			grid-gap: 16px 100px;

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}

			#{$parent}__img {
				@media (max-width: $mobile) {
					@include absolute-container(343, 234);
				}

				@media (min-width: $mobile) {
					grid-column: 1 / -1;
					@include absolute-container(83, 40);
				}
			}

			#{$parent}__badge {
				position: absolute;
				top: 26px;
				left: 26px;

				@media (max-width: $mobile) {
					top: 13px;
					left: 13px;
				}

				.badge {
					@extend %badge--large;
				}
			}

			#{$parent}__title {
				@include manrope-xs;

				@media (max-width: $desktop-small) {
					@include manrope-2xs;
				}

				@media (max-width: $mobile) {
					@include manrope-3xs;
				}
			}

			#{$parent}__date {
				@include circe-2xl;

				@media (max-width: $tablet) {
					@include circe-l;
				}

				@media (max-width: $mobile) {
					display: none;
				}
			}
		}

		&:nth-of-type(n + 2) {
			grid-column: 2;
			display: grid;
			grid-template-columns: 264fr 279fr;
			grid-template-rows: min-content 1fr min-content;
			grid-gap: 12px 38px;

			@media (max-width: $mobile) {
				display: none;
			}

			@media (max-width: $desktop-small) and (min-width: $mobile) {
				grid-gap: 12px 24px;
			}

			#{$parent}__img {
				@include absolute-container(264, 188);
				grid-column: 1;
				grid-row: 1 / -1;
			}

			#{$parent}__badge {
				grid-column: 2;

				.badge {
					@extend %badge--small;
				}
			}

			#{$parent}__title {
				grid-column: 2;
				@include manrope-3xs;

				@media (max-width: $desktop-small) and (min-width: $mobile) {
					@include circe-l(true);
				}
			}

			#{$parent}__date {
				grid-column: 2;
				@include circe-l;
			}
		}

		&__title {
			@include transition-base;

			&:hover {
				color: $primary600;
			}
		}

		&__badge {
			.badge {
				@extend %badge--green
			}
		}

		&__img {
			border-radius: 4px;
		}

		&__date {
			color: $base400;
		}
	}
}