.stock-card {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	color: $base900;

	&__img {
		@include absolute-container(464, 364);
	}

	&__img-aspect {
		width: 100%;
		aspect-ratio: 1 / 1;
		overflow: hidden;
		border-radius: 4px;
		isolation: isolate;

		img {
			width: 100%;
			display: block;
		}
	}

	&:has(.stock-card__img-aspect) {
		padding: 24px 24px 32px 24px;
		border-radius: 4px;
		background-color: #fff;
	}

	&__title {
		@include manrope-2xs;

		&:hover {
			color: $primary600;
		}

		@media (max-width: $desktop-small) {
			@include manrope-3xs;
		}

		@media (max-width: $tablet) {
			@include manrope-4xs;
		}
	}

	&__text {
		@include circe-xl;

		@media (max-width: $desktop-small) {
			@include circe-l;
		}

		@media (max-width: $mobile) {
			@include circe-m;
		}
	}

	&__footer {
		margin-top: auto;

		a {
			@media (max-width: $mobile) {
				margin: 0 auto;
				display: block;
				width: fit-content;
			}
		}
	}
}

.stock-cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 64px 24px;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 24px;
	}
}