@import "@splidejs/splide/dist/css/splide-core.min";

[class*="splide--type-"] {
	.counter {
		@include circe-m;
		text-align: right;
		margin-bottom: 4px;

		@media (min-width: $mobile) {
			display: none;
		}

		&::before {
			content: attr(data-slide-current);
		}

		&::after {
			content: "/" attr(data-slide-total);
		}
	}
}

.splide,
%splide {
	--arrow-size: 52px;

	@media (max-width: $tablet) {
		--arrow-size: 32px;
	}

	@media (max-width: $mobile) {
		--arrow-size: 24px;
	}

	&__arrows {
		--arrow-size: 52px;

		@media (max-width: $tablet) {
			--arrow-size: 32px;
		}

		@media (max-width: $mobile) {
			--arrow-size: 24px;
		}
	}

	&__arrow {
		cursor: pointer;
		width: var(--arrow-size);
		height: var(--arrow-size);
		border: none;
		outline: none;
		@extend %icon;
		--size: var(--arrow-size);

		&:disabled {
			opacity: 0.4;
		}

		&::before {
			content: "";
		}

		&--prev {
			@extend %icon--arrow__chevron-left;
		}

		&--next {
			@extend %icon--arrow__chevron-right;
		}
	}

	&--type-1 {
		.splide {
			&__slide {
				@media (max-width: $mobile) {
					width: 56% !important;
				}
			}
		}
	}

	&--type-11 {
		.splide {
			&__slide {
				@media (max-width: $mobile) {
					width: 95% !important;
				}
			}
		}
	}

	&--type-1,
	&--type-10,
	&--type-11 {
		.splide {
			position: relative;
			padding: 0 calc(var(--arrow-size) + 24px);

			@media (max-width: $tablet) {
				padding: 0 calc(var(--arrow-size) + 16px);
			}

			@media (max-width: $mobile) {
				padding: 0;
			}

			&__arrow {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				&--prev {
					left: 0;
				}

				&--next {
					right: 0;
				}
			}

			&__track {
				@media (max-width: $mobile) {
					overflow: visible;
				}
			}
		}
	}

	&--type-2,
	&--type-7 {
		.splide {
			display: flex;
			align-items: center;
			gap: 24px;

			&__arrows {
				display: flex;
			}

			&__arrow {
				--color: #{$primary600};
			}

			&__track {
				width: 0;
				clip-path: inset(-100vw -100vw -100vw 0);
				overflow: visible;
				flex-grow: 1;
			}
		}
	}

	&--type-3 {
		.splide {
			display: flex;
			align-items: center;
			gap: 24px;

			&__arrows {
				display: flex;
			}

			&__track {
				width: 0;
				clip-path: inset(-100vw -100vw -100vw 0);
				overflow: visible;
				flex-grow: 1;
			}
			
			.news-card__img img {
				filter: grayscale(100%);

				transition: all .2s;
			}

			.news-card__img:hover {
				img {
					filter: grayscale(0%);
				}
			}
		}
	}

	&--type-4 {
		--v-padding: 24px;
		border-radius: 4px;

		@media (max-width: $desktop-small) {
			--v-padding: 16px;
		}

		.splide {
			display: flex;
			align-items: center;
			gap: 24px;
			padding: var(--v-padding) 32px;
			background: $base300;
			border-radius: 4px;


			@media (max-width: $mobile) {
				padding: var(--v-padding) 16px;
			}

			&::before {
				content: "";
				background: $base300;
				position: absolute;
				width: 100vw;
				top: 0;
				bottom: 0;
				left: 0;
				border-radius: 4px;
			}

			&__arrows {
				display: flex;
			}

			&__arrow {
				--color: #{$primary600};
			}

			&__track {
				width: 0;
				clip-path: inset(-100vw -100vw -100vw 0);
				overflow: visible;
				flex-grow: 1;
				
			}

			&__slide {
				width: 293px !important;

				@media (max-width: $desktop-small) {
					width: 232px !important;
				}

				/* @media (min-width: $mobile) and (max-width: $desktop-small) {
					width: 75%;
				} */
			}
		}
	}

	&--type-5_1 {
		.counter {
			display: none;
		}
	}

	&--type-5 {
		.counter {
			display: none;
		}

		.splide {
			position: relative;

			@media (min-width: $desktop-small) {
				padding: 0 calc(var(--arrow-size) + 24px);
			}

			@media (max-width: $mobile) {
				padding: 0;
			}

			&__arrows {
				@extend %d-not-middle;
			}

			&__arrow {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;

				&--prev {
					left: 0;
				}

				&--next {
					right: 0;
				}
			}
		}
	}

	&--type-6 {}

	&--type-7 {}

	&--type-8 {
		.splide {
			height: 100%;
		}
	}

	&--type-13 {
		
	}
}

.splide__slide.active {
	p {
		font-weight: 700;
	}

	span {
		color: $primary600;
	}

}

