.custom-radio {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	@include circe-l;
	user-select: none;
	cursor: pointer;

	input[type="radio"] {
		width: 28px;
		height: 28px;
		visibility: hidden;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		@include transition-base;
		--size: 21px;

		&::before {
			visibility: visible;
			position: absolute;
			content: "";
			width: var(--size);
			height: var(--size);
			border: 3px solid #3F474B;
			border-radius: 50%;
			transition: all 0.3s ease;
		}

		&::after {
			visibility: visible;
			position: absolute;
			content: "";
			border-radius: 50%;
			transition: all 0.3s ease;
			border: 5px solid #1877BC;
			opacity: 0;
		}

		&:not(:disabled) {

			&:hover,
			&:focus {
				&::before {
					border: 3px solid #1877BC;
					filter: drop-shadow(0px 0px 3px #83B2D5);
				}
			}
		}

		&:checked {
			&::before {
				border: 3px solid #1877BC;
			}

			&::after {
				opacity: 1;
			}
		}

		&:disabled {
			pointer-events: none;

			&::before {
				border: 3px solid #BEC7CC;
			}

			&:checked {
				&::after {
					border: 5px solid #BEC7CC;
				}
			}
		}
	}
}