.video-card {
	position: relative;
	@include absolute-container(4, 5);

	&::after,
	&::before {
		content: "";
		width: 100%;
		height: 128px;
		position: absolute;
		left: 0;
		z-index: 1;
		background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
	}

	&::before {
		top: 0;
	}

	&::after {
		bottom: 0;
		transform: scaleY(-1);
	}

	&__text {
		color: $base0;
		position: absolute;
		top: 24px;
		bottom: 24px;
		left: 24px;
		right: 24px;
		z-index: 2;

		&__post {
			@include manrope-4xs;
		}

		&__name {
			@include circe-l(true);
		}
	}

	&__play {
		border-radius: 50%;
		background: rgba($base800, 0.8);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80px;
		height: 80px;
		transform: translate(-50%, -50%);

		&::before {
			content: "";
			@extend %icon, %icon--arrow__play;
			--size: 40px;
			--color: #{$base0};
		}
	}
}