.benefits {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	grid-gap: 48px;

	@media (max-width: $desktop-small) {
		grid-gap: 56px;
	}

	@media (max-width: $mobile) {
		overflow: auto;
		justify-content: flex-start;
		grid-gap: 36px;
		margin-left: calc(var(--container-padding) * -1);
		margin-right: calc(var(--container-padding) * -1);
		padding-left: var(--container-padding);
		padding-right: var(--container-padding);
		margin-bottom: calc(var(--section-padding) * -1);
		padding-bottom: calc(var(--section-padding));
	}

	&__item {
		flex: 1 1 0;
		max-width: 320px;
		text-align: center;

		@media (max-width: $desktop-small) {
			max-width: 245px;
		}

		@media (max-width: $mobile) {
			max-width: 185px;
			flex: 0 0 185px;
		}

		&__img {
			position: relative;
			display: flex;
			justify-content: center;

			&__icon {
				position: relative;
				z-index: 1;
				width: 128px;
				height: 128px;

				@media (min-width: $mobile) and (max-width: $tablet) {
					width: 96px;
					height: 96px;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}

			&__bg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 180px;

				@media (min-width: $mobile) and (max-width: $tablet) {
					width: 120px;
				}

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		&__title {
			@include manrope-2xs;
			margin-bottom: 4px;

			@media (max-width: $desktop-small) {
				@include manrope-3xs;
			}

			@media (max-width: $tablet) {
				@include manrope-4xs;
			}

			@media (max-width: $mobile) {
				@include circe-l(true);
			}
		}

		&__text {
			@include circe-l;
			color: $base500;
			white-space: nowrap;

			@media (max-width: $mobile) {
				@include circe-s;
			}

			@media (max-width: $desktop-small) {
				font-size: 16px;
				white-space: normal;
			}

			br {
				@media (min-width: $desktop-small) {
					display: none;
				}
			}

			span {
				white-space: nowrap;
			}
		}
	}
}

.benefits::-webkit-scrollbar {
	width: 0;
}