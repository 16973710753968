.page {
	&__header {
		&__inner {
			margin-top: 24px;
			display: flex;
			flex-direction: column;
			grid-gap: 48px;

			@media (max-width: $desktop-small) {
				grid-gap: 32px;
			}

			@media (max-width: $tablet) {
				grid-gap: 24px;
			}

			@media (max-width: $mobile) {
				// grid-gap: 16px;
			}
		}

		&__title {
			@include manrope-m;

			@media (max-width: $desktop-small) {
				@include manrope-s;
			}

			@media (max-width: $tablet) {
				@include manrope-2xs;
			}
		}
	}
}