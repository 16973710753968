.badge,
%badge {
	font-weight: 400;
	padding: 4px 0;
	color: $base900;
	grid-gap: 4px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	font-family: 'Circe';
	background-color: var(--bg);

	@media (min-width: $mobile) and (max-width: $desktop-small) {
		// padding: 0;
	}

	.icon {
		--size: 16px;
		--color: #{$base900};
	}

	:only-child {
		margin-left: var(--horizontal-padding--only-child);
		margin-right: var(--horizontal-padding--only-child);
	}

	:not(:only-child) {
		&:first-child {
			margin-left: var(--horizontal-padding--not-only-child);
		}

		&:last-child {
			margin-right: var(--horizontal-padding--not-only-child);
		}
	}

	&--large {
		--horizontal-padding--only-child: 16px;
		--horizontal-padding--not-only-child: 12px;
		font-size: 16px;

		@media (max-width: $desktop-small) {
			font-size: 14px;
		}

		@media (max-width: $tablet) {
			font-size: 10px;
			--horizontal-padding--only-child: 8px;
		}
	}

	&--medium {
		--horizontal-padding--only-child: 16px;
		--horizontal-padding--not-only-child: 12px;
		font-size: 14px;
		line-height: 18px;
	}

	&--small {
		--horizontal-padding--only-child: 8px;
		--horizontal-padding--not-only-child: 8px;
		font-size: 10px;
		line-height: 14px;
	}

	&--pink {
		--bg: #{$otherred100};
	}

	&--green {
		--bg: #{$othergreen100};
	}

	&--yellow {
		--bg: #{$secondary100};
	}

	&--grey {
		--bg: #{$base200};
	}

	&--white {
		--bg: #{$base0};
	}

	&--arrow {
		:only-child {
			margin-left: 4px;
			margin-right: 4px;
		}

		@media (min-width: $mobile) {
			display: none;
		}

		&.active {
			div {
				transform: rotate(180deg);
			}
		}
	}

	&--not-visible {
		@media (max-width: $mobile) {
			display: none;
		}
	}
}