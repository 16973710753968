.button,
%button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	grid-gap: 8px;
	user-select: none;
	outline: none;
	cursor: pointer;
	position: relative;
	@include transition-base;
	white-space: nowrap;
	border: none;
	text-decoration: none;

	&:disabled,
	&.disabled {
		cursor: default;
		pointer-events: none;
	}

	// размер
	&--large {
		padding: var(--padding) 18px;
		border-radius: 4px;
		@include circe-xl(true);
		--padding: 14px;

		@media (max-width: $desktop-small) {
			--padding: 12px;
			padding: var(--padding) 12px;
			font-size: 16px;
			// @include circe-l(true);
		}

		@media (max-width: $tablet) {
			--padding: 14px;
			padding: var(--padding) 12px;
			@include circe-m(true);
		}

		.icon {
			--size: 16px;
		}
	}

	&--mainLarge {
		padding: var(--padding) 24px;
		border-radius: 4px;
		@include circe-xl(true);
		--padding: 18px;

		@media (max-width: $desktop-small) {
			--padding: 12px;
			padding: var(--padding) 12px;
			font-size: 16px;
			// @include circe-l(true);
		}

		@media (max-width: $tablet) {
			--padding: 14px;
			padding: var(--padding) 12px;
			@include circe-m(true);
		}

		.icon {
			--size: 16px;
		}
	}

	&--medium {
		padding: var(--padding) 16px;
		border-radius: 4px;
		@include circe-l(true);
		--padding: 12px;

		.icon {
			--size: 24px;
		}

		@media (max-width: $desktop-small) {
			padding: 7px 13px 7px 13px;
			font-size: 14px;

			.icon {
				--size: 24px;
			}
		}
	}

	&--small {
		padding: var(--padding) 12px;
		border-radius: 2px;
		@include circe-m(true);
		--padding: 8px;

		.icon {
			--size: 16px;
		}
	}

	&--square {
		padding: var(--padding);

		:not(.icon) {
			display: none;
		}
	}

	// применение цветов
	&--primary {
		background: var(--accent-color, $primary600);
		--color: #{$base0};
		color: var(--color);
	}

	&--primary_blue {
		background: var(--accent-color, $primary600);
		--color: #{$primary600};
		color: var(--color);
	}

	&--secondary {
		color: var(--accent-color);
		background: transparent;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: inherit;
			border: 2px solid var(--accent-color);
		}

		.icon {
			--color: var(--accent-color);
		}

	}

	&--text {
		color: var(--accent-color);
		background: transparent;
		border: none;

		.icon {
			--color: var(--accent-color);
		}

		&:not(:disabled):not(.disabled) {

			&:hover,
			&:active {
				background: $base100;
			}
		}
	}

	// цвета
	&--accent {
		&__blue {
			--accent-color: #{$primary600};

			&:hover {
				--accent-color: #{$primary700};
			}

			&:active {
				--accent-color: #{$primary900};
			}

			&:disabled,
			&.disabled {
				--accent-color: #{$primary100};
			}
		}

		&__white {
			--accent-color: #{$base0};
		}

		&__lightblue {
			--accent-color: #{$primary300};
		}

		&__black {
			--accent-color: #{$base900};
		}

		&__blue-light {
			--accent-color: #{$primary300};
		}

		&__green {
			--accent-color: #{$othergreen300};
		}

		&__pink {
			--accent-color: #{$otherred300};
		}

		&__yellow {
			--accent-color: #{$secondary900};
		}
	}

	&--theme {
		&__light {
			--color:#{$base0};
		}

		&__blue {
			--color:#{$primary600};
		}

		&__dark {
			--color: #{$base900};
		}
	}
}

.buttonMargin {
	display: flex;
	width: 100%;
	margin-top: 16px;
	justify-content: center;
}

.button.popup-controller {
	span, .icon {
		pointer-events: none;
	}
}