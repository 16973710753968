.text-spoiler {
	&.spoiler {
		--initial-height: 15em;
	}

	&.spoiler:not(.is-active) .spoiler__toggler {
		position: relative;
		z-index: 1;
		box-shadow: 0px -20px 20px 20px #fff;
		background: #fff;
	}

	.spoiler__toggler {
		@extend %text-color--grey;
	}
}