.about {
	&__page {
		@media (max-width: $mobile) {
			padding-top: 16px;
		}

		.breadcrumbs {
			margin-bottom: calc(-1 * (var(--section-padding) - 48px));

			@media (max-width: $mobile) {
				margin-bottom: 0;
			}
		}
	}

	&__text {
		@include circe-2xl;

		@media (max-width: $desktop-small) {
			@include circe-xl;
		}

		@media (max-width: $tablet) {
			@include circe-l;
		}

		@media (max-width: $mobile) {
			@include circe-m;
		}

		p {
			margin: 16px 0;
		}

		img {
			margin: 48px 0;
			display: block;
			height: auto;
		}

		:first-child {
			margin-top: 0;
		}

		:last-child {
			margin-bottom: 0;
		}
	}
}