.programs {
	.splide {
		&__list {
			@media (min-width: $mobile) {
				display: grid !important;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 24px;
			}
		}

		&__track {
			overflow: visible;
		}
	}

	&__item {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
		grid-gap: 80px;
		background: $primary300;
		padding: 32px 54px 32px 80px;
		border-radius: 4px;
		height: 100%;

		@media (max-width: $desktop-small) {
			padding: 24px 32px;
			grid-gap: 32px;
		}

		@media (max-width: $tablet) {
			padding: 16px;
			align-items: flex-start;
			grid-gap: 4px 16px;
		}

		@media (max-width: $mobile) {
			grid-gap: 8px 12px;
		}

		&__img {
			width: 96px;
			height: 96px;
			border-radius: 50%;
			background: $base0;

			@media (max-width: $tablet) {
				width: 60px;
				height: 60px;
			}

			@media (max-width: $mobile) {
				width: 44px;
				height: 44px;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		&__info {
			display: grid;
			grid-template-columns: auto auto;
			grid-template-rows: auto auto 1fr;
			align-items: flex-end;
			grid-gap: 8px;
			height: 100%;

			&__title {
				@include circe-2xl(true);
				grid-column: 1 / -1;
				align-self: flex-start;

				@media (max-width: $desktop-small) {
					@include circe-xl(true);
				}

				@media (max-width: $tablet) {
					@include circe-l(true);
				}

				@media (max-width: $mobile) {
					@include circe-m(true);
				}
			}

			&__text {
				@include circe-l;
				grid-column: 1 / -1;
				align-self: flex-start;

				@media (max-width: $desktop-small) {
					@include circe-m;
				}

				@media (max-width: $tablet) and (min-width: $mobile) {
					@include circe-s;
				}
			}

			&__price {
				grid-column: 1;
				@include circe-2xl(true);
				white-space: nowrap;

				@media (max-width: $tablet) {
					@include circe-l(true);
				}

				@media (max-width: $mobile) {
					@include circe-m(true);
				}
			}

			&__button {
				grid-column: 2;
				display: flex;
				justify-content: flex-end;

				.button {
					.icon {
						@extend %icon--arrow__chevron-right;
					}
				}
			}
		}
	}
}