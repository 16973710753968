.review-card {
	--bg: #{$base200};
	--small-text: inherit;
	background: var(--bg);
	padding: 32px;
	height: 100%;
	display: flex;
	flex-direction: column;
	grid-gap: 24px;

	@media (max-width: $desktop-small) {
		padding: 16px;
		grid-gap: 16px;
	}

	@media (max-width: $tablet) {
		grid-gap: 12px;
	}

	&--light {
		--bg: #{$base0};
		--small-text: #{$base500};
	}

	&__header {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: auto 1fr;
		align-items: flex-start;
		grid-gap: 4px 8px;

		a {
			grid-column: 1 / 3;
			grid-row: 2;
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			grid-template-rows: initial;
		}
	}

	&__name {
		grid-column: 1;
		@include circe-xl;

		@media (max-width: $tablet) {
			@include circe-m(true);
		}

		@media (max-width: $mobile) {
			@include circe-l;
		}
	}

	&__course {
		grid-column: 1;
		text-decoration: none;
		@include circe-m;
		color: $base500;

		&:hover {
			text-decoration: none;
		}

		@media (max-width: $tablet) {
			@include circe-s;
		}
	}

	&__badges {
		grid-column: 2;
		grid-row: 1 / -1;

		@media (max-width: $tablet) {
			grid-column: 1;
			grid-row: 1;
		}

		.badge {
			.icon {
				@extend %icon--functional__location;
			}
		}
	}

	&__text {
		@include circe-l;

		@media (max-width: $desktop-small) {
			@include circe-m;
		}

		@media (max-width: $tablet) {
			@include circe-s;
		}

		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 6;

		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__link {
		margin-top: auto;
		text-align: right;
		color: var(--small-text);

		a {
			@include circe-l;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}

			@media (max-width: $tablet) {
				@include circe-s;
			}
		}
	}
}

.reviews-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 24px;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 16px;
	}
}