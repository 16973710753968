.stock-detail {
	margin-top: 24px;

	display: flex;
	flex-direction: column;
	grid-gap: 32px;

	&__banner {
		background-size: cover;
		background-position: center;
		padding: 100px 58px;

		@media (max-width: $mobile) {
			background-image: none !important;
			padding: 0;
		}

		&__inner {
			display: flex;
			flex-direction: column;
			grid-gap: 32px;
			max-width: 715px;

			@media (max-width: $tablet) {
				grid-gap: 16px;
			}
		}

		&__title {
			@include manrope-m;

			@media (max-width: $desktop-small) {
				@include manrope-s;
			}

			@media (max-width: $tablet) {
				@include manrope-2xs;
			}

			@media (max-width: $mobile) {
				@include manrope-2xs;
			}
		}

		&__text {
			@include circe-2xl;

			@media (max-width: $desktop-small) {
				@include circe-xl;
			}

			@media (max-width: $tablet) {
				@include circe-l;
			}
		}
	}

	&__text {
		@extend %detail-text;
	}
}