.course-cards {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;

	max-width: 1200px;
	margin: 0 auto;

	@media (max-width: $desktop-small) {
		max-width: 870px;
	}

	@media (max-width: $mobile) {
		grid-gap: 16px;
	}
}

.course-card {
	padding: 32px;
	background: $base0;
	display: grid;
	grid-template-columns: 144px 1fr auto;
	grid-template-rows: min-content 1fr;
	align-items: flex-start;
	grid-gap: 16px 24px;

	&:not(:has(.course-card__img)) {
		grid-template-columns: 1fr auto;

		.course-card__name {
			grid-column: 1;
		}

		.course-card__badges {
			grid-column: 1;
		}
		
		.course-card__info {
			grid-column: 2;
		}
	}

	@media (max-width: $desktop-small) {
		padding: 20px 24px;
		grid-template-columns: 92px 1fr auto;
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr auto;
		padding: 24px 16px;
		grid-gap: 16px;
	}

	&__img {
		align-self: center;
		@include absolute-container(1, 1);
		grid-column: 1;
		grid-row: 1 / span 2;

		@media (max-width: $mobile) {
			display: none;
		}
	}

	&__name {
		@include manrope-3xs;
		grid-column: 2;
		@include transition-base;

		&:hover {
			color: $primary600;
		}

		@media (max-width: $desktop-small) {
			@include manrope-4xs;
		}

		@media (max-width: $mobile) {
			grid-column: 1;
			@include circe-s(true);
		}
	}

	&__badges {
		grid-column: 2;
		display: flex;
		grid-gap: 8px;
		flex-wrap: wrap;

		@media (max-width: $mobile) {
			grid-column: 1;
		}
	}

	&__info {
		align-self: flex-start;
		grid-column: 3;
		grid-row: 1 / span 2;
		display: flex;
		flex-direction: column;
		grid-gap: 12px;
		height: 100%;

		@media (min-width: $mobile) and (max-width: $desktop-small) {
			margin-top: -12px;
		}

		@media (max-width: $mobile) {
			grid-column: 2;
		}

		&__buttons {
			display: flex;
			flex-direction: column;
			align-items: center;
			grid-gap: 4px;

			&:only-child {
				height: 100%;
				justify-content: center;
			}
		}
	}

	&__iconed-text {
		display: flex;
		align-items: flex-end;
		grid-gap: 8px;

		.icon {
			--color: #{$base400};
		}

		&__texts {
			display: flex;
			flex-direction: column-reverse;

			span {
				&:nth-child(1) {
					@include circe-xl;

					@media (max-width: $desktop-small) {
						@include circe-l;
					}

					@media (max-width: $mobile) {
						@include circe-m;
					}
				}

				&:nth-child(n + 2) {
					text-align: right;
					color: $base400;
					text-decoration: line-through;
					@include circe-l;

					@media (max-width: $desktop-small) {
						@include circe-s;
					}
				}
			}
		}
	}
}

.course-card:nth-child(n + 6) {
	display: none;
}

.course-card.is-visible {
	display: grid;
}