.for-who {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	grid-gap: 111px;

	@media (max-width: $desktop-small) {
		grid-gap: 24px;
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 16px;
		align-items: flex-start;
	}

	&__item {
		display: flex;
		flex-direction: column;
		grid-gap: 16px;
		min-width: 0;

		@media (max-width: $mobile) {
			padding: 16px 0;
		}

		&__decoration {
			display: flex;
			align-items: center;
			grid-gap: 24px;

			

			@media (max-width: $tablet) {
				grid-gap: 16px;
			}

			@media (max-width: $mobile) {
				grid-gap: 8px;
			}

			&::before {
				content: "";
				height: 24px;
				width: 24px;
				border-radius: 50%;
				background: $primary600;

				@media (min-width: $mobile) and (max-width:$tablet) {
					height: 16px;
					width: 16px;
				}
			}

			&::after {
				content: "";
				opacity: 0.4;
				border-bottom: 2px dashed $primary600;
				flex-grow: 1;
			}
		}

		&__text {
			@include circe-xl;

			@media (max-width: $tablet) {
				@include circe-m;
			}

			@media (max-width: $mobile) {
				@include circe-s;
			}
		}
	}
}