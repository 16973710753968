@font-face {
	font-family: Circe;
	src: url(../static/font/circe/circe.woff2);
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: Circe;
	src: url(../static/font/circe/circe_bold.woff2);
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: Manrope;
	src: url('../static/font/manrope/Manrope-VariableFont_wght.woff2') format('woff2-variations');
	font-weight: 200 800;
	font-display: swap;
}

@mixin manrope-2xl {
	font-family: Manrope;
	font-weight: 800;
	font-size: 72px;
}

@mixin manrope-xl {
	font-family: Manrope;
	font-weight: 800;
	font-size: 60px;
}

@mixin manrope-l {
	font-family: Manrope;
	font-weight: 800;
	font-size: 56px;
}

@mixin manrope-m {
	font-family: Manrope;
	font-weight: 700;
	font-size: 48px;
}

@mixin manrope-s {
	font-family: Manrope;
	font-weight: 700;
	font-size: 40px;
}

@mixin manrope-xs {
	font-family: Manrope;
	font-weight: 700;
	font-size: 32px;
	line-height: 40px;
}

@mixin manrope-2xs {
	font-family: Manrope;
	font-weight: 700;
	font-size: 24px;
}

@mixin manrope-3xs {
	font-family: Manrope;
	font-weight: 700;
	font-size: 20px;
}

@mixin manrope-4xs {
	font-family: Manrope;
	font-weight: 700;
	font-size: 16px;
}

.manrope,
%manrope {
	&-2xl {
		@include manrope-2xl;
	}

	&-xl {
		@include manrope-xl;
	}

	&-l {
		@include manrope-l;
	}

	&-m {
		@include manrope-m;
	}

	&-s {
		@include manrope-s;
	}

	&-xs {
		@include manrope-xs;
	}

	&-2xs {
		@include manrope-2xs;
	}

	&-3xs {
		@include manrope-3xs;
	}

	&-4xs {
		@include manrope-4xs;
	}
}

@mixin circe-2xl($isBald: false) {
	font-family: Circe;
	font-size: 24px;

	@if $isBald {
		font-weight: 700;
	}

	@else {
		font-weight: 400;
	}
}

@mixin circe-xl($isBald: false) {
	font-family: Circe;
	font-size: 20px;

	@if $isBald {
		font-weight: 700;
	}

	@else {
		font-weight: 400;
	}
}

@mixin circe-l($isBald: false) {
	font-family: Circe;
	font-size: 16px;

	@if $isBald {
		font-weight: 700;
	}

	@else {
		font-weight: 400;
	}
}

@mixin circe-m($isBald: false) {
	font-family: Circe;
	font-size: 14px;

	@if $isBald {
		font-weight: 700;
	}

	@else {
		font-weight: 400;
	}
}

@mixin circe-s($isBald: false) {
	font-family: Circe;
	font-size: 12px;

	@if $isBald {
		font-weight: 700;
	}

	@else {
		font-weight: 400;
	}
}

.circe,
%circe {
	&-2xl {
		&-bold {
			@include circe-2xl(true);
		}

		&-regular {
			@include circe-2xl;
		}
	}

	&-xl {
		&-bold {
			@include circe-xl(true);
		}

		&-regular {
			@include circe-xl;
		}
	}

	&-l {
		&-bold {
			@include circe-l(true);
		}

		&-regular {
			@include circe-l;
		}
	}

	&-m {
		&-bold {
			@include circe-m(true);
		}

		&-regular {
			@include circe-m;
		}
	}

	&-s {
		&-bold {
			@include circe-s(true);
		}

		&-regular {
			@include circe-s;
		}
	}
}

.text-color,
%text-color {
	&--blue {
		color: $primary600;
	}

	&--grey {
		color: $base400;
	}

	&--green {
		color: $othergreen600;
	}

	&--yellow {
		color: $secondary900;
	}

	&--pink {
		color: $otherred300;
	}

	&--pink100 {
		color: $otherred100;	
	}
}

.text {
	&--right {
		text-align: right !important;
	}

	&--center {
		text-align: center !important;
	}

	&--justify {
		text-align: justify !important;
	}

	&--nowrap {
		white-space: nowrap !important;
	}

	&--underline {
		text-decoration: underline !important;

		&:hover {
			text-decoration: none !important;
			;
		}
	}

	&--bold {
		font-weight: 700 !important;
	}
}

a {
	color: inherit;
}