.header__menu-popup {
	height: calc(100vh - var(--header-height));
	overflow: auto;
	position: absolute;
	top: 100%;
	left: 0;
	background: #fff;
	width: 100vw;
	z-index: 2;
	@include transition-base;
	box-shadow: 0 3px 3px 1px #00000040;

	&:not(.show) {
		width: calc(100* var(--vw));
		opacity: 0;
		pointer-events: none;
	}

	&__inner {
		@extend %container;
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 2fr;
		grid-gap: 56px;
		padding-top: 24px;
		padding-bottom: 24px;

		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			content: "";
			height: 1px;
			background: $primary600;
			width: 100%;
		}

		&__col {
			display: flex;
			flex-direction: column;
			grid-gap: 40px;
		}

		&__block {
			display: flex;
			flex-direction: column;
			grid-gap: 24px;

			&__title {
				@include manrope-4xs;
			}

			&__items {
				display: flex;
				flex-direction: column;
				grid-gap: 20px;
			}

			&__item {
				color: $base600;
				@include circe-m;
				@include transition-base;

				a:hover {
					color: $primary600;
				}
			}

			&__buttons {
				display: flex;
				align-items: center;
				grid-gap: 16px;
				flex-wrap: wrap;
			}
		}
	}

	&__contacts {
		display: flex;
		flex-direction: column;
		grid-gap: 16px;

		&__item {
			display: flex;
			align-items: center;
			grid-gap: 8px;
			white-space: nowrap;

			.icon {
				--size: 16px;
				--color:#{$primary600};
			}
		}
	}
}