.learning-steps {
	display: grid;
	grid-template-columns: 5fr 8fr;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 16px;
	}

	&__list {
		&__item {
			display: grid;
			grid-template-columns: min-content 1fr;
			grid-gap: 4px 32px;
			@include circe-xl;

			@media (max-width: $tablet) {
				@include circe-m;
				grid-gap: 4px 16px;
			}

			@media (max-width: $mobile) {
				grid-gap: 0 16px;
				@include circe-s;
			}

			&__text {
				grid-column: 2;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@media (max-width: $mobile) {
					width: calc(100vw - 93px);
				}
				
				br {
					// display: none;
				}
			}

			&__title {
				@include manrope-2xs;

				@media (max-width: $tablet) {
					@include manrope-3xs;
				}

				@media (max-width: $mobile) {
					@include circe-l(true);
				}
			}

			&::before {
				counter-increment: biba;
				content: counter(biba);
				@include manrope-2xl;
				color: $primary600;
				grid-column: 1;
				grid-row: 1 / -1;
				align-self: center;
				width: 45px;

				@media (max-width: $desktop-small) {
					@include manrope-l;
				}

				@media (min-width: $mobile) and (max-width: $tablet) {
					width: 27px;
				}

				@media (max-width: $tablet) {
					@include manrope-s;
					text-align: center;
				}
			}
		}

		.splide {
			&__list {
				counter-reset: biba;

				@media (min-width: $tablet) {
					grid-gap: 24px;
				}

				@media (min-width: $mobile) {
					grid-gap: 16px;
					flex-direction: column;
					align-self: flex-start;
					display: flex !important;
				}
			}
		}
	}

	&__slider {
		@media (max-width: $mobile) {
			width: calc(100vw - 32px);
		}
	}
}