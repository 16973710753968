.chosen-by {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 32px;

	&__card {
		display: grid;
		grid-gap: 16px;
		background: $base0;
		padding: 32px;

		&__img {
			height: 66px;

			img {
				height: 100%;
				width: auto;
			}
		}

		&__name {
			@include circe-xl(true);
		}

		&__text {
			@include circe-l;
		}
	}
}