.person-card {
	padding: 24px 24px 32px 24px;
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	background: $base0;
	height: 100%;

	@media (max-width: $desktop-small) {
		padding: 16px;
	}

	@media (min-width: $mobile) and (max-width: $tablet) {
		margin: -16;
	}

	@media (max-width: $mobile) {
		padding: 24px;
	}

	&__img {
		@include absolute-container(10, 11);
		border-radius: 4px;

		@media (max-width: $mobile) {
			@include absolute-container(3, 4);
		}
	}

	&__name {
		@include manrope-2xs;

		@media (max-width: $desktop-small) {
			@include manrope-3xs;
		}

		@media (max-width: $tablet) {
			@include manrope-4xs;
		}

		@media (max-width: $mobile) {
			@include circe-2xl(true);
		}
	}

	&__post {
		color: $primary600;
		@include manrope-4xs;

		@media (max-width: $desktop-small) {
			@include circe-l;
		}

		@media (max-width: $tablet) {
			@include circe-m;
		}

		@media (max-width: $mobile) {
			@include circe-s;
		}
	}

	&__text {
		@include circe-l;

		@media (max-width: $desktop-small) {
			@include circe-m;
		}

		@media (max-width: $tablet) {
			@include circe-s;
		}
	}
}