header {
	background: #fff;
	position: relative;

	.header {
		display: flex;
		justify-content: space-between;
		padding: 23px 0;
		position: relative;

		@media (max-width: $desktop-small) {
			padding: 16px 0;
		}

		&__block {
			display: flex;
			align-items: center;
			grid-gap: 16px;
			color: $base900;
			--main-color: #{$base900};

			@media (max-width: 320px) {
				grid-gap: 8px;
			}

			.button {
				font-weight: 400;
			}
		}

		&__col {
			display: flex;
			flex-direction: column;
		}

		&__sublogo-link {
			text-decoration: underline;
			font-family: 'Circe';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;

			&:hover {
				text-decoration: none;
			}
		}

		a {
			display: flex;
		}

		&__logo {
			width: 220px;

			@media (max-width: $desktop-small) {
				width: 180px;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		&__tel a {
			@extend %button, %button--large, %button--text, %button--accent__blue;
			--padding: 0 !important;
		}

		&__profile-button {
			@extend %button, %button--medium, %button--text, %button--accent__black;
			padding-left: 0 !important;
			padding-right: 0 !important;
			padding: 0 !important;
		}
	}
}

