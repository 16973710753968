@mixin transition-base($property: all) {
	transition: $property .2s ease;
}

@mixin text-line-clip($lines) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@function proportion($width, $height) {
	@return math.div($height, $width) * 100%;
}

@mixin absolute-container($width, $height) {
	display: block;
	position: relative;
	height: 0;
	width: 100%;
	padding-bottom: proportion($width : $width, $height : $height);
	overflow: hidden;

	> :first-child {
		border-radius: inherit;
		mask-image: radial-gradient(#000, #000);
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
		object-position: center;
	}
}