.webinar-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
	align-items: flex-start;
	grid-gap: 24px;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
	}
}

.webinar-card {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	padding: 24px;
	background: $base0;
	border-radius: 4px;
	height: 100%;

	@media (max-width: $mobile) {
		padding: 24px 16px;
	}

	&__top {
		position: relative;
	}

	&__img {
		@include absolute-container(477, 274);
		border-radius: 4px;

		@media (max-width: $mobile) {
			@include absolute-container(295, 274);
		}

		&::after {
			content: "";
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
	}

	&__price {
		right: 16px;
		left: 16px;
		bottom: 14px;
		color: $base0;
		@include manrope-2xs;
		position: absolute;
		text-align: right;
	}

	&__badges {
		position: absolute;
		top: 16px;
		left: 12px;
		right: 12px;
		display: flex;
		flex-wrap: wrap;
		grid-gap: 8px;
	}

	&__title {
		@include circe-xl(true);

		@media (max-width: $desktop-small) and (min-width: $mobile) {
			@include circe-l(true);
		}
	}

	&__footer {
		margin-top: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $mobile) {
			flex-direction: column;
			grid-gap: 16px;
		}
	}

	&__person {
		display: grid;
		grid-template-columns: 48px 1fr;
		grid-gap: 0 12px;

		@media (max-width: $mobile) {
			align-self: flex-start;
		}

		&__img {
			grid-row: 1 / span 2;
			@include absolute-container(1, 1);
			border-radius: 50%;
			align-self: center;
		}

		&__post {
			@include circe-m;
			align-self: flex-end;
		}

		&__name {
			@include circe-m(true);
			align-self: flex-start;
		}
	}
}