.clients {
	.splide {
		&__list {
			@media (min-width: $mobile) {
				display: grid !important;
				grid-template-columns: repeat(3, 1fr);
				grid-gap: 24px;
			}

			@media (min-width: $mobile) and (max-width: $tablet) {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	&__item {
		height: 100%;
		display: flex;
		flex-direction: column;
		grid-gap: 16px;
		background: $base0;
		padding: 32px;

		@media (min-width: $mobile) and (max-width: $tablet) {
			padding: 16px;
			grid-gap: 8px;
		}

		&__img {
			height: 66px;

			img {
				height: 100%;
			}

			@media (min-width: $mobile) and (max-width: $tablet) {
				height: 48px;
			}
		}

		&__name {
			@include circe-xl(true);

			@media (min-width: $mobile) and (max-width: $tablet) {
				@include circe-l(true);
			}
		}

		&__description {
			@include circe-l;

			@media (min-width: $mobile) and (max-width: $tablet) {
				@include circe-m;
			}
		}

		&__link {
			margin-top: auto;

			.icon {
				@extend %icon--arrow__chevron-right;
			}
		}
	}
}