@use "sass:math";

.loyalty {
	&__benefits {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 24px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		&__card {
			padding: 48px;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			background: $primary600;

			@media (max-width: $desktop-small) {
				padding: 32px;
			}

			@media (max-width: $mobile) {
				padding: 16px;
				grid-gap: 16px;
			}

			&__img {
				@include absolute-container(613, 344);

				@media (min-width: $mobile) {
					margin-bottom: 24px;
				}

				@media (min-width: $desktop-small) {
					margin-bottom: 32px;
				}
			}

			&__title {
				color: $base0;
				@include manrope-xs;
				margin-bottom: auto;

				@media (max-width: $desktop-small) {
					@include manrope-2xs;
				}

				@media (max-width: $tablet) {
					@include manrope-3xs;
				}

				@media (max-width: $mobile) {
					@include manrope-4xs;
				}
			}

			&__footer {
				@media (min-width: $mobile) {
					margin-top: 32px;
				}

				@media (min-width: $desktop-small) {
					margin-top: 48px;
				}
			}
		}
	}

	&__steps {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 24px;
		counter-reset: biba;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			grid-gap: 16px;
		}

		&__item {
			padding: 32px;
			border-radius: 4px;
			display: grid;
			grid-gap: 16px;
			grid-template-columns: 1fr min-content;
			grid-template-rows: min-content 1fr;
			background: $base100;
			align-items: flex-start;

			@media (max-width: $mobile) {
				padding: 16px;
			}

			&__title {
				@include manrope-2xs;
				grid-column: 1;

				@media (max-width: $tablet) {
					@include manrope-3xs;
				}

				@media (max-width: $mobile) {
					@include manrope-4xs;
				}
			}

			&__text {
				@include circe-l;
				grid-column: 1;

				@media (max-width: $mobile) {
					@include circe-m;
				}
			}

			&::after {
				grid-row: 1 / -1;
				grid-column: 2;
				counter-increment: biba;
				content: counter(biba);
				color: $primary600;
				font-family: Manrope;
				font-size: 140px;
				font-weight: 700;
				line-height: 100%;

				@media (max-width: $mobile) {
					font-size: 82px;
				}
			}
		}
	}

	&__slider {
		@media (min-width: $mobile) and (max-width: $desktop-small) {
			$square-size: 200px;
			$actual-width: calc(math.sqrt(2) * $square-size);
			$overlay: calc(1 / 3);

			&__card {
				width: $actual-width;
				height: $actual-width;
				margin-right: calc(-1 * $actual-width * $overlay);

				&::before {
					width: $square-size;
					height: $square-size;
				}
			}
		}

		@media (min-width: $desktop-small) {
			$square-size: 240px;
			$actual-width: calc(math.sqrt(2) * $square-size);
			$overlay: calc(1 / 3);

			&__card {
				width: $actual-width;
				height: $actual-width;
				margin-right: calc(-1 * $actual-width * $overlay);

				&::before {
					width: $square-size;
					height: $square-size;
				}
			}
		}

		@media (max-width: $mobile) {
			$square-size: 160px;
			$actual-width: calc(math.sqrt(2) * $square-size);
			$overlay: calc(1 / 3);

			&__card {
				width: $actual-width;
				height: $actual-width;
				margin-right: calc(-1 * $actual-width * $overlay);

				&::before {
					width: $square-size;
					height: $square-size;
				}
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			position: relative;

			&--color {
				&__pink1 {
					--color: #{$otherred50};
				}

				&__pink2 {
					--color: #{$otherred100};
				}

				&__green1 {
					--color: #{$othergreen50};
				}

				&__green2 {
					--color: #{$othergreen100};
				}

				&__blue1 {
					--color: #{$primary50};
				}

				&__blue2 {
					--color: #{$primary100};
				}
			}

			&::before {
				content: "";
				position: absolute;
				z-index: -1;
				transform: rotate(45deg);
				background: var(--color);
			}

			&__big {
				@include manrope-xl;

				@media (max-width: $desktop-small) {
					@include manrope-l;
				}

				@media (max-width: $tablet) {
					@include manrope-m;
				}
			}

			&__small {
				@include circe-xl;

				@media (max-width: $desktop-small) {
					@include circe-l;
				}

				@media (max-width: $mobile) {
					@include circe-m;
				}
			}
		}

		.splide {
			&.is-initialized {
				.splide__list {
					@media (min-width: $tablet) {
						display: flex !important;
						justify-content: center;
					}
				}

				.splide__track {
					overflow: visible !important;
				}

				.splide__slide {
					&:last-child .loyalty__slider__card {
						margin-right: 0;
					}
				}
			}
		}
	}
}