.marquee {
	@media (max-width: $mobile) {
		margin-right: calc(var(--container-padding) * -1);
	}

	&__group {
		// display: flex;
	}

	&__row {
		padding: 24px 0;
		border-bottom: 1px solid $base800;

		@media (max-width: $mobile) {
			padding: 16px 0;
		}

		&:nth-child(1) {
			@include manrope-m;

			@media (max-width: $tablet) {
				@include manrope-s;
			}

			@media (max-width: $mobile) {
				@include manrope-xs;
			}
		}

		&:nth-child(2) {
			@include manrope-s;

			@media (max-width: $tablet) {
				@include manrope-xs;
			}

			@media (max-width: $mobile) {
				@include manrope-2xs;
			}
		}

		&:nth-child(3) {
			@include manrope-xs;

			@media (max-width: $tablet) {
				@include manrope-2xs;
			}

			@media (max-width: $mobile) {
				@include manrope-3xs;
			}
		}

		&:nth-child(4) {
			@include manrope-2xs;

			@media (max-width: $tablet) {
				@include manrope-3xs;
			}

			@media (max-width: $mobile) {
				@include circe-l(true);
			}
		}

		&--circle-filled .marquee__item {
			&::after {
				@extend %icon, %icon--geometry__circle-filled;
			}
		}

		&--square .marquee__item {
			&::after {
				@extend %icon, %icon--geometry__square;
			}
		}

		&--parallelogram .marquee__item {
			&::after {
				@extend %icon, %icon--geometry__parallelogram;
			}
		}

		&--circle .marquee__item {
			&::after {
				@extend %icon, %icon--geometry__circle;
			}
		}
	}

	&__item {
		display: flex;
		align-items: center;
		height: 100%;
		grid-gap: 24px;

		@media (max-width: $mobile) {
			grid-gap: 16px;
		}

		&::after {
			content: "";
			--color:#{$primary600} !important;
		}
	}
}