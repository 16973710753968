* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
	text-decoration: none;

	&::before,
	&::after {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		outline: none;
		text-decoration: none;
	}
}

img {
	// width: 100%;
	height: auto;
	max-width: 100%;
	object-fit: contain;
	display: block;
	flex-shrink: 0;
}

html {
	scroll-behavior: smooth;
	color: $base900;
	background: $base0;
	scroll-margin: calc(var(--header-height, 0) + 32px);
}

body {
	&.noscroll {
		overflow: hidden;
		top: calc(-1 * var(--scroll-position, 0));
		padding-right: var(--scrollbar-width, 0);
		position: fixed;
		width: 100%;
	}
}

.d-mobile,
%d-mobile {
	@media (min-width: $mobile) {
		display: none !important;
	}
}

.d-only-tablet,
%d-only-tablet {
	@media (max-width: $mobile) {
		display: none !important;
	}

	@media (min-width: $tablet) {
		display: none !important;
	}
}

.d-after-tablet,
%d-after-tablet {
	@media (max-width: $tablet) {
		display: none !important;
	}
}

.d-tablet,
%d-tablet {
	@media (min-width: $tablet) {
		display: none !important;
	}
}

.d-not-mobile,
%d-not-mobile {
	@media (max-width: $mobile) {
		display: none !important;
	}
}

.d-middle,
%d-middle {
	@media (max-width: $mobile) {
		display: none !important;
	}

	@media (min-width: $desktop-small) {
		display: none !important;
	}
}

.d-not-middle,
%d-not-middle {
	@media (min-width: $mobile) and (max-width: $desktop-small) {
		display: none !important;
	}
}

.tags {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 12px;
}

.simple-sticky {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 5;
}

.method-help-header {
	display: flex;
	grid-gap: 10px;
	justify-content: space-between;

	@media (max-width: $mobile) {
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
	}

	button {
		height: min-content;
		align-self: flex-end;

		@media (max-width: $mobile) {
			align-self: center;
		}
	}
}

.book-container {
	padding-top: 32px;
	padding-bottom: 60px;

	.pdf-holder {
		position: relative;
		width: 100%;
		max-width: 1010px;
		max-height: 910px;
		overflow-y: scroll;
		
		margin: 0 auto;
		display: block;
	
		aspect-ratio: 10 / 9;
		
		img {
			width: 100%;
			display: block;
		}
	}
}

h2:has(.manrope-xs) {
	line-height: 40px;
}

h2 {
	p + p {
		margin-top: 32px;
	}

	p + button {
		margin-top: 32px;
	}
}

.staff-detail-person-block {
	display: grid;
	grid-template-columns: 2fr 3fr;
	grid-gap: 48px;

	@media (max-width: $mobile) {
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
	}

	.left {
		aspect-ratio: 1 / 1;
		overflow: hidden;

		img {
			width: 100%;
			display: block;
		}
	}

	.right {
		p {
			font-family: Circe;
			font-size: 24px;
			font-weight: 400;
		}

		p + p {
			margin-top: 1em;
		}

		h4 {
			font-family: Circe;
			font-size: 32px;
			line-height: 100%;
			font-weight: 700;
		}

		h4 + p {
			margin-top: 1em;
		}
	}
}

.staff-detail-slider-pad {
	padding: 12px 44px;
	background-color: #fff;

	img {
		aspect-ratio: 2 / 3;
		display: block;
	}
}

.request-title {
	display: block;
	width: 624px;
	margin: 0 auto;

	text-align: center;

	@media (max-width: $mobile) {
		width: 100%;

		.circe-xl-regular {
			font-size: 16px;
			line-height: 120%;
		}
	}

	.manrope-xl {
		@media (max-width: $tablet) {
			font-size: 48px;
		}

		@media (max-width: $mobile) {
			font-size: 24px;
		}
	}

	ul {
		list-style-type: none;

		display: flex;
		justify-content: center;
		grid-gap: 32px;

		li a {
			width: 66px;
			height: 66px;

			@media (max-width: $mobile) {
				width: 48px;
				height: 48px;
			}
	
			// @media (max-width: $mobile) {
			// 	width: 32px;
			// 	height: 32px;
			// }
		}

		li:nth-child(1) a {
			background-color: #CD201F;
		}

		li:nth-child(2) a {
			background-color: #597DA3;
		}

		li:nth-child(3) a {
			background-color: #EE8208;
		}

		li:nth-child(4) a {
			background-color: #0088CC;
		}

		li:nth-child(5) a {
			background-color: #111827;
		}

		@media (max-width: $tablet) {
			grid-gap: 24px;
		}

		@media (max-width: $mobile) {
			grid-gap: 12px;
		}
	}
}

.section__header__block--right:has(.button) {
	.button {
		margin-top: 24px;
	}
}
				
.splide__arrow:disabled {
    opacity: 0 !important;
}

.splide__list:not(:has(li:nth-child(3))) {
	justify-content: center;

	li:nth-child(2) {
		margin-right: 0 !important;
	}

	@media (max-width: $mobile) {
		justify-content: unset;
	}
}

.splide__list:not(:has(li:nth-child(2))) {
	justify-content: center;

	li:nth-child(1) {
		margin-right: 0 !important;
	}
}

.splide__list:has(.webinar-card:nth-child(3)) {
	.webinar-card:not(:last-child) {
		margin-right: 24px;
	}
}