.video-block {
	@include absolute-container(16, 9);
	
	max-width: 1200px !important;
	padding-bottom: 40% !important;
	margin: 0 auto;

	@media (max-width: $tablet) {
		max-width: unset !important;
		padding-bottom: 56.25% !important;
	}
}