$primary900: #0015b0;
$primary800: #002dc2;
$primary700: #003acc;
$primary600: #004de6;
$primary300: #6ea5f7;
$primary100: #c4d9f7;
$primary50: #e8f2fc;
//
$secondary900: #FBA612;
$secondary600: #FFDE03;
$secondary300: #FFF06E;
$secondary100: #FFF9C2;
$secondary50: #FFFDE6;
//
$base900: #111827;
$base800: #1F2937;
$base700: #374151;
$base600: #4B5563;
$base500: #6B7280;
$base400: #9CA3AF;
$base300: #D1D5DB;
$base200: #E5E7EB;
$base100: #F3F4F6;
$base50: #F9FAFB;
$base0:#FFFFFF;
//
$otherred900: #C30010;
$otherred600: #EF002B;
$otherred300: #EE6F6F;
$otherred100: #FFCCD1;
$otherred50: #FFEBEE;
//
$othergreen900: #007300;
$othergreen600: #00D719;
$othergreen300: #A4DE78;
$othergreen100: #D9F1C6;
$othergreen50: #F0FAE8;
