@use "sass:math";
@import "breakpoint";
@import "variables";
@import "container";
@import "color";
@import "font";
@import "mixins";
@import "header";
@import "breadcrumbs";
@import "button";
@import "checkbox";
@import "footer";
@import "loyalty";
@import "general";
@import "form";
@import "icon";
@import "info-page";
@import "detail-text";
@import "modal";
@import "just-toggle";
@import "stock-detail";
@import "popup";
@import "header__search_popup";
@import "page";
@import "header__free_popup";
@import "news-detail";
@import "video-block";
@import "webinar-card";
@import "header__courses-popup";
@import "input";
@import "about";
@import "stats-cards";
@import "stock-card";
@import "radio";
@import "medium-person-card";
@import "section";
@import "badge";
@import "chip";
@import "how-is-card";
@import "tag";
@import "marquee";
@import "link";
@import "benefits";
@import "switch";
@import "review-card";
@import "text-card";
@import "advantages";
@import "programs";
@import "guarantees";
@import "partners";
@import "clients";
@import "small-advantages";
@import "enrolling";
@import "video-card";
@import "learning";
@import "program-grid";
@import "header-banner";
@import "person-card";
@import "price-card";
@import "img-banner";
@import "form-banner";
@import "for-who";
@import "../../modules/tabs/style.scss";
@import "../../modules/splide/style.scss";
@import "text-content";
@import "big-person-card";
@import "chosen-by";
@import "why-us";
@import "news-card";
@import "learning-steps";
@import "mini-banner";
@import "license-card";
@import "learning-card";
@import "header__menu-popup";
@import "catalog";
@import "mobile_menu_popup";
@import "qna-spoilers";
@import "course-card";
@import "payment";
@import "text-spoiler";
@import "news-grid";
@import "news-header";
@import "letter-slide-card";
@import "coursegroup-card";
@import "spoiler";
@import "indent-generator";
@import "contacts";

.index-links {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;

	a {
		@include manrope-2xs;
	}
}

body {
	-webkit-text-size-adjust: none;
}

.button {
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
}