.how-is-cards {
	display: flex;
	flex-direction: column;
	grid-gap: 70px;

	@media (max-width: $mobile) {
		grid-gap: 32px;
	}

	&--flipped {
		.how-is-card {
			&:nth-child(odd) {
				@media (min-width: $mobile) {
					grid-template-columns: 10fr 13fr;
				}
			}

			&:nth-child(even) {
				@media (min-width: $mobile) {
					grid-template-columns: 13fr 10fr;
				}
			}
		}
	}

	&:not(&--flipped) {
		.how-is-card {
			&:nth-child(odd) {
				@media (min-width: $mobile) {
					grid-template-columns: 13fr 10fr;
				}
			}

			&:nth-child(even) {
				@media (min-width: $mobile) {
					grid-template-columns: 10fr 13fr;
				}
			}
		}
	}
}

.how-is-card {
	display: grid;
	grid-gap: 54px;
	$parent: &;

	@media (max-width: $mobile) {
		grid-gap: 16px;
	}

	&:nth-child(even) {
		@media (min-width: $mobile) {
			> :nth-child(1) {
				order: 2;
			}

			> :nth-child(2) {
				order: 1;
			}
		}
	}

	&:nth-child(even) &__splide-arrows {
		justify-content: flex-end;
	}

	&__img {
		width: 100%;
		height: auto;

		img {
			width: 100%;
		}

		.text-card {
			grid-gap: 0;
			width: 100% !important;
		}

		// border-radius: 0 0 12px 12px;
		@media (max-width: $mobile) {
			order: 0;
		}

		overflow: hidden;
		box-shadow: 0 48px 74px -24px rgba(0, 0, 0, .18)
	}

	&__title {
		@include manrope-xs;

		@media (max-width: $desktop-small) {
			@include manrope-2xs;
		}

		@media (max-width: $tablet) {
			@include manrope-3xs;
		}

		@media (max-width: $mobile) {
			@include circe-l(true);
		}
	}

	&__description {
		@extend %text-content;
	}

	&__text {
		display: flex;
		flex-direction: column;
		grid-gap: 24px;

		@media (max-width: $tablet) {
			grid-gap: 16px;
		}

		@media (max-width: $mobile) {
			grid-gap: 8px;
		}
	}

	&__splide-arrows {
		margin-top: auto;
		display: flex;
	}
}