.medium-person-card {
	display: grid;
	grid-template-columns: 182px 1fr;
	grid-template-rows: min-content 1fr;
	grid-gap: 16px 48px;
	align-items: center;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-template-rows: unset;
	}

	&__img {
		@include absolute-container(1, 1);

		@media (min-width: $mobile) {
			grid-column: 1;
			grid-row: 1 / -1;
		}
	}

	&__name {
		@include manrope-xs;

		@media (min-width: $mobile) {
			grid-column: 2;
		}

		@media (max-width: $desktop-small) {
			@include manrope-2xs;
		}

		@media (max-width: $mobile) {
			@include manrope-3xs;
		}
	}

	&__text {
		@include circe-2xl;

		@media (min-width: $mobile) {
			grid-column: 2;
		}

		@media (max-width: $desktop-small) {
			@include circe-xl;
		}

		@media (max-width: $mobile) {
			@include circe-l;
		}
	}

	&__link {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}