.news-detail,
%news-detail {
	padding: 32px 0;
	display: flex;
	flex-direction: column;
	grid-gap: 32px;

	&__header {
		display: flex;
		flex-direction: column;
		grid-gap: 48px;

		&__top {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			grid-gap: 24px;

			a {
				@include circe-l;
			}
		}
	}

	&__date {
		color: $base400;
		@include circe-l;
	}

	&__title {
		@include manrope-m;

		@media (max-width: $desktop-small) {
			@include manrope-s;
		}

		@media (max-width: $tablet) {
			@include manrope-2xs;
		}
	}

	&__banner {
		@include absolute-container(1440, 528);
	}

	&__body {
		display: grid;
		grid-gap: 64px;

		@media (max-width: $tablet) {
			grid-template-columns: 1fr;
		}

		&__other {
			display: flex;
			flex-direction: column;
			grid-gap: 48px;

			@media (max-width: $tablet) {
				display: none;
			}
		}

		&__text {
			@extend %detail-text;

			img {
				margin: 64px 0;
			}
		}
	}
}

.news-detail-banner {
	margin: 64px 0;
}

.news-detail-slider {
	overflow: hidden;
}

.news-detail-btn {
	display: flex;
	justify-content: right;
}