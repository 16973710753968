.price-card {
	text-align: center;
	padding: 24px;
	background: $base0;
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	@include transition-base;
	color: var(--text-color);
	--time-color:#{$primary600};
	--text-color: #{$base900};
	--old-price-color:#{$base400};

	&:hover {
		background: $primary600;
		--time-color:#{$base0};
		--text-color: #{$base0};
		--old-price-color:#{$base100};

		.button {
			@extend %button--theme__blue, %button--accent__white;
		}
	}

	&__top {
		display: flex;
		flex-direction: column;
		grid-gap: 32px;
	}

	&__time {
		@include manrope-2xs;
		color: var(--time-color);
	}

	&__price {
		display: flex;
		flex-direction: column;
		grid-gap: 8px;

		span {
			&:first-child {
				@include manrope-s;

				@media (max-width: $desktop-small) {
					@include manrope-xs;
				}

				~ span {
					@include circe-2xl;
					text-decoration-line: line-through;
					color: var(--old-price-color);

					@media (max-width: $desktop-small) {
						@include circe-xl;
					}
				}
			}
		}
	}

	&__text {
		@include circe-l;
	}
}