@for $i from 0 through 16 {
	.p {
		&-#{$i} {
			padding: 8px * $i;
		}

		&t-#{$i} {
			padding-top: 8px * $i;
		}

		&r-#{$i} {
			padding-right: 8px * $i;
		}

		&b-#{$i} {
			padding-bottom: 8px * $i;
		}

		&l-#{$i} {
			padding-left: 8px * $i;
		}

		&y-#{$i} {
			padding-top: 8px * $i;
			padding-bottom: 8px * $i;
		}

		&x-#{$i} {
			padding-top: 8px * $i;
			padding-bottom: 8px * $i;
		}

		@media (max-width: $mobile) {
			&-sm-#{$i} {
				padding: 8px * $i;
			}

			&y-sm-#{$i} {
				padding-top: 8px * $i;
				padding-bottom: 8px * $i;
			}

			&l-sm-#{$i} {
				padding-left: 8px * $i;
			}

			&b-sm-#{$i} {
				padding-bottom: 8px * $i;
			}

			&r-sm-#{$i} {
				padding-right: 8px * $i;
			}

			&t-sm-#{$i} {
				padding-top: 8px * $i;
			}
		}
	}

	.m {
		&x-auto {
			margin-left: auto;
			margin-right: auto;
		}

		&y-auto {
			margin-top: auto;
			margin-bottom: auto;
		}

		&l-auto {
			margin-left: auto;
		}

		&r-auto {
			margin-right: auto;
		}

		&t-auto {
			margin-top: auto;
		}

		&b-auto {
			margin-bottom: auto;
		}

		&-auto {
			margin: auto;
		}

		&-#{$i} {
			margin: 8px * $i;
		}

		&t-#{$i} {
			margin-top: 8px * $i;
		}

		&r-#{$i} {
			margin-right: 8px * $i;
		}

		&b-#{$i} {
			margin-bottom: 8px * $i;
		}

		&l-#{$i} {
			margin-left: 8px * $i;
		}

		&y-#{$i} {
			margin-top: 8px * $i;
			margin-bottom: 8px * $i;

			@media (max-width: $mobile) {
				margin-top: math.div(8px * $i, 2);
				margin-bottom: math.div(8px * $i, 2);
			}
		}

		@media (max-width: $mobile) {
			&x-sm-auto {
				margin-left: auto;
				margin-right: auto;
			}

			&y-sm-auto {
				margin-top: auto;
				margin-bottom: auto;
			}

			&-sm-auto {
				margin: auto;
			}

			&-sm-#{$i} {
				margin: 8px * $i;
			}

			&t-sm-#{$i} {
				margin-top: 8px * $i;
			}

			&r-sm-#{$i} {
				margin-right: 8px * $i;
			}

			&b-sm-#{$i} {
				margin-bottom: 8px * $i;
			}

			&l-sm-#{$i} {
				margin-left: 8px * $i;
			}

			&y-sm-#{$i} {
				margin-top: 8px * $i;
				margin-bottom: 8px * $i;
			}
		}
	}
}