.advantages {
	padding-top: var(--section-padding);
	padding-bottom: var(--section-padding);
	background-image: url(../static/img/other/advantages-bg-green.svg);
	background-size: cover;
	background-position: center;

	&--red {
		background-image: url(../static/img/other/advantages-bg-red.svg);
	}

	&__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 32px;
	}

	&__items {
		display: flex;
		justify-content: center;

		@media (max-width: $mobile) {
			flex-direction: column;
		}
	}

	&__footer {
		&__link {
			@include circe-2xl;
			text-decoration: underline;
			cursor: pointer;

			&:hover {
				text-decoration: none;
			}

			@media (max-width: $desktop-small) {
				@include circe-xl;
			}

			@media (max-width: $tablet) {
				@include circe-m;
			}
		}
	}

	&__item {
		text-align: center;
		padding: 0 32px;
		max-width: 440px;

		@media (max-width: $mobile) {
			max-width: 255px;
			padding: 0;
		}

		&:nth-of-type(n + 2) {
			@media (min-width: $mobile) {
				border-left: 1px solid rgba($base900, 0.4);
			}
		}

		&__title {
			@include manrope-m;

			@media (min-width: $tablet) and (max-width: $desktop-small) {
				@include manrope-s;
			}

			@media (min-width: $mobile) and (max-width: $tablet) {
				@include manrope-2xs;
			}
		}

		&__text {
			@include circe-xl;

			@media (max-width: $desktop-small) {
				@include circe-l;
			}

			@media (max-width: $tablet) {
				@include circe-s;
			}
		}
	}
}

.glightbox.hidden {
	display: none;
}