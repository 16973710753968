.text-content,
%text-content {
	@include circe-xl;

	@media (max-width: $desktop-small) {
		@include circe-l;
	}

	@media (max-width: $mobile) {
		@include circe-m;
	}

	&__title {
		@include manrope-2xs;

		@media (max-width: $desktop-small) {
			@include manrope-4xs;
		}

		@media (max-width: $mobile) {
			@include circe-l(true);
		}
	}

	a:not(.button) {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	p {
		margin: 1em 0;

		@media (max-width: $desktop-small) {
			// margin: 12px 0;
		}

		@media (max-width: $mobile) {
			// margin: 8px 0;
		}
	}

	h2 {
		margin-top: 32px;
		margin-bottom: 16px;

		@media (max-width: $mobile) {
			margin: 16px 0;
		}
	}

	h3 {
		margin-top: 32px;
		margin-bottom: 12px;

		@media (max-width: $mobile) {
			margin: 16px 0;
		}
	}

	h4 {
		margin-top: 32px;
		margin-bottom: 12px;

		@media (max-width: $mobile) {
			margin: 16px 0;
		}
	}

	img {
		width: 100%;
		height: auto;
		margin: 1em 0;

		@media (max-width: $mobile) {
			// margin: 8px 0;
		}
	}

	ul,
	ol {
		margin: 1em 0;
		list-style-position: inside;

		@media (max-width: $mobile) {
			// margin: 8px 0;
		}

		li {
			+ li {
				margin-top: 0.5em;
			}
		}
	}

	:first-child {
		margin-top: 0;
	}

	:last-child {
		margin-bottom: 0;
	}
}