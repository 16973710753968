.small-advantages {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 12px;

	&__item {
		display: flex;
		align-items: center;
		grid-gap: 8px;

		&__img {
			width: 64px;
			height: 64px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&__text {
			@include circe-l;
		}
	}
}