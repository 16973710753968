.qna-spoilers {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 48px;
	$parent: &;

	@media (max-width: $tablet) {
		grid-template-columns: 1fr;
		grid-gap: 0;
	}

	&--wide {
		grid-template-columns: 1fr;
		grid-gap: 0;

		#{$parent}__item {
			&:last-child {
				border-bottom: 1px solid $primary300;
			}

			&__title {
				@include circe-2xl(true);

				@media (max-width: $desktop-small) {
					@include circe-xl(true);
				}
			}
		}

		.spoiler {
			&__content {
				color: $base500;
			}
		}
	}

	&__item {
		padding: 24px 0;
		border-top: 1px solid $primary300;

		.spoiler {
			&.is-active {
				.spoiler__toggler {
					.icon {
						transform: rotate(45deg)
					}
				}
			}
		}

		&__title {
			@include circe-xl(true);
			display: flex;
			align-items: center;
			justify-content: space-between;
			grid-gap: 16px;
		}

		&__content {
			padding-top: 16px;
			@include circe-l;
		}

		p,
		ul {
			margin: 8px 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			list-style-position: inside;

			li {
				display: flex;

				&::before {
					content: "•";
					text-align: center;
					width: 24px;
				}
			}
		}
	}
}

.qna-swiper {
	overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;

	.swiper-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    	box-sizing: content-box;
	}
}

.qna-pagination {
	@extend %container;
	width: 100%;
	display: flex;

	justify-content: space-between;

	margin-top: 32px;
	padding: 0 48px; 
	overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: $tablet-small) {
		padding: 0 16px;
	}

	@media (min-width: $tablet-small) {
		flex-wrap: wrap;
		max-width: 1200px;
		justify-content: center;
	}

	&--item {
		position: relative;
		padding: 8px 16px 4px 16px;
		height: 40px;
		cursor: pointer;

		a {
			// pointer-events: none;
			cursor: pointer;
		}

		&.is-active a button {
			font-weight: 700;
			color: #4C4C4C;

			&::after {
				content: "";
				background-color: #004CE4;
			}
		}
	}

	a button {
		width: 100%;
		user-select: none;
 		white-space: nowrap;
	
		border: 0;
		border-radius: 0;
		background-color: transparent;

		@media (max-width: 1259px) {
			font-size: 16px;
		}

		font-size: 20px;
		line-height: 28px;
		font-weight: 400;
		color: #9CA3AF;

		transition: color .2s ease;

		cursor: pointer;

		&::after {
			content: "";
			width: calc(100% - 8px);
			height: 3px !important;
			display: block;

			position: absolute;
			left: 4px;
			bottom: 0;

			background-color: #E5E7EB;
			border-radius: 2px;

			transition: background-color .2s ease;
		}	
	}
}