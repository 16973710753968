.section {
	position: relative;
	overflow: hidden;

	&--p0 {}

	&--bg {
		&__grey {
			background: $base100;
		}

		&__blue {
			background: $primary50;
		}

		&__green {
			background: $othergreen50;
		}

		&__pink {
			background: $otherred50;
		}

		&__yellow {
			background: $secondary50;
		}

		&__dark {
			background: $base800;
		}

		&__blue-middle {
			background: $primary300;
			color: $base0;
		}
	}

	&--is-vertical &__inner {
		@media (min-width: $mobile) {
			&--small-header {
				grid-template-columns: 1fr 3fr;
				grid-gap: 48px;
			}

			&--big-header {
				grid-template-columns: 3fr 1fr;
				grid-gap: 200px;
			}

			&:not(&--small-header):not(&--big-header) {
				grid-template-columns: 1fr 1fr;
				grid-gap: 130px;

				@media (max-width: $tablet) {
					grid-gap: 24px;
					grid-template-columns: 1fr 2fr;
				}
			}
		}

		display: grid;
	}

	&--is-vertical &__header {
		align-items: flex-start;
		grid-template-columns: 1fr;
		grid-template-rows: min-content 1fr;
		grid-gap: 48px;

		@media (max-width: $tablet) {
			grid-gap: 24px;
		}

		@media (max-width: $mobile) {
			grid-gap: 16px;
			grid-template-rows: auto;
		}

		.section__header__block--right {
			justify-content: flex-start;
		}
	}

	&--header-onlyleft &__header {
		grid-template-columns: 1fr;

		.section__header__block--right {
			display: none;
		}
	}

	&--no-header &__header {
		display: none;
	}

	&:not(&--p0) {
		padding-top: var(--section-padding);
		padding-bottom: var(--section-padding);
	}

	&__decorative-img {
		@extend %container;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: flex-end;

		img {
			width: 300px;

			@media (max-width: $desktop-small) {
				width: 180px;
			}

			@media (max-width: $tablet) {
				width: 120px;
			}
		}

		&--big {
			img {
				width: 500px;

				@media (max-width: $desktop-small) {
					width: 260px;
				}

				@media (max-width: $tablet) {
					width: 180px;
				}
			}
		}

		@media (max-width: $mobile) {
			display: none;
		}

		img {
			&[src=""] {
				display: none;
			}
		}
	}

	&__inner {
		display: grid;
		grid-gap: 48px;
		position: relative;

		@media (max-width: $desktop-small) {
			grid-gap: 32px;
		}

		@media (max-width: $tablet) {
			grid-gap: 24px;
		}

		@media (max-width: $mobile) {
			grid-gap: 16px;
		}

		> * {
			min-width: 0;
			width: 100%;
		}
	}
	
	&__header {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 64px;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			grid-gap: 16px;
		}

		&__block {
			@media (max-width: $mobile) {
				width: 100%;
			}

			&:empty {
				display: none;
			}

			&--small {
				@media (min-width: $mobile) {
					width: calc(100% / 3 * 2);
				}
			}

			&--left {
				@include manrope-m;

				@media (max-width: $desktop-small) {
					@include manrope-xs;
				}

				@media (max-width: $tablet) {
					@include manrope-3xs;
				}

				@media (max-width: $mobile) {
					@include manrope-2xs;
				}
			}

			&--right {
				margin-left: auto;
				@include circe-xl;

				br {
					display: none;
				}

				@media (max-width: $desktop-small) {
					@include circe-l;
				}

				@media (max-width: $tablet) {
					@include circe-m;
				}

				@media (max-width: $mobile) {
					@include circe-m;
				}
				
				&__toRight {
					text-align: right;

					@media (max-width: $mobile) {
						text-align: left;
					}
				}
			}
		}
	}

	&__content {}

	&__footer {
		display: flex;
		justify-content: center;
		grid-gap: 24px;

		&:empty {
			display: none;
		}

		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: center;
			grid-gap: 16px;
		}
	}

	&__block {
		&:empty {
			display: none;
		}
	}
}

.smallPad {
	section:first-child {
		padding-top: 32px;

		@media (max-width: $desktop-small) {
			padding-top: 16px;
		}

		@media (max-width: $mobile) {
			padding-top: 0;
		}
	}
}