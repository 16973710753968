.letter-slide-card {
	padding: 8px;
	background: $base0;
	display: block;

	&__img {
		@include absolute-container(4, 5);

		> img:first-child {
			object-fit: contain;
		}
	}
}