@charset "UTF-8";
:root {
  --header-margin: 24px;
  --button-height: 56px;
  --section-padding: 64px;
  --container-padding: 48px;
  --vw: calc(1vw - var(--scrollbar-width) / 100);
}
@media (max-width: 1440px) {
  :root {
    --section-padding: 32px;
  }
}
@media (max-width: 1260px) {
  :root {
    --container-padding: 16px;
  }
}
@media (max-width: 768px) {
  :root {
    --container-padding: 16px;
    --section-padding: 24px;
  }
}

.container,
.qna-pagination,
.header__menu-popup__inner,
.mini-banner__inner,
.header-banner1__content,
.header-banner__content,
.section__decorative-img,
.header__courses-popup__inner,
.header__search__inner,
.header__search_popup__inner {
  margin: 0 auto;
  max-width: calc(1440px + var(--container-padding) * 2);
  width: 100%;
  padding: 0 var(--container-padding);
}

@font-face {
  font-family: Circe;
  src: url(../static/font/circe/circe.woff2);
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: Circe;
  src: url(../static/font/circe/circe_bold.woff2);
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: Manrope;
  src: url("../static/font/manrope/Manrope-VariableFont_wght.woff2") format("woff2-variations");
  font-weight: 200 800;
  font-display: swap;
}
.manrope-2xl {
  font-family: Manrope;
  font-weight: 800;
  font-size: 72px;
}
.manrope-xl {
  font-family: Manrope;
  font-weight: 800;
  font-size: 60px;
}
.manrope-l {
  font-family: Manrope;
  font-weight: 800;
  font-size: 56px;
}
.manrope-m {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
.manrope-s {
  font-family: Manrope;
  font-weight: 700;
  font-size: 40px;
}
.manrope-xs {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.manrope-2xs {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
.manrope-3xs {
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
}
.manrope-4xs {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}

.circe-2xl-bold {
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
}
.circe-2xl-regular {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
.circe-xl-bold {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
}
.circe-xl-regular {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
.circe-l-bold {
  font-family: Circe;
  font-size: 16px;
  font-weight: 700;
}
.circe-l-regular {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.circe-m-bold {
  font-family: Circe;
  font-size: 14px;
  font-weight: 700;
}
.circe-m-regular {
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
}
.circe-s-bold {
  font-family: Circe;
  font-size: 12px;
  font-weight: 700;
}
.circe-s-regular {
  font-family: Circe;
  font-size: 12px;
  font-weight: 400;
}

.text-color--blue {
  color: #004de6;
}
.text-color--grey,
.text-spoiler .spoiler__toggler {
  color: #9CA3AF;
}
.text-color--green {
  color: #00D719;
}
.text-color--yellow {
  color: #FBA612;
}
.text-color--pink {
  color: #EE6F6F;
}
.text-color--pink100 {
  color: #FFCCD1;
}

.text--right {
  text-align: right !important;
}
.text--center {
  text-align: center !important;
}
.text--justify {
  text-align: justify !important;
}
.text--nowrap {
  white-space: nowrap !important;
}
.text--underline {
  text-decoration: underline !important;
}
.text--underline:hover {
  text-decoration: none !important;
}
.text--bold {
  font-weight: 700 !important;
}

a {
  color: inherit;
}

header {
  background: #fff;
  position: relative;
}
header .header {
  display: flex;
  justify-content: space-between;
  padding: 23px 0;
  position: relative;
}
@media (max-width: 1440px) {
  header .header {
    padding: 16px 0;
  }
}
header .header__block {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  color: #111827;
  --main-color: #111827;
}
@media (max-width: 320px) {
  header .header__block {
    grid-gap: 8px;
  }
}
header .header__block .button {
  font-weight: 400;
}
header .header__col {
  display: flex;
  flex-direction: column;
}
header .header__sublogo-link {
  text-decoration: underline;
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
header .header__sublogo-link:hover {
  text-decoration: none;
}
header .header a {
  display: flex;
}
header .header__logo {
  width: 220px;
}
@media (max-width: 1440px) {
  header .header__logo {
    width: 180px;
  }
}
header .header__logo img {
  width: 100%;
  height: auto;
}
header .header__tel a {
  --padding: 0 !important;
}
header .header__profile-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 0 !important;
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0;
  padding-top: 32px;
}
.breadcrumbs__item {
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.breadcrumbs__item:not(:last-child) {
  color: #9CA3AF;
  cursor: pointer;
}
.breadcrumbs__item:not(:last-child):hover {
  color: #004de6;
}
.breadcrumbs__item:not(:last-child)::after {
  content: "";
  --size: 16px;
  --color: #9CA3AF;
}
@media (max-width: 768px) {
  .breadcrumbs__item:not(:last-child):nth-child(n+3)::after {
    display: none;
  }
}
.breadcrumbs__item:last-child {
  color: #1F2937;
  pointer-events: none;
}
@media (max-width: 768px) {
  .breadcrumbs__item:nth-child(n+4) {
    display: none;
  }
}

.page__header .container .page__header__inner {
  margin-top: 0 !important;
}

@media (max-width: 768px) {
  .breadcrumbs {
    padding-top: 24px;
  }
}
.about__page .breadcrumbs {
  margin-bottom: 0;
}

.button,
.mobile_menu_popup__call a,
.tabs--frame .tabs__control input:checked ~ .tabs__control__visual,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual,
header .header__tel a,
header .header__profile-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  user-select: none;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
  white-space: nowrap;
  border: none;
  text-decoration: none;
}
.button:disabled, .button.disabled,
.mobile_menu_popup__call a:disabled,
.tabs--frame .tabs__control input:checked ~ .tabs__control__visual:disabled,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual:disabled,
header .header__tel a:disabled,
header .header__profile-button:disabled,
.mobile_menu_popup__call a.disabled,
.tabs--frame .tabs__control input:checked ~ .disabled.tabs__control__visual,
.tabs--frame .tabs__control input:not(:checked) ~ .disabled.tabs__control__visual,
header .header__tel a.disabled,
header .disabled.header__profile-button {
  cursor: default;
  pointer-events: none;
}
.button--large,
.mobile_menu_popup__call a,
header .header__tel a {
  padding: var(--padding) 18px;
  border-radius: 4px;
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
  --padding: 14px;
}
@media (max-width: 1440px) {
  .button--large,
.mobile_menu_popup__call a,
header .header__tel a {
    --padding: 12px;
    padding: var(--padding) 12px;
    font-size: 16px;
  }
}
@media (max-width: 1260px) {
  .button--large,
.mobile_menu_popup__call a,
header .header__tel a {
    --padding: 14px;
    padding: var(--padding) 12px;
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}
.button--large .icon,
.mobile_menu_popup__call a .icon,
header .header__tel a .icon {
  --size: 16px;
}
.button--mainLarge {
  padding: var(--padding) 24px;
  border-radius: 4px;
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
  --padding: 18px;
}
@media (max-width: 1440px) {
  .button--mainLarge {
    --padding: 12px;
    padding: var(--padding) 12px;
    font-size: 16px;
  }
}
@media (max-width: 1260px) {
  .button--mainLarge {
    --padding: 14px;
    padding: var(--padding) 12px;
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}
.button--mainLarge .icon {
  --size: 16px;
}
.button--medium,
header .header__profile-button {
  padding: var(--padding) 16px;
  border-radius: 4px;
  font-family: Circe;
  font-size: 16px;
  font-weight: 700;
  --padding: 12px;
}
.button--medium .icon,
header .header__profile-button .icon {
  --size: 24px;
}
@media (max-width: 1440px) {
  .button--medium,
header .header__profile-button {
    padding: 7px 13px 7px 13px;
    font-size: 14px;
  }
  .button--medium .icon,
header .header__profile-button .icon {
    --size: 24px;
  }
}
.button--small,
.tabs--frame .tabs__control input:checked ~ .tabs__control__visual,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual {
  padding: var(--padding) 12px;
  border-radius: 2px;
  font-family: Circe;
  font-size: 14px;
  font-weight: 700;
  --padding: 8px;
}
.button--small .icon,
.tabs--frame .tabs__control input:checked ~ .tabs__control__visual .icon,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual .icon {
  --size: 16px;
}
.button--square {
  padding: var(--padding);
}
.button--square :not(.icon) {
  display: none;
}
.button--primary,
.tabs--frame .tabs__control input:checked ~ .tabs__control__visual {
  background: var(--accent-color, #004de6);
  --color: #FFFFFF;
  color: var(--color);
}
.button--primary_blue {
  background: var(--accent-color, #004de6);
  --color: #004de6;
  color: var(--color);
}
.button--secondary {
  color: var(--accent-color);
  background: transparent;
}
.button--secondary::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 2px solid var(--accent-color);
}
.button--secondary .icon {
  --color: var(--accent-color);
}
.button--text,
.mobile_menu_popup__call a,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual,
header .header__tel a,
header .header__profile-button {
  color: var(--accent-color);
  background: transparent;
  border: none;
}
.button--text .icon,
.mobile_menu_popup__call a .icon,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual .icon,
header .header__tel a .icon,
header .header__profile-button .icon {
  --color: var(--accent-color);
}
.button--text:not(:disabled):not(.disabled):hover, .button--text:not(:disabled):not(.disabled):active,
.mobile_menu_popup__call a:not(:disabled):not(.disabled):hover,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual:not(:disabled):not(.disabled):hover,
header .header__tel a:not(:disabled):not(.disabled):hover,
header .header__profile-button:not(:disabled):not(.disabled):hover,
.mobile_menu_popup__call a:not(:disabled):not(.disabled):active,
.tabs--frame .tabs__control input:not(:checked) ~ .tabs__control__visual:not(:disabled):not(.disabled):active,
header .header__tel a:not(:disabled):not(.disabled):active,
header .header__profile-button:not(:disabled):not(.disabled):active {
  background: #F3F4F6;
}
.button--accent__blue,
.mobile_menu_popup__call a,
.form-banner--accent-blue .button,
.header-banner1--accent__blue .button,
.header-banner--accent__blue .button,
header .header__tel a {
  --accent-color: #004de6;
}
.button--accent__blue:hover,
.mobile_menu_popup__call a:hover,
.form-banner--accent-blue .button:hover,
.header-banner1--accent__blue .button:hover,
.header-banner--accent__blue .button:hover,
header .header__tel a:hover {
  --accent-color: #003acc;
}
.button--accent__blue:active,
.mobile_menu_popup__call a:active,
.form-banner--accent-blue .button:active,
.header-banner1--accent__blue .button:active,
.header-banner--accent__blue .button:active,
header .header__tel a:active {
  --accent-color: #0015b0;
}
.button--accent__blue:disabled, .button--accent__blue.disabled,
.mobile_menu_popup__call a:disabled,
.form-banner--accent-blue .button:disabled,
.header-banner1--accent__blue .button:disabled,
.header-banner--accent__blue .button:disabled,
header .header__tel a:disabled,
.mobile_menu_popup__call a.disabled,
.form-banner--accent-blue .disabled.button,
.header-banner1--accent__blue .disabled.button,
.header-banner--accent__blue .disabled.button,
header .header__tel a.disabled {
  --accent-color: #c4d9f7;
}
.button--accent__white,
.price-card:hover .button,
.footer .button {
  --accent-color: #FFFFFF;
}
.button--accent__lightblue {
  --accent-color: #6ea5f7;
}
.button--accent__black,
header .header__profile-button {
  --accent-color: #111827;
}
.button--accent__blue-light {
  --accent-color: #6ea5f7;
}
.button--accent__green,
.form-banner--accent-green-without-image .button,
.form-banner--accent-green .button,
.header-banner1--accent__green .button,
.header-banner--accent__green .button {
  --accent-color: #A4DE78;
}
.button--accent__pink,
.form-banner--accent-pink .button,
.header-banner1--accent__pink .button,
.header-banner--accent__pink .button {
  --accent-color: #EE6F6F;
}
.button--accent__yellow,
.form-banner--accent-yellow .button,
.header-banner1--accent__yellow .button,
.header-banner--accent__yellow .button {
  --accent-color: #FBA612;
}
.button--theme__light,
.form-banner--accent-pink .button,
.form-banner--accent-blue .button {
  --color:#FFFFFF;
}
.button--theme__blue,
.price-card:hover .button,
.footer .button {
  --color:#004de6;
}
.button--theme__dark,
.form-banner--accent-yellow .button,
.form-banner--accent-green-without-image .button,
.form-banner--accent-green .button,
.header-banner1--accent__green .button,
.header-banner--accent__green .button {
  --color: #111827;
}

.buttonMargin {
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: center;
}

.button.popup-controller span, .button.popup-controller .icon {
  pointer-events: none;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-gap: 8px;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
  --main-color: #374151;
  --secondary-color: #FFFFFF;
  --active-color: #374151;
}
.custom-checkbox a {
  text-decoration: underline;
}
.custom-checkbox a:hover {
  text-decoration: none;
}
@media (max-width: 1260px) {
  .custom-checkbox {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .custom-checkbox {
    grid-gap: 0;
  }
}
.custom-checkbox--inverted {
  --main-color: #FFFFFF;
  --secondary-color: #374151;
  --active-color: #FFFFFF;
}
.custom-checkbox--blue,
.catalog__filters__item__content__item .custom-checkbox {
  --active-color: #004de6;
}
.custom-checkbox input[type=checkbox] {
  width: 28px;
  height: 28px;
  position: relative;
  transition: all 0.2s ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  visibility: hidden;
  --size: 21px;
}
.custom-checkbox input[type=checkbox]::before {
  visibility: visible;
  position: absolute;
  content: "";
  width: var(--size);
  height: var(--size);
  border-radius: 4px;
  transition: all 0.3s ease;
  border-width: 3px;
  border-style: solid;
  border-color: var(--main-color);
}
.custom-checkbox input[type=checkbox]::after {
  visibility: visible;
  position: absolute;
  content: "";
  opacity: 0;
  transition: all 0.3s ease;
  width: var(--size);
  height: var(--size);
  border-radius: 4px;
  --color: var(--secondary-color);
}
.custom-checkbox input[type=checkbox]:not(:disabled) {
  cursor: pointer;
}
.custom-checkbox input[type=checkbox]:not(:disabled):hover::before, .custom-checkbox input[type=checkbox]:not(:disabled):focus::before {
  filter: drop-shadow(0px 0px 3px var(--main-color));
  border-color: var(--main-color);
}
.custom-checkbox input[type=checkbox]:not(:disabled):checked::before {
  border-color: var(--active-color);
  background: var(--active-color);
}
.custom-checkbox input[type=checkbox]:checked::after {
  opacity: 1;
}
.custom-checkbox input[type=checkbox]:disabled ~ * {
  pointer-events: none;
}
.custom-checkbox input[type=checkbox]:disabled::before {
  border-color: #D1D5DB;
}
.custom-checkbox input[type=checkbox]:disabled:checked::before {
  background: #D1D5DB;
}
@media (max-width: 768px) {
  .custom-checkbox input[type=checkbox] {
    margin-right: 8px;
  }
}

footer {
  margin-top: 64px;
  color: #FFFFFF;
  background: #0015b0;
  padding: 48px 0;
}
@media (max-width: 1440px) {
  footer {
    margin-top: 32px;
    padding: 32px 0;
  }
}
@media (max-width: 768px) {
  footer {
    padding: 24px 0;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  grid-gap: 56px;
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
}
.footer + .footer {
  margin-top: 24px;
}
@media (min-width: 768px) and (max-width: 1440px) {
  .footer {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    grid-gap: 0;
  }
}
.footer__logo {
  width: 100%;
  max-width: 220px;
}
.footer__social {
  display: flex;
  grid-gap: 24px;
}
.footer__social__item {
  --color: #FFFFFF;
  --size: 40px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .footer__social__item {
    --size: 32px;
  }
}
.footer__img-link {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .footer__img-link {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .footer__img-link {
    white-space: nowrap;
  }
}
.footer__img-link .icon {
  --size: 24px;
  --color:#FFFFFF;
}
.footer__column {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .footer__column {
    grid-gap: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1440px) {
  .footer__column:not(:nth-child(5)):not(:nth-child(4)) {
    grid-row: span 2;
  }
}
@media (max-width: 768px) {
  .footer__column {
    grid-gap: 0;
  }
}
@media (max-width: 768px) {
  .footer__column:nth-child(1) {
    order: 0;
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .footer__column:nth-child(1) .footer__block:first-of-type {
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .footer__column:nth-child(n+2) {
    order: 2;
  }
}
@media (min-width: 768px) and (max-width: 1440px) {
  .footer__column:nth-child(4) {
    grid-column: 4;
    grid-row: 2;
  }
}
@media (max-width: 768px) {
  .footer__column:last-child {
    order: 1;
    margin-bottom: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1440px) {
  .footer__column:last-child {
    grid-column: 4;
    grid-row: 1;
  }
  .footer__column:last-child .button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .footer__column:last-child .footer__block__content {
    flex-direction: row;
  }
}
.footer__column:last-child .footer__block__item {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .footer__column:last-child .footer__block__item {
    justify-content: flex-start;
  }
}
.footer__block__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .footer__block__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .footer__block .spoiler {
    border-bottom: 1px solid #FFFFFF;
  }
}
@media (max-width: 768px) {
  .spoiler .footer__block__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }
  .spoiler .footer__block__title .icon {
    --color: #FFFFFF;
  }
}
.footer__block__content {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
@media (max-width: 1260px) {
  .footer__block__content {
    grid-gap: 16px;
  }
}
@media (max-width: 768px) {
  .footer__block__content {
    grid-gap: 8px;
  }
}
.footer__block__content a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .spoiler .footer__block__content {
    padding: 12px 0;
  }
}
@media (min-width: 768px) {
  .footer__block__title {
    margin-bottom: 24px;
  }
}
.footer .button {
  white-space: nowrap;
}

.loyalty__benefits {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .loyalty__benefits {
    grid-template-columns: 1fr;
  }
}
.loyalty__benefits__card {
  padding: 48px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: #004de6;
}
@media (max-width: 1440px) {
  .loyalty__benefits__card {
    padding: 32px;
  }
}
@media (max-width: 768px) {
  .loyalty__benefits__card {
    padding: 16px;
    grid-gap: 16px;
  }
}
.loyalty__benefits__card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 56.1174551387%;
  overflow: hidden;
}
.loyalty__benefits__card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 768px) {
  .loyalty__benefits__card__img {
    margin-bottom: 24px;
  }
}
@media (min-width: 1440px) {
  .loyalty__benefits__card__img {
    margin-bottom: 32px;
  }
}
.loyalty__benefits__card__title {
  color: #FFFFFF;
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: auto;
}
@media (max-width: 1440px) {
  .loyalty__benefits__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 1260px) {
  .loyalty__benefits__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .loyalty__benefits__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .loyalty__benefits__card__footer {
    margin-top: 32px;
  }
}
@media (min-width: 1440px) {
  .loyalty__benefits__card__footer {
    margin-top: 48px;
  }
}
.loyalty__steps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  counter-reset: biba;
}
@media (max-width: 768px) {
  .loyalty__steps {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
.loyalty__steps__item {
  padding: 32px;
  border-radius: 4px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content 1fr;
  background: #F3F4F6;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .loyalty__steps__item {
    padding: 16px;
  }
}
.loyalty__steps__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  grid-column: 1;
}
@media (max-width: 1260px) {
  .loyalty__steps__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .loyalty__steps__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.loyalty__steps__item__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  grid-column: 1;
}
@media (max-width: 768px) {
  .loyalty__steps__item__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.loyalty__steps__item::after {
  grid-row: 1/-1;
  grid-column: 2;
  counter-increment: biba;
  content: counter(biba);
  color: #004de6;
  font-family: Manrope;
  font-size: 140px;
  font-weight: 700;
  line-height: 100%;
}
@media (max-width: 768px) {
  .loyalty__steps__item::after {
    font-size: 82px;
  }
}
@media (min-width: 768px) and (max-width: 1440px) {
  .loyalty__slider__card {
    width: 282.8427124746px;
    height: 282.8427124746px;
    margin-right: -94.2809041582px;
  }
  .loyalty__slider__card::before {
    width: 200px;
    height: 200px;
  }
}
@media (min-width: 1440px) {
  .loyalty__slider__card {
    width: 339.4112549695px;
    height: 339.4112549695px;
    margin-right: -113.1370849898px;
  }
  .loyalty__slider__card::before {
    width: 240px;
    height: 240px;
  }
}
@media (max-width: 768px) {
  .loyalty__slider__card {
    width: 226.2741699797px;
    height: 226.2741699797px;
    margin-right: -75.4247233266px;
  }
  .loyalty__slider__card::before {
    width: 160px;
    height: 160px;
  }
}
.loyalty__slider__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.loyalty__slider__card--color__pink1 {
  --color: #FFEBEE;
}
.loyalty__slider__card--color__pink2 {
  --color: #FFCCD1;
}
.loyalty__slider__card--color__green1 {
  --color: #F0FAE8;
}
.loyalty__slider__card--color__green2 {
  --color: #D9F1C6;
}
.loyalty__slider__card--color__blue1 {
  --color: #e8f2fc;
}
.loyalty__slider__card--color__blue2 {
  --color: #c4d9f7;
}
.loyalty__slider__card::before {
  content: "";
  position: absolute;
  z-index: -1;
  transform: rotate(45deg);
  background: var(--color);
}
.loyalty__slider__card__big {
  font-family: Manrope;
  font-weight: 800;
  font-size: 60px;
}
@media (max-width: 1440px) {
  .loyalty__slider__card__big {
    font-family: Manrope;
    font-weight: 800;
    font-size: 56px;
  }
}
@media (max-width: 1260px) {
  .loyalty__slider__card__big {
    font-family: Manrope;
    font-weight: 700;
    font-size: 48px;
  }
}
.loyalty__slider__card__small {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .loyalty__slider__card__small {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .loyalty__slider__card__small {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (min-width: 1260px) {
  .loyalty__slider .splide.is-initialized .splide__list {
    display: flex !important;
    justify-content: center;
  }
}
.loyalty__slider .splide.is-initialized .splide__track {
  overflow: visible !important;
}
.loyalty__slider .splide.is-initialized .splide__slide:last-child .loyalty__slider__card {
  margin-right: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
}
*::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
  display: block;
  flex-shrink: 0;
}

html {
  scroll-behavior: smooth;
  color: #111827;
  background: #FFFFFF;
  scroll-margin: calc(var(--header-height, 0) + 32px);
}

body.noscroll {
  overflow: hidden;
  top: calc(-1 * var(--scroll-position, 0));
  padding-right: var(--scrollbar-width, 0);
  position: fixed;
  width: 100%;
}

@media (min-width: 768px) {
  .d-mobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .d-only-tablet {
    display: none !important;
  }
}
@media (min-width: 1260px) {
  .d-only-tablet {
    display: none !important;
  }
}

@media (max-width: 1260px) {
  .d-after-tablet {
    display: none !important;
  }
}

@media (min-width: 1260px) {
  .d-tablet {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .d-not-mobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .d-middle {
    display: none !important;
  }
}
@media (min-width: 1440px) {
  .d-middle {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .d-not-middle,
.splide--type-5 .splide__arrows {
    display: none !important;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
}

.simple-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
}

.method-help-header {
  display: flex;
  grid-gap: 10px;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .method-help-header {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}
.method-help-header button {
  height: min-content;
  align-self: flex-end;
}
@media (max-width: 768px) {
  .method-help-header button {
    align-self: center;
  }
}

.book-container {
  padding-top: 32px;
  padding-bottom: 60px;
}
.book-container .pdf-holder {
  position: relative;
  width: 100%;
  max-width: 1010px;
  max-height: 910px;
  overflow-y: scroll;
  margin: 0 auto;
  display: block;
  aspect-ratio: 10/9;
}
.book-container .pdf-holder img {
  width: 100%;
  display: block;
}

h2:has(.manrope-xs) {
  line-height: 40px;
}

h2 p + p {
  margin-top: 32px;
}
h2 p + button {
  margin-top: 32px;
}

.staff-detail-person-block {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 48px;
}
@media (max-width: 768px) {
  .staff-detail-person-block {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}
.staff-detail-person-block .left {
  aspect-ratio: 1/1;
  overflow: hidden;
}
.staff-detail-person-block .left img {
  width: 100%;
  display: block;
}
.staff-detail-person-block .right p {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
.staff-detail-person-block .right p + p {
  margin-top: 1em;
}
.staff-detail-person-block .right h4 {
  font-family: Circe;
  font-size: 32px;
  line-height: 100%;
  font-weight: 700;
}
.staff-detail-person-block .right h4 + p {
  margin-top: 1em;
}

.staff-detail-slider-pad {
  padding: 12px 44px;
  background-color: #fff;
}
.staff-detail-slider-pad img {
  aspect-ratio: 2/3;
  display: block;
}

.request-title {
  display: block;
  width: 624px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 768px) {
  .request-title {
    width: 100%;
  }
  .request-title .circe-xl-regular {
    font-size: 16px;
    line-height: 120%;
  }
}
@media (max-width: 1260px) {
  .request-title .manrope-xl {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .request-title .manrope-xl {
    font-size: 24px;
  }
}
.request-title ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  grid-gap: 32px;
}
.request-title ul li a {
  width: 66px;
  height: 66px;
}
@media (max-width: 768px) {
  .request-title ul li a {
    width: 48px;
    height: 48px;
  }
}
.request-title ul li:nth-child(1) a {
  background-color: #CD201F;
}
.request-title ul li:nth-child(2) a {
  background-color: #597DA3;
}
.request-title ul li:nth-child(3) a {
  background-color: #EE8208;
}
.request-title ul li:nth-child(4) a {
  background-color: #0088CC;
}
.request-title ul li:nth-child(5) a {
  background-color: #111827;
}
@media (max-width: 1260px) {
  .request-title ul {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .request-title ul {
    grid-gap: 12px;
  }
}

.section__header__block--right:has(.button) .button {
  margin-top: 24px;
}

.splide__arrow:disabled {
  opacity: 0 !important;
}

.splide__list:not(:has(li:nth-child(3))) {
  justify-content: center;
}
.splide__list:not(:has(li:nth-child(3))) li:nth-child(2) {
  margin-right: 0 !important;
}
@media (max-width: 768px) {
  .splide__list:not(:has(li:nth-child(3))) {
    justify-content: unset;
  }
}

.splide__list:not(:has(li:nth-child(2))) {
  justify-content: center;
}
.splide__list:not(:has(li:nth-child(2))) li:nth-child(1) {
  margin-right: 0 !important;
}

.splide__list:has(.webinar-card:nth-child(3)) .webinar-card:not(:last-child) {
  margin-right: 24px;
}

.custom-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  grid-gap: calc(var(--grid-gap) * 2);
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .custom-form {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .custom-form {
    --grid-gap: 18px;
  }
}
@media (max-width: 768px) {
  .custom-form {
    --grid-gap: 12px;
  }
}
.custom-form .form-title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
.custom-form .form-group {
  display: flex;
  grid-gap: var(--grid-gap);
}
@media (max-width: 768px) {
  .custom-form .form-group {
    flex-direction: column;
    align-items: stretch;
    grid-gap: calc(var(--grid-gap) * 2);
  }
}
.custom-form .form-group .form-col {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  grid-gap: var(--grid-gap);
}
@media (max-width: 768px) {
  .custom-form .form-group .form-col {
    grid-gap: 0;
  }
  .custom-form .form-group .form-col * + *:not(.custom-input__label) {
    margin-top: var(--grid-gap);
  }
}
@media (max-width: 1260px) {
  .custom-form .form-group .form-col:empty {
    display: none;
  }
}
.custom-form .form-group .form-col .form-row {
  display: flex;
  grid-gap: var(--grid-gap);
}
@media (max-width: 1260px) {
  .custom-form .form-group .form-col .form-row {
    flex-direction: column;
  }
}
.custom-form .form-group .form-col .form-row .form-cell {
  flex: 1 1 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: calc(var(--grid-gap) / 2);
  align-items: flex-end;
}
.custom-form .form-group .form-col .form-row .form-cell--centered {
  justify-content: center;
}
.custom-form .form-group .form-col .form-row .form-cell--big {
  flex: 3 1 0;
}
.custom-form .form-group .form-col .form-row .form-cell > *:not([class^=button]) {
  width: 100%;
}

.icon--arrow__arrow-down {
  mask-image: url(../static/img/icon/arrow/arrow-down.svg);
}

.icon--arrow__arrow-left-down {
  mask-image: url(../static/img/icon/arrow/arrow-left-down.svg);
}

.icon--arrow__arrow-left-up {
  mask-image: url(../static/img/icon/arrow/arrow-left-up.svg);
}

.icon--arrow__arrow-left {
  mask-image: url(../static/img/icon/arrow/arrow-left.svg);
}

.icon--arrow__arrow-right-down {
  mask-image: url(../static/img/icon/arrow/arrow-right-down.svg);
}

.icon--arrow__arrow-right-up {
  mask-image: url(../static/img/icon/arrow/arrow-right-up.svg);
}

.icon--arrow__arrow-right {
  mask-image: url(../static/img/icon/arrow/arrow-right.svg);
}

.icon--arrow__arrow-up {
  mask-image: url(../static/img/icon/arrow/arrow-up.svg);
}

.icon--arrow__caret-down {
  mask-image: url(../static/img/icon/arrow/caret-down.svg);
}

.icon--arrow__caret-left {
  mask-image: url(../static/img/icon/arrow/caret-left.svg);
}

.icon--arrow__caret-right {
  mask-image: url(../static/img/icon/arrow/caret-right.svg);
}

.icon--arrow__caret-up {
  mask-image: url(../static/img/icon/arrow/caret-up.svg);
}

.icon--arrow__chevron-double-down {
  mask-image: url(../static/img/icon/arrow/chevron-double-down.svg);
}

.icon--arrow__chevron-double-left {
  mask-image: url(../static/img/icon/arrow/chevron-double-left.svg);
}

.icon--arrow__chevron-double-right {
  mask-image: url(../static/img/icon/arrow/chevron-double-right.svg);
}

.icon--arrow__chevron-double-up {
  mask-image: url(../static/img/icon/arrow/chevron-double-up.svg);
}

.icon--arrow__chevron-down {
  mask-image: url(../static/img/icon/arrow/chevron-down.svg);
}

.icon--arrow__chevron-left, .splide__arrow--prev {
  mask-image: url(../static/img/icon/arrow/chevron-left.svg);
}

.icon--arrow__chevron-right, .splide__arrow--next, .form-banner__form .button .icon, .clients__item__link .icon, .programs__item__info__button .button .icon, .breadcrumbs__item:not(:last-child)::after {
  mask-image: url(../static/img/icon/arrow/chevron-right.svg);
}

.icon--arrow__chevron-up {
  mask-image: url(../static/img/icon/arrow/chevron-up.svg);
}

.icon--arrow__play, .video-card__play::before {
  mask-image: url(../static/img/icon/arrow/play.svg);
}

.icon--colored__biba {
  mask-image: url(../static/img/icon/colored/biba.svg);
}

.icon--colored__ok {
  mask-image: url(../static/img/icon/colored/ok.svg);
}

.icon--colored__tg {
  mask-image: url(../static/img/icon/colored/tg.svg);
}

.icon--colored__vk {
  mask-image: url(../static/img/icon/colored/vk.svg);
}

.icon--colored__yt {
  mask-image: url(../static/img/icon/colored/yt.svg);
}

.icon--colored__zen {
  mask-image: url(../static/img/icon/colored/zen.svg);
}

.icon--communication__call {
  mask-image: url(../static/img/icon/communication/call.svg);
}

.icon--communication__email {
  mask-image: url(../static/img/icon/communication/email.svg);
}

.icon--communication__message-alt {
  mask-image: url(../static/img/icon/communication/message-alt.svg);
}

.icon--communication__message {
  mask-image: url(../static/img/icon/communication/message.svg);
}

.icon--devices__camera {
  mask-image: url(../static/img/icon/devices/camera.svg);
}

.icon--devices__computer {
  mask-image: url(../static/img/icon/devices/computer.svg);
}

.icon--devices__laptop {
  mask-image: url(../static/img/icon/devices/laptop.svg);
}

.icon--devices__mobile {
  mask-image: url(../static/img/icon/devices/mobile.svg);
}

.icon--devices__speakers {
  mask-image: url(../static/img/icon/devices/speakers.svg);
}

.icon--devices__tablet {
  mask-image: url(../static/img/icon/devices/tablet.svg);
}

.icon--functional__add {
  mask-image: url(../static/img/icon/functional/add.svg);
}

.icon--functional__archive {
  mask-image: url(../static/img/icon/functional/archive.svg);
}

.icon--functional__attachment {
  mask-image: url(../static/img/icon/functional/attachment.svg);
}

.icon--functional__backspace {
  mask-image: url(../static/img/icon/functional/backspace.svg);
}

.icon--functional__ban {
  mask-image: url(../static/img/icon/functional/ban.svg);
}

.icon--functional__bookmark {
  mask-image: url(../static/img/icon/functional/bookmark.svg);
}

.icon--functional__check, .custom-checkbox input[type=checkbox]::after {
  mask-image: url(../static/img/icon/functional/check.svg);
}

.icon--functional__close {
  mask-image: url(../static/img/icon/functional/close.svg);
}

.icon--functional__cloud-download {
  mask-image: url(../static/img/icon/functional/cloud-download.svg);
}

.icon--functional__cloud-upload {
  mask-image: url(../static/img/icon/functional/cloud-upload.svg);
}

.icon--functional__copy {
  mask-image: url(../static/img/icon/functional/copy.svg);
}

.icon--functional__delete-alt {
  mask-image: url(../static/img/icon/functional/delete-alt.svg);
}

.icon--functional__delete {
  mask-image: url(../static/img/icon/functional/delete.svg);
}

.icon--functional__download {
  mask-image: url(../static/img/icon/functional/download.svg);
}

.icon--functional__edit-alt {
  mask-image: url(../static/img/icon/functional/edit-alt.svg);
}

.icon--functional__edit {
  mask-image: url(../static/img/icon/functional/edit.svg);
}

.icon--functional__enter {
  mask-image: url(../static/img/icon/functional/enter.svg);
}

.icon--functional__expand {
  mask-image: url(../static/img/icon/functional/expand.svg);
}

.icon--functional__export {
  mask-image: url(../static/img/icon/functional/export.svg);
}

.icon--functional__external-link {
  mask-image: url(../static/img/icon/functional/external-link.svg);
}

.icon--functional__eye-off {
  mask-image: url(../static/img/icon/functional/eye-off.svg);
}

.icon--functional__eye {
  mask-image: url(../static/img/icon/functional/eye.svg);
}

.icon--functional__favorite {
  mask-image: url(../static/img/icon/functional/favorite.svg);
}

.icon--functional__filter-alt {
  mask-image: url(../static/img/icon/functional/filter-alt.svg);
}

.icon--functional__filter, .catalog__filters__toggler::before {
  mask-image: url(../static/img/icon/functional/filter.svg);
}

.icon--functional__flag {
  mask-image: url(../static/img/icon/functional/flag.svg);
}

.icon--functional__heart {
  mask-image: url(../static/img/icon/functional/heart.svg);
}

.icon--functional__link-alt {
  mask-image: url(../static/img/icon/functional/link-alt.svg);
}

.icon--functional__link {
  mask-image: url(../static/img/icon/functional/link.svg);
}

.icon--functional__location, .review-card__badges .badge .icon {
  mask-image: url(../static/img/icon/functional/location.svg);
}

.icon--functional__lock {
  mask-image: url(../static/img/icon/functional/lock.svg);
}

.icon--functional__log-in {
  mask-image: url(../static/img/icon/functional/log-in.svg);
}

.icon--functional__log-out {
  mask-image: url(../static/img/icon/functional/log-out.svg);
}

.icon--functional__menu {
  mask-image: url(../static/img/icon/functional/menu.svg);
}

.icon--functional__minimize {
  mask-image: url(../static/img/icon/functional/minimize.svg);
}

.icon--functional__options-horizontal {
  mask-image: url(../static/img/icon/functional/options-horizontal.svg);
}

.icon--functional__options-vertical {
  mask-image: url(../static/img/icon/functional/options-vertical.svg);
}

.icon--functional__percentage {
  mask-image: url(../static/img/icon/functional/percentage.svg);
}

.icon--functional__refresh {
  mask-image: url(../static/img/icon/functional/refresh.svg);
}

.icon--functional__remove {
  mask-image: url(../static/img/icon/functional/remove.svg);
}

.icon--functional__reorder-alt {
  mask-image: url(../static/img/icon/functional/reorder-alt.svg);
}

.icon--functional__reorder {
  mask-image: url(../static/img/icon/functional/reorder.svg);
}

.icon--functional__search {
  mask-image: url(../static/img/icon/functional/search.svg);
}

.icon--functional__select {
  mask-image: url(../static/img/icon/functional/select.svg);
}

.icon--functional__send {
  mask-image: url(../static/img/icon/functional/send.svg);
}

.icon--functional__settings {
  mask-image: url(../static/img/icon/functional/settings.svg);
}

.icon--functional__share {
  mask-image: url(../static/img/icon/functional/share.svg);
}

.icon--functional__sort {
  mask-image: url(../static/img/icon/functional/sort.svg);
}

.icon--functional__switch {
  mask-image: url(../static/img/icon/functional/switch.svg);
}

.icon--functional__undo {
  mask-image: url(../static/img/icon/functional/undo.svg);
}

.icon--functional__unlock {
  mask-image: url(../static/img/icon/functional/unlock.svg);
}

.icon--functional__zoom-in {
  mask-image: url(../static/img/icon/functional/zoom-in.svg);
}

.icon--functional__zoom-out {
  mask-image: url(../static/img/icon/functional/zoom-out.svg);
}

.icon--geometry__circle-filled, .marquee__row--circle-filled .marquee__item::after {
  mask-image: url(../static/img/icon/geometry/circle-filled.svg);
}

.icon--geometry__circle, .marquee__row--circle .marquee__item::after {
  mask-image: url(../static/img/icon/geometry/circle.svg);
}

.icon--geometry__parallelogram, .marquee__row--parallelogram .marquee__item::after {
  mask-image: url(../static/img/icon/geometry/parallelogram.svg);
}

.icon--geometry__square, .marquee__row--square .marquee__item::after {
  mask-image: url(../static/img/icon/geometry/square.svg);
}

.icon--giant__black__AlarmClock {
  mask-image: url(../static/img/icon/giant/black/AlarmClock.svg);
}

.icon--giant__black__Backpack {
  mask-image: url(../static/img/icon/giant/black/Backpack.svg);
}

.icon--giant__black__Ball {
  mask-image: url(../static/img/icon/giant/black/Ball.svg);
}

.icon--giant__black__Book {
  mask-image: url(../static/img/icon/giant/black/Book.svg);
}

.icon--giant__black__Books {
  mask-image: url(../static/img/icon/giant/black/Books.svg);
}

.icon--giant__black__Bookshelf {
  mask-image: url(../static/img/icon/giant/black/Bookshelf.svg);
}

.icon--giant__black__Cactus {
  mask-image: url(../static/img/icon/giant/black/Cactus.svg);
}

.icon--giant__black__Calculator {
  mask-image: url(../static/img/icon/giant/black/Calculator.svg);
}

.icon--giant__black__Communication {
  mask-image: url(../static/img/icon/giant/black/Communication.svg);
}

.icon--giant__black__Diploma {
  mask-image: url(../static/img/icon/giant/black/Diploma.svg);
}

.icon--giant__black__Evaluation {
  mask-image: url(../static/img/icon/giant/black/Evaluation.svg);
}

.icon--giant__black__Globus {
  mask-image: url(../static/img/icon/giant/black/Globus.svg);
}

.icon--giant__black__Medal {
  mask-image: url(../static/img/icon/giant/black/Medal.svg);
}

.icon--giant__black__Patent {
  mask-image: url(../static/img/icon/giant/black/Patent.svg);
}

.icon--giant__black__Pendilum {
  mask-image: url(../static/img/icon/giant/black/Pendilum.svg);
}

.icon--giant__black__Physics {
  mask-image: url(../static/img/icon/giant/black/Physics.svg);
}

.icon--giant__black__Planet {
  mask-image: url(../static/img/icon/giant/black/Planet.svg);
}

.icon--giant__black__Presentation {
  mask-image: url(../static/img/icon/giant/black/Presentation.svg);
}

.icon--giant__black__Rating {
  mask-image: url(../static/img/icon/giant/black/Rating.svg);
}

.icon--giant__black__Scroll {
  mask-image: url(../static/img/icon/giant/black/Scroll.svg);
}

.icon--giant__black__SquareAcademicCap {
  mask-image: url(../static/img/icon/giant/black/SquareAcademicCap.svg);
}

.icon--giant__black__StudentCard {
  mask-image: url(../static/img/icon/giant/black/StudentCard.svg);
}

.icon--giant__black__Textbook {
  mask-image: url(../static/img/icon/giant/black/Textbook.svg);
}

.icon--giant__black__Video {
  mask-image: url(../static/img/icon/giant/black/Video.svg);
}

.icon--giant__blue__AlarmClock {
  mask-image: url(../static/img/icon/giant/blue/AlarmClock.svg);
}

.icon--giant__blue__Backpack {
  mask-image: url(../static/img/icon/giant/blue/Backpack.svg);
}

.icon--giant__blue__Ball {
  mask-image: url(../static/img/icon/giant/blue/Ball.svg);
}

.icon--giant__blue__Book {
  mask-image: url(../static/img/icon/giant/blue/Book.svg);
}

.icon--giant__blue__Books {
  mask-image: url(../static/img/icon/giant/blue/Books.svg);
}

.icon--giant__blue__Bookshelf {
  mask-image: url(../static/img/icon/giant/blue/Bookshelf.svg);
}

.icon--giant__blue__Cactus {
  mask-image: url(../static/img/icon/giant/blue/Cactus.svg);
}

.icon--giant__blue__Calculator {
  mask-image: url(../static/img/icon/giant/blue/Calculator.svg);
}

.icon--giant__blue__Communication {
  mask-image: url(../static/img/icon/giant/blue/Communication.svg);
}

.icon--giant__blue__Diploma {
  mask-image: url(../static/img/icon/giant/blue/Diploma.svg);
}

.icon--giant__blue__Evaluation {
  mask-image: url(../static/img/icon/giant/blue/Evaluation.svg);
}

.icon--giant__blue__Globus {
  mask-image: url(../static/img/icon/giant/blue/Globus.svg);
}

.icon--giant__blue__Medal {
  mask-image: url(../static/img/icon/giant/blue/Medal.svg);
}

.icon--giant__blue__Patent {
  mask-image: url(../static/img/icon/giant/blue/Patent.svg);
}

.icon--giant__blue__Pendilum {
  mask-image: url(../static/img/icon/giant/blue/Pendilum.svg);
}

.icon--giant__blue__Physics {
  mask-image: url(../static/img/icon/giant/blue/Physics.svg);
}

.icon--giant__blue__Planet {
  mask-image: url(../static/img/icon/giant/blue/Planet.svg);
}

.icon--giant__blue__Presentation {
  mask-image: url(../static/img/icon/giant/blue/Presentation.svg);
}

.icon--giant__blue__Rating {
  mask-image: url(../static/img/icon/giant/blue/Rating.svg);
}

.icon--giant__blue__Scroll {
  mask-image: url(../static/img/icon/giant/blue/Scroll.svg);
}

.icon--giant__blue__SquareAcademicCap {
  mask-image: url(../static/img/icon/giant/blue/SquareAcademicCap.svg);
}

.icon--giant__blue__StudentCard {
  mask-image: url(../static/img/icon/giant/blue/StudentCard.svg);
}

.icon--giant__blue__Textbook {
  mask-image: url(../static/img/icon/giant/blue/Textbook.svg);
}

.icon--giant__blue__Video {
  mask-image: url(../static/img/icon/giant/blue/Video.svg);
}

.icon--informational__circle-add {
  mask-image: url(../static/img/icon/informational/circle-add.svg);
}

.icon--informational__circle-arrow-down {
  mask-image: url(../static/img/icon/informational/circle-arrow-down.svg);
}

.icon--informational__circle-arrow-left {
  mask-image: url(../static/img/icon/informational/circle-arrow-left.svg);
}

.icon--informational__circle-arrow-right {
  mask-image: url(../static/img/icon/informational/circle-arrow-right.svg);
}

.icon--informational__circle-arrow-up {
  mask-image: url(../static/img/icon/informational/circle-arrow-up.svg);
}

.icon--informational__circle-check {
  mask-image: url(../static/img/icon/informational/circle-check.svg);
}

.icon--informational__circle-error {
  mask-image: url(../static/img/icon/informational/circle-error.svg);
}

.icon--informational__circle-help {
  mask-image: url(../static/img/icon/informational/circle-help.svg);
}

.icon--informational__circle-information {
  mask-image: url(../static/img/icon/informational/circle-information.svg);
}

.icon--informational__circle-remove {
  mask-image: url(../static/img/icon/informational/circle-remove.svg);
}

.icon--informational__circle-warning {
  mask-image: url(../static/img/icon/informational/circle-warning.svg);
}

.icon--informational__circle {
  mask-image: url(../static/img/icon/informational/circle.svg);
}

.icon--informational__warning {
  mask-image: url(../static/img/icon/informational/warning.svg);
}

.icon--media__next {
  mask-image: url(../static/img/icon/media/next.svg);
}

.icon--media__pause {
  mask-image: url(../static/img/icon/media/pause.svg);
}

.icon--media__play {
  mask-image: url(../static/img/icon/media/play.svg);
}

.icon--media__previous {
  mask-image: url(../static/img/icon/media/previous.svg);
}

.icon--media__repeat {
  mask-image: url(../static/img/icon/media/repeat.svg);
}

.icon--media__shuffle {
  mask-image: url(../static/img/icon/media/shuffle.svg);
}

.icon--media__stop {
  mask-image: url(../static/img/icon/media/stop.svg);
}

.icon--media__volume-off {
  mask-image: url(../static/img/icon/media/volume-off.svg);
}

.icon--media__volume-up {
  mask-image: url(../static/img/icon/media/volume-up.svg);
}

.icon--objects__bar-chart-alt {
  mask-image: url(../static/img/icon/objects/bar-chart-alt.svg);
}

.icon--objects__bar-chart {
  mask-image: url(../static/img/icon/objects/bar-chart.svg);
}

.icon--objects__book {
  mask-image: url(../static/img/icon/objects/book.svg);
}

.icon--objects__calendar {
  mask-image: url(../static/img/icon/objects/calendar.svg);
}

.icon--objects__clipboard-check {
  mask-image: url(../static/img/icon/objects/clipboard-check.svg);
}

.icon--objects__clipboard-list {
  mask-image: url(../static/img/icon/objects/clipboard-list.svg);
}

.icon--objects__clipboard {
  mask-image: url(../static/img/icon/objects/clipboard.svg);
}

.icon--objects__clock {
  mask-image: url(../static/img/icon/objects/clock.svg);
}

.icon--objects__comment {
  mask-image: url(../static/img/icon/objects/comment.svg);
}

.icon--objects__compass {
  mask-image: url(../static/img/icon/objects/compass.svg);
}

.icon--objects__credit-card {
  mask-image: url(../static/img/icon/objects/credit-card.svg);
}

.icon--objects__database {
  mask-image: url(../static/img/icon/objects/database.svg);
}

.icon--objects__document-add {
  mask-image: url(../static/img/icon/objects/document-add.svg);
}

.icon--objects__document-check {
  mask-image: url(../static/img/icon/objects/document-check.svg);
}

.icon--objects__document-download {
  mask-image: url(../static/img/icon/objects/document-download.svg);
}

.icon--objects__document-empty {
  mask-image: url(../static/img/icon/objects/document-empty.svg);
}

.icon--objects__document-remove {
  mask-image: url(../static/img/icon/objects/document-remove.svg);
}

.icon--objects__document {
  mask-image: url(../static/img/icon/objects/document.svg);
}

.icon--objects__folder-add {
  mask-image: url(../static/img/icon/objects/folder-add.svg);
}

.icon--objects__folder-check {
  mask-image: url(../static/img/icon/objects/folder-check.svg);
}

.icon--objects__folder-download {
  mask-image: url(../static/img/icon/objects/folder-download.svg);
}

.icon--objects__folder-remove {
  mask-image: url(../static/img/icon/objects/folder-remove.svg);
}

.icon--objects__folder {
  mask-image: url(../static/img/icon/objects/folder.svg);
}

.icon--objects__home {
  mask-image: url(../static/img/icon/objects/home.svg);
}

.icon--objects__image {
  mask-image: url(../static/img/icon/objects/image.svg);
}

.icon--objects__inbox {
  mask-image: url(../static/img/icon/objects/inbox.svg);
}

.icon--objects__location {
  mask-image: url(../static/img/icon/objects/location.svg);
}

.icon--objects__map {
  mask-image: url(../static/img/icon/objects/map.svg);
}

.icon--objects__megaphone {
  mask-image: url(../static/img/icon/objects/megaphone.svg);
}

.icon--objects__money {
  mask-image: url(../static/img/icon/objects/money.svg);
}

.icon--objects__notification-off {
  mask-image: url(../static/img/icon/objects/notification-off.svg);
}

.icon--objects__notification {
  mask-image: url(../static/img/icon/objects/notification.svg);
}

.icon--objects__pen {
  mask-image: url(../static/img/icon/objects/pen.svg);
}

.icon--objects__pin {
  mask-image: url(../static/img/icon/objects/pin.svg);
}

.icon--objects__print {
  mask-image: url(../static/img/icon/objects/print.svg);
}

.icon--objects__shopping-cart-add {
  mask-image: url(../static/img/icon/objects/shopping-cart-add.svg);
}

.icon--objects__shopping-cart {
  mask-image: url(../static/img/icon/objects/shopping-cart.svg);
}

.icon--objects__tag {
  mask-image: url(../static/img/icon/objects/tag.svg);
}

.icon--objects__webcam {
  mask-image: url(../static/img/icon/objects/webcam.svg);
}

.icon--social__odnoklassniki {
  mask-image: url(../static/img/icon/social/odnoklassniki.svg);
}

.icon--social__ok {
  mask-image: url(../static/img/icon/social/ok.svg);
}

.icon--social__tg {
  mask-image: url(../static/img/icon/social/tg.svg);
}

.icon--social__VK {
  mask-image: url(../static/img/icon/social/VK.svg);
}

.icon--social__yt {
  mask-image: url(../static/img/icon/social/yt.svg);
}

.icon--social__zen {
  mask-image: url(../static/img/icon/social/zen.svg);
}

.icon--user__user-add {
  mask-image: url(../static/img/icon/user/user-add.svg);
}

.icon--user__user-check {
  mask-image: url(../static/img/icon/user/user-check.svg);
}

.icon--user__user-remove {
  mask-image: url(../static/img/icon/user/user-remove.svg);
}

.icon--user__user {
  mask-image: url(../static/img/icon/user/user.svg);
}

.icon--user__users {
  mask-image: url(../static/img/icon/user/users.svg);
}

.icon--views__board {
  mask-image: url(../static/img/icon/views/board.svg);
}

.icon--views__grid {
  mask-image: url(../static/img/icon/views/grid.svg);
}

.icon--views__layers {
  mask-image: url(../static/img/icon/views/layers.svg);
}

.icon--views__layout {
  mask-image: url(../static/img/icon/views/layout.svg);
}

.icon--views__table {
  mask-image: url(../static/img/icon/views/table.svg);
}

.icon--views__three-rows {
  mask-image: url(../static/img/icon/views/three-rows.svg);
}

.icon--views__two-columns {
  mask-image: url(../static/img/icon/views/two-columns.svg);
}

.icon--views__two-rows {
  mask-image: url(../static/img/icon/views/two-rows.svg);
}

.icon--views__window {
  mask-image: url(../static/img/icon/views/window.svg);
}

.icon--weather__cloud {
  mask-image: url(../static/img/icon/weather/cloud.svg);
}

.icon--weather__cloudy {
  mask-image: url(../static/img/icon/weather/cloudy.svg);
}

.icon--weather__drop {
  mask-image: url(../static/img/icon/weather/drop.svg);
}

.icon--weather__fog {
  mask-image: url(../static/img/icon/weather/fog.svg);
}

.icon--weather__moon {
  mask-image: url(../static/img/icon/weather/moon.svg);
}

.icon--weather__rain {
  mask-image: url(../static/img/icon/weather/rain.svg);
}

.icon--weather__snow {
  mask-image: url(../static/img/icon/weather/snow.svg);
}

.icon--weather__snowflake {
  mask-image: url(../static/img/icon/weather/snowflake.svg);
}

.icon--weather__storm {
  mask-image: url(../static/img/icon/weather/storm.svg);
}

.icon--weather__sun {
  mask-image: url(../static/img/icon/weather/sun.svg);
}

.icon--weather__sunrise-alt {
  mask-image: url(../static/img/icon/weather/sunrise-alt.svg);
}

.icon--weather__sunrise {
  mask-image: url(../static/img/icon/weather/sunrise.svg);
}

.icon--weather__sunset {
  mask-image: url(../static/img/icon/weather/sunset.svg);
}

.icon--weather__temperature {
  mask-image: url(../static/img/icon/weather/temperature.svg);
}

.icon--weather__wind {
  mask-image: url(../static/img/icon/weather/wind.svg);
}

.icon--WYSIWYG__bold {
  mask-image: url(../static/img/icon/WYSIWYG/bold.svg);
}

.icon--WYSIWYG__italic {
  mask-image: url(../static/img/icon/WYSIWYG/italic.svg);
}

.icon--WYSIWYG__list {
  mask-image: url(../static/img/icon/WYSIWYG/list.svg);
}

.icon--WYSIWYG__strikethrough {
  mask-image: url(../static/img/icon/WYSIWYG/strikethrough.svg);
}

.icon--WYSIWYG__text {
  mask-image: url(../static/img/icon/WYSIWYG/text.svg);
}

.icon--WYSIWYG__underline {
  mask-image: url(../static/img/icon/WYSIWYG/underline.svg);
}

.icon,
.catalog__filters__toggler::before,
.splide__arrow,
.video-card__play::before,
.marquee__row--circle .marquee__item::after,
.marquee__row--parallelogram .marquee__item::after,
.marquee__row--square .marquee__item::after,
.marquee__row--circle-filled .marquee__item::after,
.breadcrumbs__item:not(:last-child)::after,
.custom-checkbox input[type=checkbox]::after {
  display: inline-flex;
  border: none;
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  flex-shrink: 0;
  transition: all 0.2s ease;
  width: var(--size, 24px);
  height: var(--size, 24px);
  position: relative;
  background: var(--color, #111827);
}

.icon--white {
  display: inline-flex;
  border: none;
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  flex-shrink: 0;
  transition: all 0.2s ease;
  width: var(--size, 24px);
  height: var(--size, 24px);
  position: relative;
  background: var(--color, #FFFFFF);
}

.icon32 {
  display: inline-flex;
  border: none;
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;
  flex-shrink: 0;
  transition: all 0.2s ease;
  width: var(--size, 32px);
  height: var(--size, 32px);
  background: var(--color, #004de6);
}

.icon--functional__pay {
  background: url(../static/img/icon/functional/pay-1.svg);
}

.icon--kurs__check {
  background: url(../static/img/icon/informational/kurs-check.svg);
}

.info-page {
  display: grid;
  grid-template-columns: 5fr 9fr;
  grid-gap: 140px;
}
@media (max-width: 1440px) {
  .info-page {
    grid-gap: 48px;
  }
}
@media (max-width: 768px) {
  .info-page {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}
.info-page__nav {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
}
@media (max-width: 1440px) {
  .info-page__nav {
    grid-gap: 16px;
  }
}
@media (max-width: 768px) {
  .info-page__nav {
    grid-gap: 8px;
  }
}
.info-page__nav__item {
  text-decoration: underline;
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
.info-page__nav__item:hover {
  text-decoration: none;
}
@media (max-width: 1440px) {
  .info-page__nav__item {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.info-page__nav__item.is-active {
  color: #004de6;
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 1440px) {
  .info-page__nav__item.is-active {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}

.detail-text,
.news-detail__body__text,
.stock-detail__text {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .detail-text,
.news-detail__body__text,
.stock-detail__text {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .detail-text,
.news-detail__body__text,
.stock-detail__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .detail-text,
.news-detail__body__text,
.stock-detail__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.detail-text > :first-child,
.news-detail__body__text > :first-child,
.stock-detail__text > :first-child {
  margin-top: 0;
}
.detail-text > :last-child,
.news-detail__body__text > :last-child,
.stock-detail__text > :last-child {
  margin-bottom: 0;
}
.detail-text p,
.detail-text ul,
.detail-text ol,
.detail-text img,
.news-detail__body__text p,
.stock-detail__text p,
.news-detail__body__text ul,
.stock-detail__text ul,
.news-detail__body__text ol,
.stock-detail__text ol,
.news-detail__body__text img,
.stock-detail__text img {
  margin: 16px 0;
}
@media (max-width: 1260px) {
  .detail-text p,
.detail-text ul,
.detail-text ol,
.detail-text img,
.news-detail__body__text p,
.stock-detail__text p,
.news-detail__body__text ul,
.stock-detail__text ul,
.news-detail__body__text ol,
.stock-detail__text ol,
.news-detail__body__text img,
.stock-detail__text img {
    margin: 12px 0;
  }
}
.detail-text ul,
.news-detail__body__text ul,
.stock-detail__text ul {
  list-style-position: inside;
}
.detail-text ul li,
.news-detail__body__text ul li,
.stock-detail__text ul li {
  display: flex;
  margin: 0;
}
.detail-text ul li::before,
.news-detail__body__text ul li::before,
.stock-detail__text ul li::before {
  content: "•";
  text-align: center;
  width: 24px;
}
.detail-text ol,
.news-detail__body__text ol,
.stock-detail__text ol {
  list-style: none;
  counter-reset: biba;
}
.detail-text ol li,
.news-detail__body__text ol li,
.stock-detail__text ol li {
  display: grid;
  grid-template-columns: 24px 1fr;
  counter-increment: biba;
}
.detail-text ol li > *,
.news-detail__body__text ol li > *,
.stock-detail__text ol li > * {
  grid-column: 2;
}
.detail-text ol li::before,
.news-detail__body__text ol li::before,
.stock-detail__text ol li::before {
  content: counter(biba) ".";
}
.detail-text h2,
.news-detail__body__text h2,
.stock-detail__text h2 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 32px 0 16px 0;
}
@media (max-width: 1440px) {
  .detail-text h2,
.news-detail__body__text h2,
.stock-detail__text h2 {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 1260px) {
  .detail-text h2,
.news-detail__body__text h2,
.stock-detail__text h2 {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .detail-text h2,
.news-detail__body__text h2,
.stock-detail__text h2 {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}

.detail-text a,
.news-detail__body__text a,
.stock-detail__text a {
  text-decoration: underline;
}

.detail-text a:hover,
.news-detail__body__text a:hover,
.stock-detail__text a:hover {
  text-decoration: none;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease;
  opacity: 1;
  overflow: hidden;
  background: #FFFFFF;
  z-index: 10;
}
.modal:not(.show) {
  opacity: 0;
  pointer-events: none;
}
.modal .kurs-group {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 32px;
  justify-content: center;
}
.modal .kurs-group .kurs-group__item {
  display: flex;
  height: 64px;
}
@media (max-width: 1260px) {
  .modal .kurs-group .kurs-group__item {
    height: 56px;
  }
}
@media (max-width: 768px) {
  .modal .kurs-group .kurs-group__item {
    height: 48px;
  }
}
.modal .kurs-group .kurs-group__item .kurs_check {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  top: 50%;
  background-color: #FFCCD1;
  border-radius: 50%;
  margin-right: 24px;
  transform: translateY(-50%);
}
.modal .kurs-group .kurs-group__item span {
  text-align: left;
}
@media (max-width: 1260px) {
  .modal .kurs-group .kurs-group__item span {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .modal .kurs-group .kurs-group__item span {
    font-size: 16px;
  }
}
.modal .kurs-group .kurs-group__item:last-child {
  justify-content: center;
  height: 56px;
}
.modal .kurs-group .kurs_check {
  width: 32px;
  height: 32px;
  background-color: #FFCCD1;
  border-radius: 50%;
  margin-right: 24px;
}
@media (min-width: 769px) {
  .modal {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    width: 1000px;
  }
}
@media (max-width: 768px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: none;
    overflow: visible;
    transition: none;
  }
}
@media (min-width: 768px) {
  .modal--mini {
    width: 449px;
  }
}
.modal--mini .modal__inner {
  grid-gap: 24px;
}
.modal__socials {
  display: flex;
  grid-gap: 16px;
  justify-content: center;
}
.modal__socials a {
  display: block;
  width: 40px;
  height: 40px;
}
.modal__socials a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.modal__inner {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  padding: 32px;
}
@media (max-width: 768px) {
  .modal__inner {
    grid-gap: 16px;
    padding: 16px;
  }
}
.modal__inner--blue {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding: 16px 32px 32px;
  background: #004de6;
  color: #e8f2fc;
}
@media (max-width: 768px) {
  .modal__inner--blue {
    grid-gap: 16px;
    padding: 16px;
  }
}
.modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal__header .icon {
  --size: 32px;
}
.modal__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
@media (max-width: 768px) {
  .modal__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
.modal__subtitle {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  color: #979797;
}
@media (max-width: 768px) {
  .modal__subtitle {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.modal__text-content {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.modal__text-content__title {
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
}
.modal__text-content p,
.modal__text-content ul,
.modal__text-content ol,
.modal__text-content img {
  margin: 16px 0;
}
.modal__text-content > :first-child {
  margin-top: 0;
}
.modal__text-content > :last-child {
  margin-bottom: 0;
}

.person_modal__body {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
}
@media (max-width: 768px) {
  .person_modal__body {
    grid-gap: 16px;
  }
}
.person_modal__imgtext {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}
@media (max-width: 768px) {
  .person_modal__imgtext {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
.person_modal__imgtext__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}
.person_modal__imgtext__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.person_modal__imgtext__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.person_modal__slider {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding: 16px 0;
  background: #F3F4F6;
  overflow: hidden;
}
@media (max-width: 768px) {
  .person_modal__slider {
    margin: 0 -16px;
    padding: 16px;
  }
}
.person_modal__slider__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  padding: 0 16px;
}
.organization_modal {
  width: auto;
}
.organization_modal__title-img img {
  height: 65px;
  width: auto;
}
.organization_modal__imgtext {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 32px;
}
@media (max-width: 768px) {
  .organization_modal__imgtext {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
.organization_modal__imgtext__img {
  width: 450px;
}
.organization_modal__imgtext__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.organization_modal__imgtext__text {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
.organization_modal__imgtext__text__title {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
}
.organization_modal__imgtext__text__text {
  width: 450px;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}

.modalPopupKurs {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease;
  opacity: 1;
  overflow: auto;
  background: #FFFFFF;
  z-index: 99999;
}
.modalPopupKurs:not(.show) {
  opacity: 0;
  pointer-events: none;
}
.modalPopupKurs .kurs-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modalPopupKurs .kurs-group__item {
  display: flex;
  height: 64px;
}
.modalPopupKurs .kurs-group__item + .kurs-group__item {
  margin-top: 32px;
}
@media (max-width: 768px) {
  .modalPopupKurs .kurs-group__item + .kurs-group__item {
    margin-top: 20px;
  }
}
@media (max-width: 1260px) {
  .modalPopupKurs .kurs-group__item {
    height: 56px;
  }
}
@media (max-width: 768px) {
  .modalPopupKurs .kurs-group__item {
    height: 48px;
  }
}
.modalPopupKurs .kurs-group__item__check {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  top: 50%;
  background-color: #FFCCD1;
  border-radius: 50%;
  margin-right: 24px;
  transform: translateY(-50%);
}
@media (max-width: 1260px) {
  .modalPopupKurs .kurs-group__item__check {
    scale: 0.9;
    margin-right: 20px;
  }
}
@media (max-width: 320px) {
  .modalPopupKurs .kurs-group__item__check {
    margin-right: 16px;
  }
}
.modalPopupKurs .kurs-group__item p {
  text-align: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1260px) {
  .modalPopupKurs .kurs-group__item p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .modalPopupKurs .kurs-group__item p {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .modalPopupKurs .kurs-group__item p {
    font-size: 14px;
    height: 40px;
  }
}
.modalPopupKurs .kurs-group__item:last-child {
  justify-content: center;
  height: auto;
}
@media (min-width: 768px) {
  .modalPopupKurs {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    width: 1000px;
  }
}
@media (max-width: 768px) {
  .modalPopupKurs {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    width: 300px;
  }
}
@media (max-width: 400px) {
  .modalPopupKurs {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    width: 80vw;
  }
}
@media (max-width: 290px) {
  .modalPopupKurs {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
    width: 90vw;
  }
}
@media (min-width: 768px) {
  .modalPopupKurs--mini {
    width: 449px;
  }
}
.modalPopupKurs--mini .modalPopupKurs__inner {
  grid-gap: 24px;
}
.modalPopupKurs__socials {
  display: flex;
  grid-gap: 16px;
  justify-content: center;
}
.modalPopupKurs__socials a {
  display: block;
  width: 40px;
  height: 40px;
}
.modalPopupKurs__socials a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.modalPopupKurs__inner {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  padding: 32px;
}
@media (max-width: 768px) {
  .modalPopupKurs__inner {
    grid-gap: 16px;
    padding: 16px;
  }
}
.modalPopupKurs__inner--blue {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding: 16px 32px 32px;
  background: #004de6;
  color: #e8f2fc;
}
@media (max-width: 768px) {
  .modalPopupKurs__inner--blue {
    grid-gap: 8px;
    padding: 16px;
  }
}
.modalPopupKurs__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalPopupKurs__header .icon {
  --size: 32px;
}
.modalPopupKurs__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
@media (max-width: 768px) {
  .modalPopupKurs__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
.modalPopupKurs__subtitle {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  color: #979797;
}
@media (max-width: 768px) {
  .modalPopupKurs__subtitle {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.modalPopupKurs__text-content {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.modalPopupKurs__text-content__title {
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
}
.modalPopupKurs__text-content p,
.modalPopupKurs__text-content ul,
.modalPopupKurs__text-content ol,
.modalPopupKurs__text-content img {
  margin: 16px 0;
}
.modalPopupKurs__text-content > :first-child {
  margin-top: 0;
}
.modalPopupKurs__text-content > :last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .modalPopupKurs-body .text--center {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}

.stock-detail {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
}
.stock-detail__banner {
  background-size: cover;
  background-position: center;
  padding: 100px 58px;
}
@media (max-width: 768px) {
  .stock-detail__banner {
    background-image: none !important;
    padding: 0;
  }
}
.stock-detail__banner__inner {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  max-width: 715px;
}
@media (max-width: 1260px) {
  .stock-detail__banner__inner {
    grid-gap: 16px;
  }
}
.stock-detail__banner__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .stock-detail__banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
@media (max-width: 1260px) {
  .stock-detail__banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .stock-detail__banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.stock-detail__banner__text {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .stock-detail__banner__text {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .stock-detail__banner__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
[data-popup]:not(.show) {
  pointer-events: none;
}

.popup__selfcloser {
  cursor: pointer;
}

.popup-controller {
  cursor: pointer;
}
.popup-controller--active .popup-controller__initial {
  display: none !important;
}
.popup-controller:not(.popup-controller--active) .popup-controller__final {
  display: none !important;
}

.right-menu-item {
  height: 24px;
}

.right-menu-tablet {
  height: 24px;
}

.header__search_popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  opacity: 1;
  transition: all 0.2s ease;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.2509803922);
}
.header__search_popup:not(.show) {
  opacity: 0;
}
.header__search_popup__inner {
  display: flex;
}
.header__search_popup__top {
  height: 100px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
}
@media (max-width: 768px) {
  .header__search_popup__top {
    height: 70px;
  }
}
.header__search_popup__bot {
  background: #f6f7f8;
  height: calc(100vh - 200px);
  display: flex;
  width: 100vw;
}
@media (max-width: 768px) {
  .header__search_popup__bot {
    position: fixed;
    top: 100px;
    height: calc(100vh - 100px);
  }
}
@media (max-width: 768px) {
  .header__search_popup:not(:has(.header__search_popup__afterBot)) .header__search_popup__bot {
    top: 70px;
    height: calc(100vh - 70px);
  }
}
.header__search_popup__afterBot {
  position: relative;
  width: 100%;
  height: 100px;
  bottom: 0;
  background: #fff;
  display: flex;
}
@media (max-width: 768px) {
  .header__search_popup__afterBot {
    position: fixed;
    top: 70px;
    height: 30px;
  }
  .header__search_popup__afterBot .header__search_popup__inner .header__search_popup__field-1 a {
    padding: 0;
    background-color: transparent;
    border-radius: unset;
    color: var(--accent-color);
    text-decoration: underline;
  }
  .header__search_popup__afterBot .header__search_popup__inner .header__search_popup__field-1 a:active {
    opacity: 0.5;
  }
  .header__search_popup__afterBot .header__search_popup__inner .header__search_popup__field-1 a .icon {
    display: none;
  }
}
.header__search_popup__field {
  display: flex;
  grid-gap: 32px;
  align-items: center;
  width: 100%;
}
.header__search_popup__field input {
  width: 100%;
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
  border: none;
  outline: none;
}
.header__search_popup__field-1 {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
}
.header__search_popup__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 48px;
  overflow: auto;
  padding: 24px 0;
}
.header__search_popup__list__item {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}
@media (max-width: 1260px) {
  .header__search_popup__list__item {
    grid-gap: 8px;
  }
}
.header__search_popup__list__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  text-decoration: underline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1440px) {
  .header__search_popup__list__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 1260px) {
  .header__search_popup__list__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.header__search_popup__list__item__title:hover {
  color: #004de6;
}
.header__search_popup__list__item__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1440px) {
  .header__search_popup__list__item__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .header__search_popup__list__item__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header__search_popup__list__item__text {
    height: 3em;
    overflow: hidden;
    isolation: isolate;
  }
}

.header__search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  opacity: 1;
  transition: all 0.2s ease;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.2509803922);
}
.header__search:not(.show) {
  opacity: 0;
}
.header__search__inner {
  display: flex;
}
.header__search__top {
  height: var(--header-height, 134px);
  display: flex;
  align-items: center;
  background: #FFFFFF;
}
.header__search__field {
  display: flex;
  grid-gap: 32px;
  align-items: center;
  width: 100%;
}
.header__search__field input {
  width: 100%;
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
  border: none;
  outline: none;
}
.header__search__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 48px;
  overflow: auto;
  padding: 24px 0;
}
.header__search__list__item {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}
@media (max-width: 1260px) {
  .header__search__list__item {
    grid-gap: 8px;
  }
}
.header__search__list__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  text-decoration: underline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1440px) {
  .header__search__list__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 1260px) {
  .header__search__list__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.header__search__list__item__title:hover {
  color: #004de6;
}
.header__search__list__item__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1440px) {
  .header__search__list__item__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .header__search__list__item__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header__search__list__item__text {
    height: 3em;
    overflow: hidden;
    isolation: isolate;
  }
}

.page__header__inner {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  grid-gap: 48px;
}
@media (max-width: 1440px) {
  .page__header__inner {
    grid-gap: 32px;
  }
}
@media (max-width: 1260px) {
  .page__header__inner {
    grid-gap: 24px;
  }
}
.page__header__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .page__header__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
@media (max-width: 1260px) {
  .page__header__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}

.header__free_popup {
  position: absolute;
  top: calc(100% + 16px);
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  background: #FFFFFF;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25)) drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15));
  transition: all 0.2s ease;
  z-index: 10;
  white-space: nowrap;
}
.header__free_popup:not(.show) {
  opacity: 0;
  pointer-events: none;
}
.header__free_popup.show {
  opacity: 1;
}
.header__free_popup__links {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
}
.header__free_popup__links a {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.header__free_popup__links a:hover {
  color: #004de6;
}

.news-detail {
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
}
.news-detail__header {
  display: flex;
  flex-direction: column;
  grid-gap: 48px;
}
.news-detail__header__top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 24px;
}
.news-detail__header__top a {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.news-detail__date {
  color: #9CA3AF;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.news-detail__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .news-detail__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
@media (max-width: 1260px) {
  .news-detail__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.news-detail__banner {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 36.6666666667%;
  overflow: hidden;
}
.news-detail__banner > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.news-detail__body {
  display: grid;
  grid-gap: 64px;
}
@media (max-width: 1260px) {
  .news-detail__body {
    grid-template-columns: 1fr;
  }
}
.news-detail__body__other {
  display: flex;
  flex-direction: column;
  grid-gap: 48px;
}
@media (max-width: 1260px) {
  .news-detail__body__other {
    display: none;
  }
}
.news-detail__body__text img {
  margin: 64px 0;
}

.news-detail-banner {
  margin: 64px 0;
}

.news-detail-slider {
  overflow: hidden;
}

.news-detail-btn {
  display: flex;
  justify-content: right;
}

.video-block {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 1200px !important;
  padding-bottom: 40% !important;
  margin: 0 auto;
}
.video-block > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1260px) {
  .video-block {
    max-width: unset !important;
    padding-bottom: 56.25% !important;
  }
}

.webinar-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  align-items: flex-start;
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .webinar-cards {
    grid-template-columns: 1fr;
  }
}

.webinar-card {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  padding: 24px;
  background: #FFFFFF;
  border-radius: 4px;
  height: 100%;
}
@media (max-width: 768px) {
  .webinar-card {
    padding: 24px 16px;
  }
}
.webinar-card__top {
  position: relative;
}
.webinar-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 57.4423480084%;
  overflow: hidden;
  border-radius: 4px;
}
.webinar-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 768px) {
  .webinar-card__img {
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 92.8813559322%;
    overflow: hidden;
  }
  .webinar-card__img > :first-child {
    border-radius: inherit;
    mask-image: radial-gradient(#000, #000);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
}
.webinar-card__img::after {
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.webinar-card__price {
  right: 16px;
  left: 16px;
  bottom: 14px;
  color: #FFFFFF;
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  position: absolute;
  text-align: right;
}
.webinar-card__badges {
  position: absolute;
  top: 16px;
  left: 12px;
  right: 12px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
}
.webinar-card__title {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 1440px) and (min-width: 768px) {
  .webinar-card__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.webinar-card__footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .webinar-card__footer {
    flex-direction: column;
    grid-gap: 16px;
  }
}
.webinar-card__person {
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-gap: 0 12px;
}
@media (max-width: 768px) {
  .webinar-card__person {
    align-self: flex-start;
  }
}
.webinar-card__person__img {
  grid-row: 1/span 2;
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
  align-self: center;
}
.webinar-card__person__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.webinar-card__person__post {
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
  align-self: flex-end;
}
.webinar-card__person__name {
  font-family: Circe;
  font-size: 14px;
  font-weight: 700;
  align-self: flex-start;
}

.header__courses-popup {
  position: absolute;
  top: 100%;
  max-height: calc(100vh - var(--header-height));
  left: 0;
  background: #fff;
  width: 100vw;
  z-index: 2;
  transition: max-height 0.4s ease;
  overflow: hidden;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.2509803922);
}
@media (max-width: 768px) {
  .header__courses-popup .header__courses-popup {
    display: none;
  }
}
.header__courses-popup:not(.show) {
  width: calc(100 * var(--vw));
  max-height: 0;
  pointer-events: none;
  box-shadow: none;
}
.header__courses-popup__inner {
  display: grid;
  grid-template-columns: 1fr 3fr;
  position: relative;
  height: calc(100vh - var(--header-height));
}
.header__courses-popup__inner::before {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  height: 1px;
  background: #004de6;
  width: 100%;
}
.header__courses-popup__nav {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #9CA3AF;
}
.header__courses-popup__nav__inner {
  flex-basis: 0;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 19px;
}
.header__courses-popup__nav__item {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0;
  width: fit-content;
}
.header__courses-popup__nav__item.is-active, .header__courses-popup__nav__item:hover {
  color: #004de6;
  font-family: Circe;
  font-size: 16px;
  font-weight: 700;
}
.header__courses-popup__catalog {
  padding: 24px 56px 0 0;
  position: relative;
  max-height: calc(100vh - var(--header-height, 0));
  min-height: 360px;
  grid-column: 2;
  overflow: auto;
}
.header__courses-popup__catalog:not(.is-active) {
  display: none;
  pointer-events: none;
  position: absolute;
}
.header__courses-popup__catalog__tags {
  display: flex;
  grid-gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding-left: 32px;
}
.header__courses-popup__catalog__categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content min-content 1fr;
  grid-gap: 24px 48px;
  margin-bottom: 84px;
  padding-left: 32px;
}
.header__courses-popup__catalog__categories__block {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.header__courses-popup__catalog__categories__block:nth-child(1) {
  grid-column: 1;
  grid-row: 1/span all;
}
.header__courses-popup__catalog__categories__block:nth-child(2) {
  grid-column: 2;
  grid-row: 1/span all;
}
.header__courses-popup__catalog__categories__block:nth-child(n+3) {
  grid-column: 3;
}
.header__courses-popup__catalog__categories__block__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}
.header__courses-popup__catalog__categories__block__item {
  display: block;
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
}
.header__courses-popup__catalog__categories__block__item:hover {
  color: #004de6;
}
.header__courses-popup__catalog__categories__block__footer {
  display: flex;
  justify-content: flex-end;
}
.header__courses-popup__catalog__footer {
  position: fixed;
  padding: 24px 56px 24px 0;
  width: 1128px;
  background-color: #fff;
  bottom: 0;
  border-top: 1px solid #004de6;
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
}
@media (max-width: 1536px) {
  .header__courses-popup__catalog__footer {
    width: calc((100vw - 96px) * 0.75 + 48px);
    right: 0;
    padding: 24px 104px 24px 0;
  }
}

.header__courses-popup:not(.show) .header__courses-popup__catalog__footer {
  display: none;
}

.custom-input {
  display: grid;
  position: relative;
  width: 100%;
  grid-gap: 8px;
}
.custom-input--icon input {
  --padding-right: calc(var(--padding-left) * 2 + 4px);
}
@media (max-width: 1440px) and (min-width: 768px) {
  .custom-input--tablet-compact .custom-input__label {
    display: none;
  }
}
.custom-input__icon {
  right: 16px;
  --size: 16px;
  transform: translateY(14px);
  position: absolute;
  grid-row: 1;
  --color:#6B7280;
}
.custom-input__label {
  order: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: "Circe";
  font-style: normal;
  color: inherit;
}
.custom-input__label ~ .custom-input__icon {
  grid-row: 2;
}
.custom-input__helper {
  order: 3;
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: inherit;
  transition: all 0.2s ease;
}
.custom-input input,
.custom-input textarea, .custom-input select {
  color: #111827;
  --padding-left: 16px;
  width: 100%;
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: white;
  padding: 0 var(--padding-right, var(--padding-left)) 0 var(--padding-left);
  order: 2;
  outline: none;
  transition: all 0.2s ease;
  height: 46px;
  border-radius: 4px;
  border-color: #004de6;
  border-style: solid;
  border-width: 1px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.custom-input input:disabled,
.custom-input textarea:disabled, .custom-input select:disabled {
  background-color: #F9FAFB;
  border-color: #D1D5DB;
}
.custom-input input:disabled ~ .custom-input__icon,
.custom-input textarea:disabled ~ .custom-input__icon, .custom-input select:disabled ~ .custom-input__icon {
  --color:#D1D5DB;
}
.custom-input input:not(:disabled):focus,
.custom-input textarea:not(:disabled):focus, .custom-input select:not(:disabled):focus, .custom-input input:not(:disabled):hover,
.custom-input textarea:not(:disabled):hover, .custom-input select:not(:disabled):hover {
  box-shadow: 0 0 3px 2px rgba(34, 73, 237, 0.3);
}
.custom-input input:not(:disabled):focus ~ .custom-input__icon,
.custom-input textarea:not(:disabled):focus ~ .custom-input__icon, .custom-input select:not(:disabled):focus ~ .custom-input__icon, .custom-input input:not(:disabled):hover ~ .custom-input__icon,
.custom-input textarea:not(:disabled):hover ~ .custom-input__icon, .custom-input select:not(:disabled):hover ~ .custom-input__icon {
  --color:#004de6;
}
.custom-input input::placeholder,
.custom-input textarea::placeholder {
  color: #D1D5DB;
}
.custom-input input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid,
.custom-input textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid {
  border-color: #00D719;
  background-color: #F0FAE8;
}
.custom-input input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__helper,
.custom-input textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__helper {
  color: #00D719;
}
.custom-input input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__icon,
.custom-input textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__icon {
  --color:#00D719;
}
.custom-input input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid,
.custom-input textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid {
  border-color: #EF002B;
  background-color: #FFEBEE;
}
.custom-input input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__helper,
.custom-input textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__helper {
  color: #EF002B;
}
.custom-input input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__icon,
.custom-input textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__icon {
  --color:#EF002B;
}
.custom-input textarea {
  height: 92px;
  resize: none;
  padding: var(--padding-right, var(--padding-left)) var(--padding-left);
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=number],
textarea {
  appearance: textfield;
}
input[type=text]::-webkit-inner-spin-button, input[type=text]::-webkit-inner-spin-button,
input[type=password]::-webkit-inner-spin-button,
input[type=password]::-webkit-inner-spin-button,
input[type=email]::-webkit-inner-spin-button,
input[type=email]::-webkit-inner-spin-button,
input[type=tel]::-webkit-inner-spin-button,
input[type=tel]::-webkit-inner-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-inner-spin-button,
textarea::-webkit-inner-spin-button,
textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.custom-input-1 {
  display: grid;
  position: relative;
  width: 100%;
  grid-gap: 8px;
}
@media (max-width: 768px) {
  .custom-input-1 {
    grid-gap: 0;
  }
  .custom-input-1 input {
    margin-top: var(--grid-gap);
  }
}
.custom-input-1--icon input {
  --padding-right: calc(var(--padding-left) * 2 + 4px);
}
@media (max-width: 1440px) and (min-width: 768px) {
  .custom-input-1--tablet-compact .custom-input-1__label {
    display: none;
  }
}
.custom-input-1__icon {
  right: 16px;
  --size: 16px;
  transform: translateY(14px);
  position: absolute;
  grid-row: 1;
  --color:#6B7280;
}
.custom-input-1__label {
  order: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: "Circe";
  font-style: normal;
  color: inherit;
}
.custom-input-1__label ~ .custom-input__icon {
  grid-row: 2;
}
.custom-input-1__helper {
  order: 3;
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: inherit;
  transition: all 0.2s ease;
}
.custom-input-1 input,
.custom-input-1 textarea, .custom-input-1 select {
  color: #111827;
  --padding-left: 16px;
  width: 100%;
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: white;
  padding: 0 var(--padding-right, var(--padding-left)) 0 var(--padding-left);
  order: 2;
  outline: none;
  transition: all 0.2s ease;
  height: 46px;
  border-radius: 4px;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.custom-input-1 input:disabled,
.custom-input-1 textarea:disabled, .custom-input-1 select:disabled {
  background-color: #F9FAFB;
  border-color: #D1D5DB;
}
.custom-input-1 input:disabled ~ .custom-input__icon,
.custom-input-1 textarea:disabled ~ .custom-input__icon, .custom-input-1 select:disabled ~ .custom-input__icon {
  --color:#D1D5DB;
}
.custom-input-1 input:not(:disabled):focus,
.custom-input-1 textarea:not(:disabled):focus, .custom-input-1 select:not(:disabled):focus, .custom-input-1 input:not(:disabled):hover,
.custom-input-1 textarea:not(:disabled):hover, .custom-input-1 select:not(:disabled):hover {
  box-shadow: 0 0 3px 2px rgba(66, 66, 66, 0.3);
}
.custom-input-1 input:not(:disabled):focus ~ .custom-input__icon,
.custom-input-1 textarea:not(:disabled):focus ~ .custom-input__icon, .custom-input-1 select:not(:disabled):focus ~ .custom-input__icon, .custom-input-1 input:not(:disabled):hover ~ .custom-input__icon,
.custom-input-1 textarea:not(:disabled):hover ~ .custom-input__icon, .custom-input-1 select:not(:disabled):hover ~ .custom-input__icon {
  --color:#004de6;
}
.custom-input-1 input::placeholder,
.custom-input-1 textarea::placeholder {
  color: #D1D5DB;
}
.custom-input-1 input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid,
.custom-input-1 textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid {
  border-color: #00D719;
  background-color: #F0FAE8;
}
.custom-input-1 input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__helper,
.custom-input-1 textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__helper {
  color: #00D719;
}
.custom-input-1 input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__icon,
.custom-input-1 textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):valid ~ .custom-input__icon {
  --color:#00D719;
}
.custom-input-1 input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid,
.custom-input-1 textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid {
  border-color: #EF002B;
  background-color: #FFEBEE;
}
.custom-input-1 input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__helper,
.custom-input-1 textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__helper {
  color: #EF002B;
}
.custom-input-1 input:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__icon,
.custom-input-1 textarea:not(:disabled):not(:focus):not(:hover):not(:placeholder-shown):invalid ~ .custom-input__icon {
  --color:#EF002B;
}
.custom-input-1 textarea {
  height: 92px;
  resize: none;
  padding: var(--padding-right, var(--padding-left)) var(--padding-left);
}

@media (max-width: 768px) {
  .about__page {
    padding-top: 16px;
  }
}
.about__page .breadcrumbs {
  margin-bottom: calc(-1 * (var(--section-padding) - 48px));
}
@media (max-width: 768px) {
  .about__page .breadcrumbs {
    margin-bottom: 0;
  }
}
.about__text {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .about__text {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .about__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .about__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.about__text p {
  margin: 16px 0;
}
.about__text img {
  margin: 48px 0;
  display: block;
  height: auto;
}
.about__text :first-child {
  margin-top: 0;
}
.about__text :last-child {
  margin-bottom: 0;
}

.stats-cards {
  display: flex;
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .stats-cards {
    flex-direction: column;
  }
}
.stats-cards--green {
  --accent: #D9F1C6;
}
.stats-cards__card {
  flex: 1 1 0;
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 4px;
  background: var(--accent);
}
.stats-cards__card__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (min-width: 768px) and (max-width: 1440px) {
  .stats-cards__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
.stats-cards__card__text {
  max-width: 330px;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .stats-cards__card__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.stock-card {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  color: #111827;
}
.stock-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 78.4482758621%;
  overflow: hidden;
}
.stock-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.stock-card__img-aspect {
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 4px;
  isolation: isolate;
}
.stock-card__img-aspect img {
  width: 100%;
  display: block;
}
.stock-card:has(.stock-card__img-aspect) {
  padding: 24px 24px 32px 24px;
  border-radius: 4px;
  background-color: #fff;
}
.stock-card__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
.stock-card__title:hover {
  color: #004de6;
}
@media (max-width: 1440px) {
  .stock-card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 1260px) {
  .stock-card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.stock-card__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .stock-card__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .stock-card__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.stock-card__footer {
  margin-top: auto;
}
@media (max-width: 768px) {
  .stock-card__footer a {
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
}

.stock-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 64px 24px;
}
@media (max-width: 768px) {
  .stock-cards {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}

.custom-radio {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
  cursor: pointer;
}
.custom-radio input[type=radio] {
  width: 28px;
  height: 28px;
  visibility: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.2s ease;
  --size: 21px;
}
.custom-radio input[type=radio]::before {
  visibility: visible;
  position: absolute;
  content: "";
  width: var(--size);
  height: var(--size);
  border: 3px solid #3F474B;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.custom-radio input[type=radio]::after {
  visibility: visible;
  position: absolute;
  content: "";
  border-radius: 50%;
  transition: all 0.3s ease;
  border: 5px solid #1877BC;
  opacity: 0;
}
.custom-radio input[type=radio]:not(:disabled):hover::before, .custom-radio input[type=radio]:not(:disabled):focus::before {
  border: 3px solid #1877BC;
  filter: drop-shadow(0px 0px 3px #83B2D5);
}
.custom-radio input[type=radio]:checked::before {
  border: 3px solid #1877BC;
}
.custom-radio input[type=radio]:checked::after {
  opacity: 1;
}
.custom-radio input[type=radio]:disabled {
  pointer-events: none;
}
.custom-radio input[type=radio]:disabled::before {
  border: 3px solid #BEC7CC;
}
.custom-radio input[type=radio]:disabled:checked::after {
  border: 5px solid #BEC7CC;
}

.medium-person-card {
  display: grid;
  grid-template-columns: 182px 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 16px 48px;
  align-items: center;
}
@media (max-width: 768px) {
  .medium-person-card {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
  }
}
.medium-person-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}
.medium-person-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 768px) {
  .medium-person-card__img {
    grid-column: 1;
    grid-row: 1/-1;
  }
}
.medium-person-card__name {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
@media (min-width: 768px) {
  .medium-person-card__name {
    grid-column: 2;
  }
}
@media (max-width: 1440px) {
  .medium-person-card__name {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .medium-person-card__name {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
.medium-person-card__text {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (min-width: 768px) {
  .medium-person-card__text {
    grid-column: 2;
  }
}
@media (max-width: 1440px) {
  .medium-person-card__text {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .medium-person-card__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.medium-person-card__link {
  text-decoration: underline;
}
.medium-person-card__link:hover {
  text-decoration: none;
}

.section {
  position: relative;
  overflow: hidden;
}
.section--bg__grey {
  background: #F3F4F6;
}
.section--bg__blue {
  background: #e8f2fc;
}
.section--bg__green {
  background: #F0FAE8;
}
.section--bg__pink {
  background: #FFEBEE;
}
.section--bg__yellow {
  background: #FFFDE6;
}
.section--bg__dark {
  background: #1F2937;
}
.section--bg__blue-middle {
  background: #6ea5f7;
  color: #FFFFFF;
}
.section--is-vertical .section__inner {
  display: grid;
}
@media (min-width: 768px) {
  .section--is-vertical .section__inner--small-header {
    grid-template-columns: 1fr 3fr;
    grid-gap: 48px;
  }
  .section--is-vertical .section__inner--big-header {
    grid-template-columns: 3fr 1fr;
    grid-gap: 200px;
  }
  .section--is-vertical .section__inner:not(.section--is-vertical .section__inner--small-header):not(.section--is-vertical .section__inner--big-header) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 130px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .section--is-vertical .section__inner:not(.section--is-vertical .section__inner--small-header):not(.section--is-vertical .section__inner--big-header) {
    grid-gap: 24px;
    grid-template-columns: 1fr 2fr;
  }
}
.section--is-vertical .section__header {
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 48px;
}
@media (max-width: 1260px) {
  .section--is-vertical .section__header {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .section--is-vertical .section__header {
    grid-gap: 16px;
    grid-template-rows: auto;
  }
}
.section--is-vertical .section__header .section__header__block--right {
  justify-content: flex-start;
}
.section--header-onlyleft .section__header {
  grid-template-columns: 1fr;
}
.section--header-onlyleft .section__header .section__header__block--right {
  display: none;
}
.section--no-header .section__header {
  display: none;
}
.section:not(.section--p0) {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
}
.section__decorative-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
}
.section__decorative-img img {
  width: 300px;
}
@media (max-width: 1440px) {
  .section__decorative-img img {
    width: 180px;
  }
}
@media (max-width: 1260px) {
  .section__decorative-img img {
    width: 120px;
  }
}
.section__decorative-img--big img {
  width: 500px;
}
@media (max-width: 1440px) {
  .section__decorative-img--big img {
    width: 260px;
  }
}
@media (max-width: 1260px) {
  .section__decorative-img--big img {
    width: 180px;
  }
}
@media (max-width: 768px) {
  .section__decorative-img {
    display: none;
  }
}
.section__decorative-img img[src=""] {
  display: none;
}
.section__inner {
  display: grid;
  grid-gap: 48px;
  position: relative;
}
@media (max-width: 1440px) {
  .section__inner {
    grid-gap: 32px;
  }
}
@media (max-width: 1260px) {
  .section__inner {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .section__inner {
    grid-gap: 16px;
  }
}
.section__inner > * {
  min-width: 0;
  width: 100%;
}
.section__header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .section__header {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
@media (max-width: 768px) {
  .section__header__block {
    width: 100%;
  }
}
.section__header__block:empty {
  display: none;
}
@media (min-width: 768px) {
  .section__header__block--small {
    width: 66.6666666667%;
  }
}
.section__header__block--left {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .section__header__block--left {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 1260px) {
  .section__header__block--left {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .section__header__block--left {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.section__header__block--right {
  margin-left: auto;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
.section__header__block--right br {
  display: none;
}
@media (max-width: 1440px) {
  .section__header__block--right {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .section__header__block--right {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .section__header__block--right {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.section__header__block--right__toRight {
  text-align: right;
}
@media (max-width: 768px) {
  .section__header__block--right__toRight {
    text-align: left;
  }
}
.section__footer {
  display: flex;
  justify-content: center;
  grid-gap: 24px;
}
.section__footer:empty {
  display: none;
}
@media (max-width: 768px) {
  .section__footer {
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
  }
}
.section__block:empty {
  display: none;
}

.smallPad section:first-child {
  padding-top: 32px;
}
@media (max-width: 1440px) {
  .smallPad section:first-child {
    padding-top: 16px;
  }
}
@media (max-width: 768px) {
  .smallPad section:first-child {
    padding-top: 0;
  }
}

.badge {
  font-weight: 400;
  padding: 4px 0;
  color: #111827;
  grid-gap: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: "Circe";
  background-color: var(--bg);
}
.badge .icon {
  --size: 16px;
  --color: #111827;
}
.badge :only-child {
  margin-left: var(--horizontal-padding--only-child);
  margin-right: var(--horizontal-padding--only-child);
}
.badge :not(:only-child):first-child {
  margin-left: var(--horizontal-padding--not-only-child);
}
.badge :not(:only-child):last-child {
  margin-right: var(--horizontal-padding--not-only-child);
}
.badge--large,
.news-header__card:nth-of-type(1) .news-header__card__badge .badge,
.news-grid__card__badge .badge {
  --horizontal-padding--only-child: 16px;
  --horizontal-padding--not-only-child: 12px;
  font-size: 16px;
}
@media (max-width: 1440px) {
  .badge--large,
.news-header__card:nth-of-type(1) .news-header__card__badge .badge,
.news-grid__card__badge .badge {
    font-size: 14px;
  }
}
@media (max-width: 1260px) {
  .badge--large,
.news-header__card:nth-of-type(1) .news-header__card__badge .badge,
.news-grid__card__badge .badge {
    font-size: 10px;
    --horizontal-padding--only-child: 8px;
  }
}
.badge--medium {
  --horizontal-padding--only-child: 16px;
  --horizontal-padding--not-only-child: 12px;
  font-size: 14px;
  line-height: 18px;
}
.badge--small,
.news-header__card:nth-of-type(n + 2) .news-header__card__badge .badge {
  --horizontal-padding--only-child: 8px;
  --horizontal-padding--not-only-child: 8px;
  font-size: 10px;
  line-height: 14px;
}
.badge--pink,
.news-grid__card:nth-child(3n) .news-grid__card__badge .badge {
  --bg: #FFCCD1;
}
.badge--green,
.news-header__card__badge .badge,
.news-grid__card:nth-child(3n-2) .news-grid__card__badge .badge {
  --bg: #D9F1C6;
}
.badge--yellow,
.news-grid__card:nth-child(3n-1) .news-grid__card__badge .badge {
  --bg: #FFF9C2;
}
.badge--grey {
  --bg: #E5E7EB;
}
.badge--white {
  --bg: #FFFFFF;
}
.badge--arrow :only-child {
  margin-left: 4px;
  margin-right: 4px;
}
@media (min-width: 768px) {
  .badge--arrow {
    display: none;
  }
}
.badge--arrow.active div {
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .badge--not-visible {
    display: none;
  }
}

.chip {
  padding: 8px 24px;
  border: 2px solid #181743;
  color: #181743;
  border-radius: 32px;
  background: #FFFFFF;
  display: inline-flex;
  font-family: "Circe";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.chip--active {
  background: #181743;
  color: #FFFFFF;
}

.how-is-cards {
  display: flex;
  flex-direction: column;
  grid-gap: 70px;
}
@media (max-width: 768px) {
  .how-is-cards {
    grid-gap: 32px;
  }
}
@media (min-width: 768px) {
  .how-is-cards--flipped .how-is-card:nth-child(odd) {
    grid-template-columns: 10fr 13fr;
  }
}
@media (min-width: 768px) {
  .how-is-cards--flipped .how-is-card:nth-child(even) {
    grid-template-columns: 13fr 10fr;
  }
}
@media (min-width: 768px) {
  .how-is-cards:not(.how-is-cards--flipped) .how-is-card:nth-child(odd) {
    grid-template-columns: 13fr 10fr;
  }
}
@media (min-width: 768px) {
  .how-is-cards:not(.how-is-cards--flipped) .how-is-card:nth-child(even) {
    grid-template-columns: 10fr 13fr;
  }
}

.how-is-card {
  display: grid;
  grid-gap: 54px;
}
@media (max-width: 768px) {
  .how-is-card {
    grid-gap: 16px;
  }
}
@media (min-width: 768px) {
  .how-is-card:nth-child(even) > :nth-child(1) {
    order: 2;
  }
  .how-is-card:nth-child(even) > :nth-child(2) {
    order: 1;
  }
}
.how-is-card:nth-child(even) .how-is-card__splide-arrows {
  justify-content: flex-end;
}
.how-is-card__img {
  width: 100%;
  height: auto;
  overflow: hidden;
  box-shadow: 0 48px 74px -24px rgba(0, 0, 0, 0.18);
}
.how-is-card__img img {
  width: 100%;
}
.how-is-card__img .text-card {
  grid-gap: 0;
  width: 100% !important;
}
@media (max-width: 768px) {
  .how-is-card__img {
    order: 0;
  }
}
.how-is-card__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
@media (max-width: 1440px) {
  .how-is-card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 1260px) {
  .how-is-card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .how-is-card__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.how-is-card__text {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
@media (max-width: 1260px) {
  .how-is-card__text {
    grid-gap: 16px;
  }
}
@media (max-width: 768px) {
  .how-is-card__text {
    grid-gap: 8px;
  }
}
.how-is-card__splide-arrows {
  margin-top: auto;
  display: flex;
}

.tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  position: relative;
  --color:#004de6;
  border-radius: 4px;
  color: var(--color);
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.tag::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 1px solid var(--color);
}
.tag:hover {
  --color:#0015b0;
}
.tag:active, .tag.active {
  color: #FFFFFF;
  --color:#004de6;
  background: var(--color);
}

@media (max-width: 768px) {
  .marquee {
    margin-right: calc(var(--container-padding) * -1);
  }
}
.marquee__row {
  padding: 24px 0;
  border-bottom: 1px solid #1F2937;
}
@media (max-width: 768px) {
  .marquee__row {
    padding: 16px 0;
  }
}
.marquee__row:nth-child(1) {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1260px) {
  .marquee__row:nth-child(1) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .marquee__row:nth-child(1) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
.marquee__row:nth-child(2) {
  font-family: Manrope;
  font-weight: 700;
  font-size: 40px;
}
@media (max-width: 1260px) {
  .marquee__row:nth-child(2) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .marquee__row:nth-child(2) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.marquee__row:nth-child(3) {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
@media (max-width: 1260px) {
  .marquee__row:nth-child(3) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .marquee__row:nth-child(3) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
.marquee__row:nth-child(4) {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
@media (max-width: 1260px) {
  .marquee__row:nth-child(4) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .marquee__row:nth-child(4) {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.marquee__item {
  display: flex;
  align-items: center;
  height: 100%;
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .marquee__item {
    grid-gap: 16px;
  }
}
.marquee__item::after {
  content: "";
  --color:#004de6 !important;
}

.link {
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
  color: #1F2937;
  transition: all 0.2s ease;
}
.link:hover {
  text-decoration: none;
}
.link:hover {
  color: #004de6;
}
.link:active {
  color: #0015b0;
}

.benefits {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 48px;
}
@media (max-width: 1440px) {
  .benefits {
    grid-gap: 56px;
  }
}
@media (max-width: 768px) {
  .benefits {
    overflow: auto;
    justify-content: flex-start;
    grid-gap: 36px;
    margin-left: calc(var(--container-padding) * -1);
    margin-right: calc(var(--container-padding) * -1);
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    margin-bottom: calc(var(--section-padding) * -1);
    padding-bottom: calc(var(--section-padding));
  }
}
.benefits__item {
  flex: 1 1 0;
  max-width: 320px;
  text-align: center;
}
@media (max-width: 1440px) {
  .benefits__item {
    max-width: 245px;
  }
}
@media (max-width: 768px) {
  .benefits__item {
    max-width: 185px;
    flex: 0 0 185px;
  }
}
.benefits__item__img {
  position: relative;
  display: flex;
  justify-content: center;
}
.benefits__item__img__icon {
  position: relative;
  z-index: 1;
  width: 128px;
  height: 128px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .benefits__item__img__icon {
    width: 96px;
    height: 96px;
  }
}
.benefits__item__img__icon img {
  width: 100%;
  height: 100%;
}
.benefits__item__img__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .benefits__item__img__bg {
    width: 120px;
  }
}
.benefits__item__img__bg img {
  width: 100%;
  height: auto;
}
.benefits__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 4px;
}
@media (max-width: 1440px) {
  .benefits__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 1260px) {
  .benefits__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .benefits__item__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.benefits__item__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  color: #6B7280;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .benefits__item__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
@media (max-width: 1440px) {
  .benefits__item__text {
    font-size: 16px;
    white-space: normal;
  }
}
@media (min-width: 1440px) {
  .benefits__item__text br {
    display: none;
  }
}
.benefits__item__text span {
  white-space: nowrap;
}

.benefits::-webkit-scrollbar {
  width: 0;
}

.switch {
  display: inline-flex;
  grid-gap: 12px;
  --height: 22px;
  --size: 16px;
}
.switch input[type=checkbox],
.switch input[type=radio] {
  visibility: hidden;
  width: 40px;
  height: var(--height);
  appearance: initial;
  cursor: pointer;
  position: relative;
}
.switch input[type=checkbox]::before,
.switch input[type=radio]::before {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: calc(var(--height) / 2);
  transition: all 0.2s ease;
}
.switch input[type=checkbox]::after,
.switch input[type=radio]::after {
  --offset: calc((var(--height) - var(--size)) / 2);
  visibility: visible;
  position: absolute;
  top: var(--offset);
  content: "";
  width: var(--size);
  height: var(--size);
  background: #FFFFFF;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.switch input[type=checkbox]:not(:checked)::before,
.switch input[type=radio]:not(:checked)::before {
  background: #DDE6EA;
}
.switch input[type=checkbox]:not(:checked)::after,
.switch input[type=radio]:not(:checked)::after {
  left: var(--offset);
}
.switch input[type=checkbox]:checked::before,
.switch input[type=radio]:checked::before {
  background: #004de6;
}
.switch input[type=checkbox]:checked::after,
.switch input[type=radio]:checked::after {
  left: calc(100% - var(--offset));
  transform: translateX(-100%);
}

.review-card {
  --bg: #E5E7EB;
  --small-text: inherit;
  background: var(--bg);
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
@media (max-width: 1440px) {
  .review-card {
    padding: 16px;
    grid-gap: 16px;
  }
}
@media (max-width: 1260px) {
  .review-card {
    grid-gap: 12px;
  }
}
.review-card--light {
  --bg: #FFFFFF;
  --small-text: #6B7280;
}
.review-card__header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  align-items: flex-start;
  grid-gap: 4px 8px;
}
.review-card__header a {
  grid-column: 1/3;
  grid-row: 2;
}
@media (max-width: 768px) {
  .review-card__header {
    grid-template-columns: 1fr;
    grid-template-rows: initial;
  }
}
.review-card__name {
  grid-column: 1;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .review-card__name {
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .review-card__name {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.review-card__course {
  grid-column: 1;
  text-decoration: none;
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
  color: #6B7280;
}
.review-card__course:hover {
  text-decoration: none;
}
@media (max-width: 1260px) {
  .review-card__course {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
.review-card__badges {
  grid-column: 2;
  grid-row: 1/-1;
}
@media (max-width: 1260px) {
  .review-card__badges {
    grid-column: 1;
    grid-row: 1;
  }
}
.review-card__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 1440px) {
  .review-card__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .review-card__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
.review-card__link {
  margin-top: auto;
  text-align: right;
  color: var(--small-text);
}
.review-card__link a {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}
.review-card__link a:hover {
  text-decoration: none;
}
@media (max-width: 1260px) {
  .review-card__link a {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.reviews-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .reviews-cards {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.text-card {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  height: 100%;
}
@media (max-width: 768px) {
  .text-card {
    grid-gap: 8px;
  }
}
.text-card__img {
  min-width: 0;
}
.text-card__img--type-1 {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 70%;
  overflow: hidden;
}
.text-card__img--type-1 > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 768px) {
  .text-card__img--type-1 ~ .text-card__text {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
.text-card__img--type-2 {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 50%;
  overflow: hidden;
}
.text-card__img--type-2 > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1260px) {
  .text-card__img--type-2 {
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
  }
  .text-card__img--type-2 > :first-child {
    border-radius: inherit;
    mask-image: radial-gradient(#000, #000);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
}
.text-card__text {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  min-width: 0;
}
.text-card__text:hover {
  color: #004de6;
}
@media (max-width: 1440px) {
  .text-card__text {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 1260px) {
  .text-card__text {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .text-card__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}

.advantages {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  background-image: url(../static/img/other/advantages-bg-green.svg);
  background-size: cover;
  background-position: center;
}
.advantages--red {
  background-image: url(../static/img/other/advantages-bg-red.svg);
}
.advantages__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 32px;
}
.advantages__items {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .advantages__items {
    flex-direction: column;
  }
}
.advantages__footer__link {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}
.advantages__footer__link:hover {
  text-decoration: none;
}
@media (max-width: 1440px) {
  .advantages__footer__link {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .advantages__footer__link {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.advantages__item {
  text-align: center;
  padding: 0 32px;
  max-width: 440px;
}
@media (max-width: 768px) {
  .advantages__item {
    max-width: 255px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .advantages__item:nth-of-type(n + 2) {
    border-left: 1px solid rgba(17, 24, 39, 0.4);
  }
}
.advantages__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (min-width: 1260px) and (max-width: 1440px) {
  .advantages__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .advantages__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.advantages__item__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .advantages__item__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .advantages__item__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.glightbox.hidden {
  display: none;
}

@media (min-width: 768px) {
  .programs .splide__list {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
}
.programs .splide__track {
  overflow: visible;
}
.programs__item {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 80px;
  background: #6ea5f7;
  padding: 32px 54px 32px 80px;
  border-radius: 4px;
  height: 100%;
}
@media (max-width: 1440px) {
  .programs__item {
    padding: 24px 32px;
    grid-gap: 32px;
  }
}
@media (max-width: 1260px) {
  .programs__item {
    padding: 16px;
    align-items: flex-start;
    grid-gap: 4px 16px;
  }
}
@media (max-width: 768px) {
  .programs__item {
    grid-gap: 8px 12px;
  }
}
.programs__item__img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: #FFFFFF;
}
@media (max-width: 1260px) {
  .programs__item__img {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 768px) {
  .programs__item__img {
    width: 44px;
    height: 44px;
  }
}
.programs__item__img img {
  width: 100%;
  height: 100%;
}
.programs__item__info {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto 1fr;
  align-items: flex-end;
  grid-gap: 8px;
  height: 100%;
}
.programs__item__info__title {
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
  grid-column: 1/-1;
  align-self: flex-start;
}
@media (max-width: 1440px) {
  .programs__item__info__title {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
@media (max-width: 1260px) {
  .programs__item__info__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .programs__item__info__title {
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}
.programs__item__info__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  grid-column: 1/-1;
  align-self: flex-start;
}
@media (max-width: 1440px) {
  .programs__item__info__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) and (min-width: 768px) {
  .programs__item__info__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
.programs__item__info__price {
  grid-column: 1;
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
}
@media (max-width: 1260px) {
  .programs__item__info__price {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .programs__item__info__price {
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}
.programs__item__info__button {
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
}
.guarantees {
  display: grid;
  grid-gap: 48px;
  grid-template-columns: repeat(3, minmax(0, 305px));
  justify-content: space-between;
}
@media (max-width: 768px) {
  .guarantees {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}
.guarantees__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.guarantees__item__img {
  width: 72px;
  height: 72px;
}
.guarantees__item__img img {
  width: 100%;
  height: 100%;
}
.guarantees__item__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  white-space: initial;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .guarantees__item__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.guarantees__item .icon {
  --size: 16px;
  --color:#9CA3AF;
}
@media (max-width: 1260px) {
  .guarantees__item .icon {
    display: none;
  }
}

.partners {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 32px;
}
@media (max-width: 1440px) {
  .partners {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .partners {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px 48px;
  }
}
.partners__item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 768px) {
  .clients .splide__list {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .clients .splide__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
.clients__item {
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  background: #FFFFFF;
  padding: 32px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .clients__item {
    padding: 16px;
    grid-gap: 8px;
  }
}
.clients__item__img {
  height: 66px;
}
.clients__item__img img {
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .clients__item__img {
    height: 48px;
  }
}
.clients__item__name {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .clients__item__name {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.clients__item__description {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .clients__item__description {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.clients__item__link {
  margin-top: auto;
}
.small-advantages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
}
.small-advantages__item {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
.small-advantages__item__img {
  width: 64px;
  height: 64px;
}
.small-advantages__item__img img {
  width: 100%;
  height: 100%;
}
.small-advantages__item__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}

.enrolling {
  counter-reset: biba;
}
.enrolling__item {
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-gap: 16px;
  padding: 32px 0;
  align-items: flex-start;
  border-top: 1px solid #1F2937;
}
@media (max-width: 1440px) {
  .enrolling__item {
    padding: 24px 0;
  }
}
@media (max-width: 768px) {
  .enrolling__item {
    grid-template-columns: 1fr 7fr;
    grid-gap: 8px 26px;
    padding: 16px 0;
  }
}
.enrolling__item:last-child {
  border-bottom: 1px solid #1F2937;
}
.enrolling__item::before {
  counter-increment: biba;
  font-family: Manrope;
  font-weight: 800;
  font-size: 72px;
  color: #004de6;
  grid-row: span 2;
}
@media (max-width: 1440px) {
  .enrolling__item::before {
    font-family: Manrope;
    font-weight: 800;
    font-size: 60px;
  }
}
.enrolling__item:nth-child(-n+9)::before {
  content: "0" counter(biba);
}
.enrolling__item:nth-child(n+10)::before {
  content: counter(biba);
}
.enrolling__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  grid-column: 2;
}
@media (max-width: 1440px) {
  .enrolling__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 1260px) {
  .enrolling__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .enrolling__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.enrolling__item__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  grid-column: 2;
}
@media (max-width: 1440px) {
  .enrolling__item__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .enrolling__item__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .enrolling__item__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.video-card {
  position: relative;
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 125%;
  overflow: hidden;
}
.video-card > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.video-card::after, .video-card::before {
  content: "";
  width: 100%;
  height: 128px;
  position: absolute;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.video-card::before {
  top: 0;
}
.video-card::after {
  bottom: 0;
  transform: scaleY(-1);
}
.video-card__text {
  color: #FFFFFF;
  position: absolute;
  top: 24px;
  bottom: 24px;
  left: 24px;
  right: 24px;
  z-index: 2;
}
.video-card__text__post {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}
.video-card__text__name {
  font-family: Circe;
  font-size: 16px;
  font-weight: 700;
}
.video-card__play {
  border-radius: 50%;
  background: rgba(31, 41, 55, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
}
.video-card__play::before {
  content: "";
  --size: 40px;
  --color: #FFFFFF;
}

.learning {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 24px;
  counter-reset: biba;
  padding-bottom: 100px;
}
.learning:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(../static/img/other/learning-bg-string.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 768px) {
  .learning:before {
    transform: rotate(90deg) scale(2);
  }
}
@media (max-width: 768px) {
  .learning {
    grid-template-columns: 1fr;
    grid-gap: 60px;
  }
}
.learning__item {
  counter-increment: biba;
  display: grid;
  text-align: center;
}
.learning__item__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 768px) {
  .learning__item__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.learning__item::before {
  content: counter(biba);
  font-family: Manrope;
  font-weight: 800;
  font-size: 72px;
  color: #004de6;
}

.program-grid__header {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 14px;
  text-align: center;
}
@media (max-width: 768px) {
  .program-grid__header {
    display: none;
  }
}
.program-grid__body .program-grid__row {
  padding: 26px 0;
  border-bottom: 1px solid #9CA3AF;
}
@media (max-width: 1440px) {
  .program-grid__body .program-grid__row {
    padding: 20px 0;
  }
}
@media (max-width: 768px) {
  .program-grid__body .program-grid__row {
    padding: 13px 0;
  }
}
.program-grid__body .program-grid__row:first-child {
  border-top: 1px solid #9CA3AF;
}
.program-grid__body .program-grid__row__cell:first-child {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
@media (max-width: 1440px) {
  .program-grid__body .program-grid__row__cell:first-child {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 1260px) {
  .program-grid__body .program-grid__row__cell:first-child {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .program-grid__body .program-grid__row__cell:first-child {
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}
.program-grid__body .program-grid__row__cell:nth-child(n+2) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.program-grid__body .program-grid__row__cell .badge {
  white-space: nowrap;
}
.program-grid__row {
  display: grid;
  grid-template-columns: 10fr 1fr 1fr;
  grid-gap: 12px;
}
.breadcrumbs {
  position: relative;
}
@media (max-width: 768px) {
  .breadcrumbs {
    position: static;
  }
}

.header-banner {
  background-size: cover !important;
  background-position: center !important;
}
.header-banner__content {
  --v-padding: 72px;
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-gap: 32px;
  padding-top: 24px;
  padding-bottom: 32px;
  position: relative;
}
@media (max-width: 768px) {
  .header-banner__content {
    grid-template-columns: 1fr !important;
    --v-padding: 16px;
    grid-gap: 16px;
    padding-bottom: 24px;
  }
}
@media (max-width: 1440px) {
  .header-banner__content {
    grid-template-columns: 5fr 3fr;
  }
}
@media (max-width: 1260px) {
  .header-banner__content {
    grid-template-columns: 5fr 4fr;
  }
}
.header-banner__content__promo {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 320px;
  transform: translateX(-50%);
  background: #FFDE03;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  padding: 16px;
  border-radius: 4px;
}
@media (max-width: 1260px) {
  .header-banner__content__promo {
    grid-gap: 12px;
    left: initial;
    transform: none;
    right: 0;
    padding: 12px;
  }
}
.header-banner__content__promo__icon .icon {
  --color: #1F2937;
  --size: 48px;
}
@media (max-width: 1260px) {
  .header-banner__content__promo__icon .icon {
    --size: 32px;
  }
}
.header-banner__content__promo__text {
  width: auto;
}
.header-banner__content__promo__text p:first-child {
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 1260px) {
  .header-banner__content__promo__text p:first-child {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
.header-banner__content__promo__text p:last-child {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .header-banner__content__promo__text p:last-child {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.header-banner__content__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 32px;
}
@media (max-width: 1440px) {
  .header-banner__content__left {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .header-banner__content__left {
    grid-gap: 16px;
  }
}
.header-banner__content__left__footer {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 32px;
  width: 100%;
}
@media (max-width: 768px) {
  .header-banner__content__left__footer {
    margin-top: 0;
  }
}
.header-banner__content__right {
  display: flex;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 1260px) {
  .header-banner__content__right {
    justify-content: center;
  }
}
@media (min-width: 1440px) {
  .header-banner__content__right:has(.header-banner__content__promo) .header-banner__img {
    padding-top: 115px;
  }
}
@media (max-width: 1440px) {
  .header-banner__content__right:has(.header-banner__content__promo) .header-banner__img {
    padding-top: 75px;
  }
}
@media (max-width: 1440px) {
  .header-banner__content__right:has(.header-banner__content__promo) .header-banner__img img {
    max-height: 340px;
  }
}
.header-banner__img img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .header-banner--img-hide-mobile .header-banner__img img {
    display: none !important;
  }
}
.header-banner__price {
  display: flex;
  align-items: flex-end;
  grid-gap: 16px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .header-banner__price {
    align-items: baseline;
    grid-gap: 8px;
  }
}
.header-banner__price span:first-child {
  font-family: Manrope;
  font-weight: 700;
  font-size: 40px;
}
@media (max-width: 1440px) {
  .header-banner__price span:first-child {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 1260px) {
  .header-banner__price span:first-child {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.header-banner__price span:not(:first-child) {
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-decoration: line-through;
  color: #9CA3AF;
}
@media (max-width: 1440px) {
  .header-banner__price span:not(:first-child) {
    font-size: 26px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .header-banner__price span:not(:first-child) {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header-banner__price span:not(:first-child) {
    font-family: Circe;
    font-size: 24px;
    font-weight: 400;
  }
}
.header-banner__badges {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
}
.header-banner__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .header-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 1260px) {
  .header-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
    line-height: 140% !important;
  }
}
.header-banner__subtitle {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .header-banner__subtitle {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .header-banner__subtitle {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header-banner__subtitle {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.header-banner__subtitle ul {
  list-style-type: none;
}
.header-banner__subtitle ul li {
  display: flex;
}
.header-banner__subtitle ul li::before {
  content: "•";
  margin-right: 12px;
}
.header-banner__buttons {
  display: flex;
  grid-gap: 16px;
}
@media (max-width: 768px) {
  .header-banner__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-gap: 8px;
  }
}
.header-banner .text-accent {
  color: var(--accent);
}
.header-banner__links a {
  display: block;
  text-decoration: underline;
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
.header-banner__links a:hover {
  text-decoration: none;
}
@media (max-width: 1440px) {
  .header-banner__links a {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header-banner__links a {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.header-banner--accent__green {
  --accent: #A4DE78;
}
.header-banner--accent__blue {
  --accent: #004de6;
}
.header-banner--accent__yellow {
  --accent: #FBA612;
}
.header-banner--accent__pink {
  --accent: #EE6F6F;
}
.header-banner__content__promo__text.is-visible {
  display: block;
}

@media (max-width: 768px) {
  .header-banner1 {
    background: none !important;
  }
}
.header-banner1__content {
  --v-padding: 72px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-gap: 56px;
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}
@media (min-width: 1536px) {
  .header-banner1__content {
    margin-right: 0;
    margin-left: calc((100vw - 1536px) / 2);
    max-width: calc(100vw - (100vw - 1536px) / 2);
    grid-template-columns: 593.1428571429px 1fr;
  }
}
@media (max-width: 768px) {
  .header-banner1__content {
    grid-template-columns: 1fr !important;
    --v-padding: 16px;
    grid-gap: 16px;
  }
}
@media (max-width: 1440px) {
  .header-banner1__content {
    grid-template-columns: 3fr 4fr;
  }
}
.header-banner1__content__promo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #FFDE03;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  padding: 16px;
  border-radius: 4px;
}
@media (max-width: 1260px) {
  .header-banner1__content__promo {
    grid-gap: 12px;
    left: initial;
    transform: none;
    right: 0;
    padding: 12px;
  }
}
.header-banner1__content__promo__icon .icon {
  --color: #1F2937;
  --size: 48px;
}
@media (max-width: 1260px) {
  .header-banner1__content__promo__icon .icon {
    --size: 32px;
  }
}
.header-banner1__content__promo__text {
  width: auto;
}
.header-banner1__content__promo__text p:first-child {
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 1260px) {
  .header-banner1__content__promo__text p:first-child {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
.header-banner1__content__promo__text p:last-child {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .header-banner1__content__promo__text p:last-child {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.header-banner1__content__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 32px;
}
@media (max-width: 1440px) {
  .header-banner1__content__left {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .header-banner1__content__left {
    grid-gap: 16px;
  }
}
.header-banner1__content__left__footer {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 32px;
  width: 100%;
}
.header-banner1__content__right {
  display: flex;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 768px) {
  .header-banner1__content__right {
    justify-content: center;
  }
}
.header-banner1__img img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .header-banner1--img-hide-mobile .header-banner1__img img {
    display: none !important;
  }
}
.header-banner1__price {
  display: flex;
  align-items: flex-end;
  grid-gap: 16px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .header-banner1__price {
    align-items: baseline;
    grid-gap: 8px;
  }
}
.header-banner1__price span:first-child {
  font-family: Manrope;
  font-weight: 700;
  font-size: 40px;
}
@media (max-width: 1440px) {
  .header-banner1__price span:first-child {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 1260px) {
  .header-banner1__price span:first-child {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.header-banner1__price span:not(:first-child) {
  font-family: "Circe";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-decoration: line-through;
  color: #9CA3AF;
}
@media (max-width: 1440px) {
  .header-banner1__price span:not(:first-child) {
    font-size: 26px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .header-banner1__price span:not(:first-child) {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header-banner1__price span:not(:first-child) {
    font-family: Circe;
    font-size: 24px;
    font-weight: 400;
  }
}
.header-banner1__badges {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
}
.header-banner1__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .header-banner1__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 1260px) {
  .header-banner1__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
.header-banner1__subtitle {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .header-banner1__subtitle {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .header-banner1__subtitle {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header-banner1__subtitle {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
.header-banner1__subtitle ul {
  list-style-type: none;
}
.header-banner1__subtitle ul li {
  display: flex;
}
.header-banner1__subtitle ul li::before {
  content: "•";
  margin-right: 12px;
}
.header-banner1__buttons {
  display: flex;
  grid-gap: 16px;
}
@media (max-width: 768px) {
  .header-banner1__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-gap: 8px;
  }
}
.header-banner1 .text-accent {
  color: var(--accent);
}
.header-banner1__links a {
  display: block;
  text-decoration: underline;
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
.header-banner1__links a:hover {
  text-decoration: none;
}
@media (max-width: 1440px) {
  .header-banner1__links a {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .header-banner1__links a {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.header-banner1--accent__green {
  --accent: #A4DE78;
}
.header-banner1--accent__blue {
  --accent: #004de6;
}
.header-banner1--accent__yellow {
  --accent: #FBA612;
}
.header-banner1--accent__pink {
  --accent: #EE6F6F;
}
.person-card {
  padding: 24px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  background: #FFFFFF;
  height: 100%;
}
@media (max-width: 1440px) {
  .person-card {
    padding: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .person-card {
    margin: -16;
  }
}
@media (max-width: 768px) {
  .person-card {
    padding: 24px;
  }
}
.person-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 110%;
  overflow: hidden;
  border-radius: 4px;
}
.person-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 768px) {
  .person-card__img {
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 133.3333333333%;
    overflow: hidden;
  }
  .person-card__img > :first-child {
    border-radius: inherit;
    mask-image: radial-gradient(#000, #000);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
}
.person-card__name {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
@media (max-width: 1440px) {
  .person-card__name {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 1260px) {
  .person-card__name {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .person-card__name {
    font-family: Circe;
    font-size: 24px;
    font-weight: 700;
  }
}
.person-card__post {
  color: #004de6;
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}
@media (max-width: 1440px) {
  .person-card__post {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .person-card__post {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .person-card__post {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
.person-card__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .person-card__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .person-card__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.price-card {
  text-align: center;
  padding: 24px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  transition: all 0.2s ease;
  color: var(--text-color);
  --time-color:#004de6;
  --text-color: #111827;
  --old-price-color:#9CA3AF;
}
.price-card:hover {
  background: #004de6;
  --time-color:#FFFFFF;
  --text-color: #FFFFFF;
  --old-price-color:#F3F4F6;
}
.price-card__top {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
}
.price-card__time {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  color: var(--time-color);
}
.price-card__price {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
}
.price-card__price span:first-child {
  font-family: Manrope;
  font-weight: 700;
  font-size: 40px;
}
@media (max-width: 1440px) {
  .price-card__price span:first-child {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
.price-card__price span:first-child ~ span {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
  text-decoration-line: line-through;
  color: var(--old-price-color);
}
@media (max-width: 1440px) {
  .price-card__price span:first-child ~ span {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
.price-card__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}

.img-banner {
  padding: 48px;
  background: #004CE4;
  border-radius: 12px;
  color: #FFFFFF;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 94px;
}
.img-banner--is-vertical {
  grid-template-columns: 1fr;
  grid-gap: 24px;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .img-banner {
    padding: 32px;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .img-banner {
    grid-template-columns: 1fr;
    padding: 16px;
  }
}
.img-banner__info {
  display: flex;
  flex-direction: column;
  grid-gap: 56px;
}
@media (max-width: 1440px) {
  .img-banner__info {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .img-banner__info {
    grid-gap: 16px;
  }
}
.img-banner__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .img-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
@media (max-width: 1260px) {
  .img-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .img-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.img-banner__text {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .img-banner__text {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .img-banner__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.img-banner__link {
  margin-top: auto;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
.img-banner__link > *:not(.button) {
  text-decoration: none;
  display: inline-block;
  line-height: 1;
  position: relative;
}
.img-banner__link > *:not(.button)::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
}
.img-banner__link:hover {
  text-decoration: none;
}
.img-banner__link:empty {
  display: none;
}
.img-banner__img img {
  display: block;
  width: 100%;
  height: auto;
}

.form-banner {
  border-radius: 12px;
  padding: 48px;
  display: grid;
  grid-template-columns: 9fr 10fr;
  grid-gap: 16px 80px;
  align-items: flex-start;
}
@media (max-width: 1440px) {
  .form-banner {
    padding: 32px;
  }
}
@media (max-width: 1260px) {
  .form-banner {
    grid-gap: 16px 48px;
  }
}
@media (max-width: 768px) {
  .form-banner {
    grid-template-columns: 1fr;
    padding: 16px;
    grid-gap: 0;
  }
  .form-banner * + *:not(.custom-input__label) {
    margin-top: 12px;
  }
}
.form-banner::before {
  content: "";
  position: absolute;
  bottom: 15px;
  right: 51%;
  width: 480px;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 1440px) {
  .form-banner::before {
    display: none;
  }
}
.form-banner--type-1 {
  grid-template-rows: min-content auto;
  grid-gap: 16px 16%;
  grid-template-columns: 446fr 668fr;
}
@media (max-width: 768px) {
  .form-banner--type-1 {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 768px) {
  .form-banner--type-1 .form-banner__info {
    grid-column: 2;
  }
}
@media (max-width: 768px) {
  .form-banner--type-1 .form-banner__img {
    display: none;
  }
}
@media (min-width: 768px) {
  .form-banner--type-1 .form-banner__img {
    grid-row: 1/-1;
  }
}
.form-banner--type-1 .form-banner__form {
  align-self: flex-end;
}
.form-banner--type-1::before {
  display: none;
}
.form-banner__img {
  width: 100%;
}
.form-banner__img img {
  width: 100%;
  height: auto;
}
.form-banner__info {
  display: grid;
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .form-banner__info {
    grid-gap: 0;
  }
}
.form-banner__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .form-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
  }
}
@media (max-width: 1260px) {
  .form-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .form-banner__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.form-banner__link {
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
  color: var(--accent);
}
@media (max-width: 1440px) {
  .form-banner__link {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
@media (max-width: 1260px) {
  .form-banner__link {
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
  }
}
.form-banner__text {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .form-banner__text {
    font-family: Circe;
    font-size: 20px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .form-banner__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.form-banner__form {
  grid-column: 2;
}
@media (max-width: 768px) {
  .form-banner__form {
    grid-column: 1;
  }
}
.form-banner__form .button {
  margin-left: auto;
}
@media (max-width: 768px) {
  .form-banner__form .button {
    margin-right: auto;
  }
}
.form-banner--dark {
  background: #1F2937;
  color: #FFFFFF;
}
.form-banner--dark .form-banner__title {
  color: var(--accent);
}
.form-banner--light {
  background: #A4DE78;
}
.form-banner--light .custom-checkbox input::before {
  border-color: #111827;
}
.form-banner--blue {
  background: #004de6;
  color: #FFFFFF;
}
.form-banner--accent-green {
  --accent: #A4DE78;
}
.form-banner--accent-green::before {
  background-image: url(../static/img/form-banner-arrow/green.png);
}
.form-banner--accent-green-without-image {
  --accent: #A4DE78;
}
.form-banner--accent-blue {
  --accent: #004de6;
}
.form-banner--accent-yellow {
  --accent: #FBA612;
}
.form-banner--accent-yellow::before {
  background-image: url(../static/img/form-banner-arrow/yellow.png);
}
.form-banner--accent-pink {
  --accent: #EE6F6F;
}
.for-who {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 111px;
}
@media (max-width: 1440px) {
  .for-who {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .for-who {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-items: flex-start;
  }
}
.for-who__item {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  min-width: 0;
}
@media (max-width: 768px) {
  .for-who__item {
    padding: 16px 0;
  }
}
.for-who__item__decoration {
  display: flex;
  align-items: center;
  grid-gap: 24px;
}
@media (max-width: 1260px) {
  .for-who__item__decoration {
    grid-gap: 16px;
  }
}
@media (max-width: 768px) {
  .for-who__item__decoration {
    grid-gap: 8px;
  }
}
.for-who__item__decoration::before {
  content: "";
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #004de6;
}
@media (min-width: 768px) and (max-width: 1260px) {
  .for-who__item__decoration::before {
    height: 16px;
    width: 16px;
  }
}
.for-who__item__decoration::after {
  content: "";
  opacity: 0.4;
  border-bottom: 2px dashed #004de6;
  flex-grow: 1;
}
.for-who__item__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .for-who__item__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .for-who__item__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.tabs__control input {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
}
.tabs__control__visual {
  cursor: pointer;
  user-select: none;
}
.tabs--frame .tabs__controls {
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  display: inline-flex;
  gap: 4px;
  padding: 8px;
}
.tabs--row .tabs__controls {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
.tabs--row .tabs__control {
  flex: 1 1 0;
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}
.tabs--row .tabs__control__visual::after {
  content: "";
  border-radius: 24px;
  height: 4px;
}
.tabs--row .tabs__control input:not(:checked) ~ .tabs__control__visual {
  color: #9CA3AF;
}
.tabs--row .tabs__control input:not(:checked) ~ .tabs__control__visual::after {
  background: #E5E7EB;
}
.tabs--row .tabs__control input:checked ~ .tabs__control__visual {
  color: #4C4C4C;
}
.tabs--row .tabs__control input:checked ~ .tabs__control__visual::after {
  background: #004CE4;
}
.tabs__contents input {
  visibility: hidden;
  position: absolute;
  z-index: -9999;
}
.tabs__contents input:not(:checked) ~ .tabs__content__visual {
  display: none !important;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

[class*=splide--type-] .counter {
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  margin-bottom: 4px;
}
@media (min-width: 768px) {
  [class*=splide--type-] .counter {
    display: none;
  }
}
[class*=splide--type-] .counter::before {
  content: attr(data-slide-current);
}
[class*=splide--type-] .counter::after {
  content: "/" attr(data-slide-total);
}

.splide {
  --arrow-size: 52px;
}
@media (max-width: 1260px) {
  .splide {
    --arrow-size: 32px;
  }
}
@media (max-width: 768px) {
  .splide {
    --arrow-size: 24px;
  }
}
.splide__arrows {
  --arrow-size: 52px;
}
@media (max-width: 1260px) {
  .splide__arrows {
    --arrow-size: 32px;
  }
}
@media (max-width: 768px) {
  .splide__arrows {
    --arrow-size: 24px;
  }
}
.splide__arrow {
  cursor: pointer;
  width: var(--arrow-size);
  height: var(--arrow-size);
  border: none;
  outline: none;
  --size: var(--arrow-size);
}
.splide__arrow:disabled {
  opacity: 0.4;
}
.splide__arrow::before {
  content: "";
}
@media (max-width: 768px) {
  .splide--type-1 .splide__slide {
    width: 56% !important;
  }
}
@media (max-width: 768px) {
  .splide--type-11 .splide__slide {
    width: 95% !important;
  }
}
.splide--type-1 .splide, .splide--type-10 .splide, .splide--type-11 .splide {
  position: relative;
  padding: 0 calc(var(--arrow-size) + 24px);
}
@media (max-width: 1260px) {
  .splide--type-1 .splide, .splide--type-10 .splide, .splide--type-11 .splide {
    padding: 0 calc(var(--arrow-size) + 16px);
  }
}
@media (max-width: 768px) {
  .splide--type-1 .splide, .splide--type-10 .splide, .splide--type-11 .splide {
    padding: 0;
  }
}
.splide--type-1 .splide__arrow, .splide--type-10 .splide__arrow, .splide--type-11 .splide__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.splide--type-1 .splide__arrow--prev, .splide--type-10 .splide__arrow--prev, .splide--type-11 .splide__arrow--prev {
  left: 0;
}
.splide--type-1 .splide__arrow--next, .splide--type-10 .splide__arrow--next, .splide--type-11 .splide__arrow--next {
  right: 0;
}
@media (max-width: 768px) {
  .splide--type-1 .splide__track, .splide--type-10 .splide__track, .splide--type-11 .splide__track {
    overflow: visible;
  }
}
.splide--type-2 .splide, .splide--type-7 .splide {
  display: flex;
  align-items: center;
  gap: 24px;
}
.splide--type-2 .splide__arrows, .splide--type-7 .splide__arrows {
  display: flex;
}
.splide--type-2 .splide__arrow, .splide--type-7 .splide__arrow {
  --color: #004de6;
}
.splide--type-2 .splide__track, .splide--type-7 .splide__track {
  width: 0;
  clip-path: inset(-100vw -100vw -100vw 0);
  overflow: visible;
  flex-grow: 1;
}
.splide--type-3 .splide {
  display: flex;
  align-items: center;
  gap: 24px;
}
.splide--type-3 .splide__arrows {
  display: flex;
}
.splide--type-3 .splide__track {
  width: 0;
  clip-path: inset(-100vw -100vw -100vw 0);
  overflow: visible;
  flex-grow: 1;
}
.splide--type-3 .splide .news-card__img img {
  filter: grayscale(100%);
  transition: all 0.2s;
}
.splide--type-3 .splide .news-card__img:hover img {
  filter: grayscale(0%);
}
.splide--type-4 {
  --v-padding: 24px;
  border-radius: 4px;
}
@media (max-width: 1440px) {
  .splide--type-4 {
    --v-padding: 16px;
  }
}
.splide--type-4 .splide {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: var(--v-padding) 32px;
  background: #D1D5DB;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .splide--type-4 .splide {
    padding: var(--v-padding) 16px;
  }
}
.splide--type-4 .splide::before {
  content: "";
  background: #D1D5DB;
  position: absolute;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
}
.splide--type-4 .splide__arrows {
  display: flex;
}
.splide--type-4 .splide__arrow {
  --color: #004de6;
}
.splide--type-4 .splide__track {
  width: 0;
  clip-path: inset(-100vw -100vw -100vw 0);
  overflow: visible;
  flex-grow: 1;
}
.splide--type-4 .splide__slide {
  width: 293px !important;
  /* @media (min-width: $mobile) and (max-width: $desktop-small) {
  	width: 75%;
  } */
}
@media (max-width: 1440px) {
  .splide--type-4 .splide__slide {
    width: 232px !important;
  }
}
.splide--type-5_1 .counter {
  display: none;
}
.splide--type-5 .counter {
  display: none;
}
.splide--type-5 .splide {
  position: relative;
}
@media (min-width: 1440px) {
  .splide--type-5 .splide {
    padding: 0 calc(var(--arrow-size) + 24px);
  }
}
@media (max-width: 768px) {
  .splide--type-5 .splide {
    padding: 0;
  }
}
.splide--type-5 .splide__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.splide--type-5 .splide__arrow--prev {
  left: 0;
}
.splide--type-5 .splide__arrow--next {
  right: 0;
}
.splide--type-8 .splide {
  height: 100%;
}
.splide__slide.active p {
  font-weight: 700;
}
.splide__slide.active span {
  color: #004de6;
}

.text-content,
.info-page__right,
.how-is-card__description {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .text-content,
.info-page__right,
.how-is-card__description {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .text-content,
.info-page__right,
.how-is-card__description {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.text-content__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
@media (max-width: 1440px) {
  .text-content__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .text-content__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.text-content a:not(.button),
.info-page__right a:not(.button),
.how-is-card__description a:not(.button) {
  text-decoration: underline;
}
.text-content a:not(.button):hover,
.info-page__right a:not(.button):hover,
.how-is-card__description a:not(.button):hover {
  text-decoration: none;
}
.text-content p,
.info-page__right p,
.how-is-card__description p {
  margin: 1em 0;
}
.text-content h2,
.info-page__right h2,
.how-is-card__description h2 {
  margin-top: 32px;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .text-content h2,
.info-page__right h2,
.how-is-card__description h2 {
    margin: 16px 0;
  }
}
.text-content h3,
.info-page__right h3,
.how-is-card__description h3 {
  margin-top: 32px;
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .text-content h3,
.info-page__right h3,
.how-is-card__description h3 {
    margin: 16px 0;
  }
}
.text-content h4,
.info-page__right h4,
.how-is-card__description h4 {
  margin-top: 32px;
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .text-content h4,
.info-page__right h4,
.how-is-card__description h4 {
    margin: 16px 0;
  }
}
.text-content img,
.info-page__right img,
.how-is-card__description img {
  width: 100%;
  height: auto;
  margin: 1em 0;
}
.text-content ul,
.text-content ol,
.info-page__right ul,
.how-is-card__description ul,
.info-page__right ol,
.how-is-card__description ol {
  margin: 1em 0;
  list-style-position: inside;
}
.text-content ul li + li,
.text-content ol li + li,
.info-page__right ul li + li,
.how-is-card__description ul li + li,
.info-page__right ol li + li,
.how-is-card__description ol li + li {
  margin-top: 0.5em;
}
.text-content :first-child,
.info-page__right :first-child,
.how-is-card__description :first-child {
  margin-top: 0;
}
.text-content :last-child,
.info-page__right :last-child,
.how-is-card__description :last-child {
  margin-bottom: 0;
}

.big-person-card {
  padding: 32px;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 15fr;
  grid-template-rows: min-content min-content 1fr;
  grid-gap: 0 32px;
  background: #FFFFFF;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .big-person-card {
    grid-template-columns: 1fr;
    padding: 16px;
  }
}
.big-person-card--dark {
  background: #F3F4F6;
}
.big-person-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}
.big-person-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 768px) {
  .big-person-card__img {
    grid-column: 1;
    grid-row: 1/span all;
  }
}
@media (max-width: 768px) {
  .big-person-card__img {
    margin-bottom: 32px;
  }
}
.big-person-card__name {
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}
@media (max-width: 1260px) {
  .big-person-card__name {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
@media (min-width: 768px) {
  .big-person-card__name {
    grid-column: 2;
  }
}
.big-person-card__post {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  color: #6B7280;
  margin-bottom: 16px;
}
@media (max-width: 1260px) {
  .big-person-card__post {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .big-person-card__post {
    grid-column: 2;
  }
}
.big-person-card__text {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .big-person-card__text {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 1260px) {
  .big-person-card__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .big-person-card__text {
    grid-column: 2;
  }
}

.big-person-cards {
  display: grid;
  grid-gap: 32px;
}

.big-person-card:last-child {
  margin-bottom: 0;
}

.chosen-by {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
}
.chosen-by__card {
  display: grid;
  grid-gap: 16px;
  background: #FFFFFF;
  padding: 32px;
}
.chosen-by__card__img {
  height: 66px;
}
.chosen-by__card__img img {
  height: 100%;
  width: auto;
}
.chosen-by__card__name {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
}
.chosen-by__card__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}

.why-us {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 48px;
  justify-content: space-between;
}
@media (max-width: 1440px) {
  .why-us {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .why-us {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
.why-us__item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 12px 40px;
}
@media (max-width: 1260px) {
  .why-us__item {
    grid-gap: 8px 16px;
  }
}
@media (max-width: 768px) {
  .why-us__item {
    grid-gap: 8px 16px;
  }
}
.why-us__item__img {
  width: 128px;
  height: 128px;
  grid-column: 1;
  grid-row: 1/-1;
}
@media (max-width: 1440px) {
  .why-us__item__img {
    width: 92px;
    height: 92px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .why-us__item__img {
    width: 48px;
    height: 48px;
  }
}
@media (max-width: 768px) {
  .why-us__item__img {
    width: 64px;
    height: 64px;
  }
}
.why-us__item__img img {
  width: 100%;
  height: 100%;
}
.why-us__item__title {
  grid-column: 2;
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
@media (max-width: 1440px) {
  .why-us__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 1260px) {
  .why-us__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .why-us__item__title {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
.why-us__item__text {
  grid-column: 2;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .why-us__item__text {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.news-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  grid-gap: 16px;
}
.news-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 80%;
  overflow: hidden;
  border-radius: 4px;
}
.news-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.news-card__text {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}
.news-card__text:hover {
  color: #004de6;
}

.learning-steps {
  display: grid;
  grid-template-columns: 5fr 8fr;
}
@media (max-width: 768px) {
  .learning-steps {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
.learning-steps__list__item {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 4px 32px;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .learning-steps__list__item {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
    grid-gap: 4px 16px;
  }
}
@media (max-width: 768px) {
  .learning-steps__list__item {
    grid-gap: 0 16px;
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
.learning-steps__list__item__text {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .learning-steps__list__item__text {
    width: calc(100vw - 93px);
  }
}
.learning-steps__list__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}
@media (max-width: 1260px) {
  .learning-steps__list__item__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .learning-steps__list__item__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.learning-steps__list__item::before {
  counter-increment: biba;
  content: counter(biba);
  font-family: Manrope;
  font-weight: 800;
  font-size: 72px;
  color: #004de6;
  grid-column: 1;
  grid-row: 1/-1;
  align-self: center;
  width: 45px;
}
@media (max-width: 1440px) {
  .learning-steps__list__item::before {
    font-family: Manrope;
    font-weight: 800;
    font-size: 56px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .learning-steps__list__item::before {
    width: 27px;
  }
}
@media (max-width: 1260px) {
  .learning-steps__list__item::before {
    font-family: Manrope;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
  }
}
.learning-steps__list .splide__list {
  counter-reset: biba;
}
@media (min-width: 1260px) {
  .learning-steps__list .splide__list {
    grid-gap: 24px;
  }
}
@media (min-width: 768px) {
  .learning-steps__list .splide__list {
    grid-gap: 16px;
    flex-direction: column;
    align-self: flex-start;
    display: flex !important;
  }
}
@media (max-width: 768px) {
  .learning-steps__slider {
    width: calc(100vw - 32px);
  }
}

.mini-banner {
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .mini-banner {
    background-image: none !important;
  }
}
.mini-banner--inverted {
  color: #FFFFFF;
}
.mini-banner--type-1, .mini-banner--type-3 {
  color: #FFFFFF;
}
.mini-banner--type-1 .mini-banner__block:nth-child(1), .mini-banner--type-3 .mini-banner__block:nth-child(1) {
  align-self: center;
  font-family: Manrope;
  font-weight: 700;
  font-size: 40px;
}
@media (max-width: 1440px) {
  .mini-banner--type-1 .mini-banner__block:nth-child(1), .mini-banner--type-3 .mini-banner__block:nth-child(1) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .mini-banner--type-1 .mini-banner__block:nth-child(1), .mini-banner--type-3 .mini-banner__block:nth-child(1) {
    grid-column: 1/-1;
  }
}
@media (max-width: 768px) {
  .mini-banner--type-1 .mini-banner__block:nth-child(1), .mini-banner--type-3 .mini-banner__block:nth-child(1) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.mini-banner--type-1 .mini-banner__block:nth-child(2), .mini-banner--type-3 .mini-banner__block:nth-child(2) {
  padding: 6px 0;
  text-align: left;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .mini-banner--type-1 .mini-banner__block:nth-child(2), .mini-banner--type-3 .mini-banner__block:nth-child(2) {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .mini-banner--type-1 .mini-banner__block:nth-child(2), .mini-banner--type-3 .mini-banner__block:nth-child(2) {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
    display: block;
  }
}
.mini-banner--type-1 .mini-banner__block:nth-child(3), .mini-banner--type-3 .mini-banner__block:nth-child(3) {
  padding: 6px 0;
}
.mini-banner--type-1 .mini-banner__inner, .mini-banner--type-3 .mini-banner__inner {
  height: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  grid-template-columns: 50% 1fr auto;
  align-items: flex-start;
}
@media (max-width: 1260px) {
  .mini-banner--type-1 .mini-banner__inner, .mini-banner--type-3 .mini-banner__inner {
    grid-gap: 24px;
  }
}
@media (max-width: 1024px) {
  .mini-banner--type-1 .mini-banner__inner, .mini-banner--type-3 .mini-banner__inner {
    grid-template-columns: 1fr auto;
    grid-gap: 16px;
  }
}
@media (max-width: 768px) {
  .mini-banner--type-1 .mini-banner__inner, .mini-banner--type-3 .mini-banner__inner {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
}
.mini-banner--type-2 .mini-banner__block {
  flex-direction: column;
}
@media (max-width: 768px) {
  .mini-banner--type-2 .mini-banner__block:nth-child(1) {
    display: none;
  }
}
.mini-banner--type-2 .mini-banner__block:nth-child(2) {
  align-items: flex-start;
  text-align: left;
}
@media (max-width: 768px) {
  .mini-banner--type-2 .mini-banner__block:nth-child(2) {
    display: block;
  }
}
.mini-banner--type-2 .mini-banner__block__big {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .mini-banner--type-2 .mini-banner__block__big {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.mini-banner--type-2 .mini-banner__block__small {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .mini-banner--type-2 .mini-banner__block__small {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .mini-banner--type-2 .mini-banner__block__small {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}
.mini-banner--type-2 .mini-banner__inner {
  padding-top: 16px;
  padding-bottom: 16px;
  height: auto;
  grid-gap: 24px;
  grid-template-columns: auto 1fr min-content;
}
@media (max-width: 768px) {
  .mini-banner--type-2 .mini-banner__inner {
    grid-template-columns: 1fr;
  }
}
.mini-banner--type-3 .mini-banner__inner {
  grid-template-columns: auto minmax(0, 540px) auto;
}
@media (max-width: 1024px) {
  .mini-banner--type-3 .mini-banner__inner {
    grid-template-columns: 1fr auto;
  }
}
@media (max-width: 768px) {
  .mini-banner--type-3 .mini-banner__inner {
    grid-template-columns: 1fr;
  }
}
.mini-banner__inner {
  height: 72px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  grid-gap: 32px;
}
@media (max-width: 768px) {
  .mini-banner__inner {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    height: 56px;
  }
}
.mini-banner__timer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 4px;
  color: #000;
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 768px) {
  .mini-banner__timer {
    justify-content: flex-start;
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.mini-banner__timer__block {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  width: 40px;
  height: 40px;
}
.mini-banner__block {
  display: flex;
  align-items: center;
}
.mini-banner__block:nth-child(1) {
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 768px) {
  .mini-banner__block:nth-child(1) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.mini-banner__block:nth-child(2) {
  text-align: center;
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
}
.mini-banner__block:nth-child(2) img {
  height: 100%;
  width: auto;
}
@media (max-width: 768px) {
  .mini-banner__block:nth-child(2) {
    display: none;
  }
}
.mini-banner__block:nth-child(3) {
  text-align: right;
  justify-content: flex-end;
}
.license-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 130%;
  overflow: hidden;
}
.license-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

.learning-card__img {
  width: 100%;
}
.learning-card__img img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

.header__menu-popup {
  height: calc(100vh - var(--header-height));
  overflow: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  width: 100vw;
  z-index: 2;
  transition: all 0.2s ease;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.2509803922);
}
.header__menu-popup:not(.show) {
  width: calc(100 * var(--vw));
  opacity: 0;
  pointer-events: none;
}
.header__menu-popup__inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-gap: 56px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.header__menu-popup__inner::before {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  height: 1px;
  background: #004de6;
  width: 100%;
}
.header__menu-popup__inner__col {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
}
.header__menu-popup__inner__block {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
.header__menu-popup__inner__block__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}
.header__menu-popup__inner__block__items {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.header__menu-popup__inner__block__item {
  color: #4B5563;
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
}
.header__menu-popup__inner__block__item a:hover {
  color: #004de6;
}
.header__menu-popup__inner__block__buttons {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.header__menu-popup__contacts {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}
.header__menu-popup__contacts__item {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  white-space: nowrap;
}
.header__menu-popup__contacts__item .icon {
  --size: 16px;
  --color:#004de6;
}

.catalog {
  display: flex;
  flex-direction: column;
  grid-gap: 48px;
}
@media (max-width: 1440px) {
  .catalog {
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .catalog {
    grid-gap: 16px;
  }
}
.catalog__head {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
@media (max-width: 768px) {
  .catalog__head {
    flex-direction: column;
    align-items: stretch;
  }
}
@media (min-width: 768px) {
  .catalog__search {
    margin-left: auto;
  }
}
.catalog__search .custom-input {
  width: 342px;
}
@media (max-width: 768px) {
  .catalog__search .custom-input {
    width: 100%;
  }
}
.catalog__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 1440px) {
  .catalog__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .catalog__title {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .catalog__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
.catalog__body {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 24px;
}
@media (max-width: 1260px) {
  .catalog__body {
    grid-template-columns: 1fr;
  }
}
.catalog__footer {
  display: flex;
  justify-content: center;
}
@media (min-width: 1260px) {
  .catalog__footer {
    grid-column: 2;
  }
}
.catalog__filters__content {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .catalog__filters__content {
    padding-top: 16px;
  }
}
.catalog__filters__title {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.catalog__filters__toggler {
  display: flex;
  grid-gap: 12px;
  align-items: center;
  padding: 8px 16px;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  border-top: 1px solid #D1D5DB;
  border-bottom: 1px solid #D1D5DB;
}
.catalog__filters__toggler span {
  flex-grow: 1;
}
.catalog__filters__toggler .icon {
  --size: 16px;
}
.catalog__filters__toggler::before {
  content: "";
  --size: 24px;
}
.catalog__filters__item__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 12px;
  padding-bottom: 8px;
}
.catalog__filters__item__content {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  padding-top: 4px;
  overflow: auto;
  max-height: 424px;
}
.catalog__filters__item__content__item .custom-input {
  width: 100%;
}
.mobile_menu_popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  background: rgba(75, 85, 99, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  opacity: 1;
}
.mobile_menu_popup:not(.show) {
  opacity: 0;
  pointer-events: none;
}
.mobile_menu_popup__inner {
  background: #FFFFFF;
  height: 100%;
  overflow: auto;
  width: 100%;
  max-width: 333px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  z-index: 10000;
}
.mobile_menu_popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}
.mobile_menu_popup__header__title {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
}
.mobile_menu_popup__subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile_menu_popup__call a {
  padding: 0;
}
.mobile_menu_popup__nav__item {
  border-bottom: 1px solid #D1D5DB;
  padding: 8px 0;
}
.mobile_menu_popup__nav__item .button {
  align-self: center;
}
.mobile_menu_popup__nav__item__title {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  display: block;
}
.mobile_menu_popup__nav__item__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mobile_menu_popup__nav__item__content__link {
  font-family: Circe;
  font-size: 14px;
  font-weight: 400;
}
.mobile_menu_popup__nav__item .spoiler__toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile_menu_popup__nav__item .spoiler__content .mobile_menu_popup__nav__item__content {
  padding-top: 16px;
}
.mobile_menu_popup__nav__item .spoiler.is-active .spoiler__toggler {
  color: #004de6;
}
.mobile_menu_popup__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.mobile_menu_popup__footer__link {
  text-decoration: underline;
  font-family: Circe;
  font-size: 12px;
  font-weight: 400;
}
.mobile_menu_popup__footer__link:hover {
  text-decoration: none;
}

.qna-spoilers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
}
@media (max-width: 1260px) {
  .qna-spoilers {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
.qna-spoilers--wide {
  grid-template-columns: 1fr;
  grid-gap: 0;
}
.qna-spoilers--wide .qna-spoilers__item:last-child {
  border-bottom: 1px solid #6ea5f7;
}
.qna-spoilers--wide .qna-spoilers__item__title {
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 1440px) {
  .qna-spoilers--wide .qna-spoilers__item__title {
    font-family: Circe;
    font-size: 20px;
    font-weight: 700;
  }
}
.qna-spoilers--wide .spoiler__content {
  color: #6B7280;
}
.qna-spoilers__item {
  padding: 24px 0;
  border-top: 1px solid #6ea5f7;
}
.qna-spoilers__item .spoiler.is-active .spoiler__toggler .icon {
  transform: rotate(45deg);
}
.qna-spoilers__item__title {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
}
.qna-spoilers__item__content {
  padding-top: 16px;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.qna-spoilers__item p,
.qna-spoilers__item ul {
  margin: 8px 0;
}
.qna-spoilers__item p:first-child,
.qna-spoilers__item ul:first-child {
  margin-top: 0;
}
.qna-spoilers__item p:last-child,
.qna-spoilers__item ul:last-child {
  margin-bottom: 0;
}
.qna-spoilers__item ul {
  list-style-position: inside;
}
.qna-spoilers__item ul li {
  display: flex;
}
.qna-spoilers__item ul li::before {
  content: "•";
  text-align: center;
  width: 24px;
}

.qna-swiper {
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.qna-swiper .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}

.qna-pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0 48px;
  overflow-x: scroll;
}
.qna-pagination::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1024px) {
  .qna-pagination {
    padding: 0 16px;
  }
}
@media (min-width: 1024px) {
  .qna-pagination {
    flex-wrap: wrap;
    max-width: 1200px;
    justify-content: center;
  }
}
.qna-pagination--item {
  position: relative;
  padding: 8px 16px 4px 16px;
  height: 40px;
  cursor: pointer;
}
.qna-pagination--item a {
  cursor: pointer;
}
.qna-pagination--item.is-active a button {
  font-weight: 700;
  color: #4C4C4C;
}
.qna-pagination--item.is-active a button::after {
  content: "";
  background-color: #004CE4;
}
.qna-pagination a button {
  width: 100%;
  user-select: none;
  white-space: nowrap;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #9CA3AF;
  transition: color 0.2s ease;
  cursor: pointer;
}
@media (max-width: 1259px) {
  .qna-pagination a button {
    font-size: 16px;
  }
}
.qna-pagination a button::after {
  content: "";
  width: calc(100% - 8px);
  height: 3px !important;
  display: block;
  position: absolute;
  left: 4px;
  bottom: 0;
  background-color: #E5E7EB;
  border-radius: 2px;
  transition: background-color 0.2s ease;
}

.course-cards {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .course-cards {
    max-width: 870px;
  }
}
@media (max-width: 768px) {
  .course-cards {
    grid-gap: 16px;
  }
}

.course-card {
  padding: 32px;
  background: #FFFFFF;
  display: grid;
  grid-template-columns: 144px 1fr auto;
  grid-template-rows: min-content 1fr;
  align-items: flex-start;
  grid-gap: 16px 24px;
}
.course-card:not(:has(.course-card__img)) {
  grid-template-columns: 1fr auto;
}
.course-card:not(:has(.course-card__img)) .course-card__name {
  grid-column: 1;
}
.course-card:not(:has(.course-card__img)) .course-card__badges {
  grid-column: 1;
}
.course-card:not(:has(.course-card__img)) .course-card__info {
  grid-column: 2;
}
@media (max-width: 1440px) {
  .course-card {
    padding: 20px 24px;
    grid-template-columns: 92px 1fr auto;
  }
}
@media (max-width: 768px) {
  .course-card {
    grid-template-columns: 1fr auto;
    padding: 24px 16px;
    grid-gap: 16px;
  }
}
.course-card__img {
  align-self: center;
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  grid-column: 1;
  grid-row: 1/span 2;
}
.course-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 768px) {
  .course-card__img {
    display: none;
  }
}
.course-card__name {
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
  grid-column: 2;
  transition: all 0.2s ease;
}
.course-card__name:hover {
  color: #004de6;
}
@media (max-width: 1440px) {
  .course-card__name {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .course-card__name {
    grid-column: 1;
    font-family: Circe;
    font-size: 12px;
    font-weight: 700;
  }
}
.course-card__badges {
  grid-column: 2;
  display: flex;
  grid-gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .course-card__badges {
    grid-column: 1;
  }
}
.course-card__info {
  align-self: flex-start;
  grid-column: 3;
  grid-row: 1/span 2;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1440px) {
  .course-card__info {
    margin-top: -12px;
  }
}
@media (max-width: 768px) {
  .course-card__info {
    grid-column: 2;
  }
}
.course-card__info__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 4px;
}
.course-card__info__buttons:only-child {
  height: 100%;
  justify-content: center;
}
.course-card__iconed-text {
  display: flex;
  align-items: flex-end;
  grid-gap: 8px;
}
.course-card__iconed-text .icon {
  --color: #9CA3AF;
}
.course-card__iconed-text__texts {
  display: flex;
  flex-direction: column-reverse;
}
.course-card__iconed-text__texts span:nth-child(1) {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .course-card__iconed-text__texts span:nth-child(1) {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .course-card__iconed-text__texts span:nth-child(1) {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.course-card__iconed-text__texts span:nth-child(n+2) {
  text-align: right;
  color: #9CA3AF;
  text-decoration: line-through;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .course-card__iconed-text__texts span:nth-child(n+2) {
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
  }
}

.course-card:nth-child(n+6) {
  display: none;
}

.course-card.is-visible {
  display: grid;
}

.payment {
  display: grid;
  grid-template-columns: 7fr 10fr;
}
@media (max-width: 768px) {
  .payment {
    grid-template-columns: 1fr;
  }
}
.payment__left .img-banner {
  height: 100%;
}
@media (max-width: 1260px) {
  .payment__left .img-banner {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
}
@media (max-width: 768px) {
  .payment__left .img-banner {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .payment__left .img-banner {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}
.payment__form-footer {
  display: flex;
  justify-content: space-between;
  font-family: Circe;
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .payment__form-footer {
    justify-content: flex-end;
    grid-gap: 8px;
  }
}
.payment__right {
  background: #e8f2fc;
  border-radius: 0px 12px 12px 0px;
  padding: 48px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .payment__right {
    padding: 24px;
  }
}
@media (max-width: 1260px) {
  .payment__right .form-group {
    flex-direction: column;
  }
}
.payment__paysystems {
  display: flex;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.payment__paysystems img {
  height: 54px;
  width: auto;
}
@media (max-width: 768px) {
  .payment__paysystems img {
    height: 32px;
  }
}

.text-spoiler.spoiler {
  --initial-height: 15em;
}
.text-spoiler.spoiler:not(.is-active) .spoiler__toggler {
  position: relative;
  z-index: 1;
  box-shadow: 0px -20px 20px 20px #fff;
  background: #fff;
}
.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 68px 24px;
}
@media (max-width: 768px) {
  .news-grid {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
.news-grid__card {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}
@media (max-width: 768px) {
  .news-grid__card {
    margin: 16px 0;
  }
}
.news-grid__card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 78.4482758621%;
  overflow: hidden;
  border-radius: 4px;
}
.news-grid__card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.news-grid__card__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  transition: all 0.2s ease;
}
.news-grid__card__title:hover {
  color: #004de6;
}
@media (max-width: 1440px) {
  .news-grid__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1260px) {
  .news-grid__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
}
.news-grid__card__date {
  color: #9CA3AF;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .news-grid__card__date {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.news-grid__card__badge {
  position: absolute;
  top: 26px;
  left: 26px;
}
@media (max-width: 768px) {
  .news-grid__card__badge {
    top: 13px;
    left: 13px;
  }
}
.news-header {
  display: grid;
  grid-template-columns: 830fr 581fr;
  grid-template-rows: min-content 1fr;
  align-items: flex-start;
  grid-gap: 24px;
}
@media (max-width: 768px) {
  .news-header {
    grid-template-columns: 1fr;
  }
}
.news-header__card {
  position: relative;
}
.news-header__card:nth-of-type(1) {
  grid-row: 1/-1;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px 100px;
}
@media (max-width: 768px) {
  .news-header__card:nth-of-type(1) {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .news-header__card:nth-of-type(1) .news-header__card__img {
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 68.221574344%;
    overflow: hidden;
  }
  .news-header__card:nth-of-type(1) .news-header__card__img > :first-child {
    border-radius: inherit;
    mask-image: radial-gradient(#000, #000);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
}
@media (min-width: 768px) {
  .news-header__card:nth-of-type(1) .news-header__card__img {
    grid-column: 1/-1;
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 48.1927710843%;
    overflow: hidden;
  }
  .news-header__card:nth-of-type(1) .news-header__card__img > :first-child {
    border-radius: inherit;
    mask-image: radial-gradient(#000, #000);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
  }
}
.news-header__card:nth-of-type(1) .news-header__card__badge {
  position: absolute;
  top: 26px;
  left: 26px;
}
@media (max-width: 768px) {
  .news-header__card:nth-of-type(1) .news-header__card__badge {
    top: 13px;
    left: 13px;
  }
}
.news-header__card:nth-of-type(1) .news-header__card__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
@media (max-width: 1440px) {
  .news-header__card:nth-of-type(1) .news-header__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .news-header__card:nth-of-type(1) .news-header__card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
.news-header__card:nth-of-type(1) .news-header__card__date {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
}
@media (max-width: 1260px) {
  .news-header__card:nth-of-type(1) .news-header__card__date {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .news-header__card:nth-of-type(1) .news-header__card__date {
    display: none;
  }
}
.news-header__card:nth-of-type(n + 2) {
  grid-column: 2;
  display: grid;
  grid-template-columns: 264fr 279fr;
  grid-template-rows: min-content 1fr min-content;
  grid-gap: 12px 38px;
}
@media (max-width: 768px) {
  .news-header__card:nth-of-type(n + 2) {
    display: none;
  }
}
@media (max-width: 1440px) and (min-width: 768px) {
  .news-header__card:nth-of-type(n + 2) {
    grid-gap: 12px 24px;
  }
}
.news-header__card:nth-of-type(n + 2) .news-header__card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 71.2121212121%;
  overflow: hidden;
  grid-column: 1;
  grid-row: 1/-1;
}
.news-header__card:nth-of-type(n + 2) .news-header__card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.news-header__card:nth-of-type(n + 2) .news-header__card__badge {
  grid-column: 2;
}
.news-header__card:nth-of-type(n + 2) .news-header__card__title {
  grid-column: 2;
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 1440px) and (min-width: 768px) {
  .news-header__card:nth-of-type(n + 2) .news-header__card__title {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
  }
}
.news-header__card:nth-of-type(n + 2) .news-header__card__date {
  grid-column: 2;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
}
.news-header__card__title {
  transition: all 0.2s ease;
}
.news-header__card__title:hover {
  color: #004de6;
}
.news-header__card__img {
  border-radius: 4px;
}
.news-header__card__date {
  color: #9CA3AF;
}

.letter-slide-card {
  padding: 8px;
  background: #FFFFFF;
  display: block;
}
.letter-slide-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 125%;
  overflow: hidden;
}
.letter-slide-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.letter-slide-card__img > img:first-child {
  object-fit: contain;
}

.coursegroup-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
}
@media (max-width: 768px) {
  .coursegroup-cards {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}

.coursegroup-card {
  padding: 32px;
  border-radius: 4px;
  grid-template-rows: min-content min-content 1fr;
  background: #F3F4F6;
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: 1fr 144px;
}
.coursegroup-card:not(:has(.coursegroup-card__img)) {
  grid-template-columns: 1fr;
}
.coursegroup-card:not(:has(.coursegroup-card__img)) .coursegroup-card__img {
  grid-column: 1;
}
@media (max-width: 768px) {
  .coursegroup-card {
    grid-template-columns: 1fr;
    padding: 24px;
  }
}
.coursegroup-card__title {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  grid-column: 1;
}
@media (max-width: 1440px) {
  .coursegroup-card__title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
  }
}
.coursegroup-card__text {
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  grid-column: 1;
}
@media (max-width: 1440px) {
  .coursegroup-card__text {
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
  }
}
.coursegroup-card__button {
  grid-column: 1;
  align-items: flex-end;
  margin-top: auto;
}
.coursegroup-card__img {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  grid-column: 2;
  grid-row: 1/-1;
}
.coursegroup-card__img > :first-child {
  border-radius: inherit;
  mask-image: radial-gradient(#000, #000);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 768px) {
  .coursegroup-card__img {
    display: none;
  }
}

.spoiler {
  --initial-height: 0px;
}
.spoiler:not(.spoiler--hard) .spoiler__content {
  transition: all 0.2s ease;
}
.spoiler .spoiler__content {
  overflow: hidden;
}
.spoiler .spoiler__content:not(.is-active) {
  max-height: var(--initial-height);
}
.spoiler .spoiler__toggler.is-active + .spoiler__content {
  max-height: initial;
}
@media (min-width: 768px) {
  .spoiler--before-mobile > .spoiler__content {
    overflow: visible !important;
    max-height: initial !important;
  }
}
@media (min-width: 1260px) {
  .spoiler--before-tablet > .spoiler__content {
    overflow: visible !important;
    max-height: initial !important;
  }
}
@media (min-width: 1260px) {
  .spoiler--only-tablet > .spoiler__content {
    overflow: visible !important;
    max-height: initial !important;
  }
}
@media (max-width: 768px) {
  .spoiler--only-tablet > .spoiler__content {
    overflow: visible !important;
    max-height: initial !important;
  }
}
.spoiler .spoiler__toggler {
  cursor: pointer;
  user-select: none;
}
.spoiler .spoiler__flipper {
  transition: all 0.2s ease;
}

.spoiler.is-active .spoiler__flipper, .spoiler .spoiler.is-active .spoiler__flipper, .spoiler .spoiler .spoiler.is-active .spoiler__flipper,
.spoiler__toggler.is-active .spoiler__flipper,
.spoiler .spoiler__toggler.is-active .spoiler__flipper,
.spoiler .spoiler .spoiler__toggler.is-active .spoiler__flipper,
.spoiler__toggler .spoiler.is-active .spoiler__flipper,
.spoiler .spoiler__toggler .spoiler.is-active .spoiler__flipper,
.spoiler__toggler .spoiler__toggler.is-active .spoiler__flipper,
.spoiler .spoiler__toggler .spoiler__toggler.is-active .spoiler__flipper,
.spoiler__toggler .spoiler .spoiler.is-active .spoiler__flipper,
.spoiler__toggler .spoiler .spoiler__toggler.is-active .spoiler__flipper,
.spoiler__toggler .spoiler__toggler .spoiler.is-active .spoiler__flipper,
.spoiler__toggler .spoiler__toggler .spoiler__toggler.is-active .spoiler__flipper {
  transform: scaleY(-1);
}
.spoiler.is-active .spoiler__initial, .spoiler .spoiler.is-active .spoiler__initial, .spoiler .spoiler .spoiler.is-active .spoiler__initial,
.spoiler__toggler.is-active .spoiler__initial,
.spoiler .spoiler__toggler.is-active .spoiler__initial,
.spoiler .spoiler .spoiler__toggler.is-active .spoiler__initial,
.spoiler__toggler .spoiler.is-active .spoiler__initial,
.spoiler .spoiler__toggler .spoiler.is-active .spoiler__initial,
.spoiler__toggler .spoiler__toggler.is-active .spoiler__initial,
.spoiler .spoiler__toggler .spoiler__toggler.is-active .spoiler__initial,
.spoiler__toggler .spoiler .spoiler.is-active .spoiler__initial,
.spoiler__toggler .spoiler .spoiler__toggler.is-active .spoiler__initial,
.spoiler__toggler .spoiler__toggler .spoiler.is-active .spoiler__initial,
.spoiler__toggler .spoiler__toggler .spoiler__toggler.is-active .spoiler__initial {
  display: none;
}
.spoiler.is-active .spoiler__final, .spoiler .spoiler.is-active .spoiler__final, .spoiler .spoiler .spoiler.is-active .spoiler__final,
.spoiler__toggler.is-active .spoiler__final,
.spoiler .spoiler__toggler.is-active .spoiler__final,
.spoiler .spoiler .spoiler__toggler.is-active .spoiler__final,
.spoiler__toggler .spoiler.is-active .spoiler__final,
.spoiler .spoiler__toggler .spoiler.is-active .spoiler__final,
.spoiler__toggler .spoiler__toggler.is-active .spoiler__final,
.spoiler .spoiler__toggler .spoiler__toggler.is-active .spoiler__final,
.spoiler__toggler .spoiler .spoiler.is-active .spoiler__final,
.spoiler__toggler .spoiler .spoiler__toggler.is-active .spoiler__final,
.spoiler__toggler .spoiler__toggler .spoiler.is-active .spoiler__final,
.spoiler__toggler .spoiler__toggler .spoiler__toggler.is-active .spoiler__final {
  display: block;
}

.spoiler:not(.is-active) .spoiler__initial, .spoiler .spoiler:not(.is-active) .spoiler__initial, .spoiler .spoiler .spoiler:not(.is-active) .spoiler__initial,
.spoiler__toggler:not(.is-active) .spoiler__initial,
.spoiler .spoiler__toggler:not(.is-active) .spoiler__initial,
.spoiler .spoiler .spoiler__toggler:not(.is-active) .spoiler__initial,
.spoiler__toggler .spoiler:not(.is-active) .spoiler__initial,
.spoiler .spoiler__toggler .spoiler:not(.is-active) .spoiler__initial,
.spoiler__toggler .spoiler__toggler:not(.is-active) .spoiler__initial,
.spoiler .spoiler__toggler .spoiler__toggler:not(.is-active) .spoiler__initial,
.spoiler__toggler .spoiler .spoiler:not(.is-active) .spoiler__initial,
.spoiler__toggler .spoiler .spoiler__toggler:not(.is-active) .spoiler__initial,
.spoiler__toggler .spoiler__toggler .spoiler:not(.is-active) .spoiler__initial,
.spoiler__toggler .spoiler__toggler .spoiler__toggler:not(.is-active) .spoiler__initial {
  display: block;
}
.spoiler:not(.is-active) .spoiler__final, .spoiler .spoiler:not(.is-active) .spoiler__final, .spoiler .spoiler .spoiler:not(.is-active) .spoiler__final,
.spoiler__toggler:not(.is-active) .spoiler__final,
.spoiler .spoiler__toggler:not(.is-active) .spoiler__final,
.spoiler .spoiler .spoiler__toggler:not(.is-active) .spoiler__final,
.spoiler__toggler .spoiler:not(.is-active) .spoiler__final,
.spoiler .spoiler__toggler .spoiler:not(.is-active) .spoiler__final,
.spoiler__toggler .spoiler__toggler:not(.is-active) .spoiler__final,
.spoiler .spoiler__toggler .spoiler__toggler:not(.is-active) .spoiler__final,
.spoiler__toggler .spoiler .spoiler:not(.is-active) .spoiler__final,
.spoiler__toggler .spoiler .spoiler__toggler:not(.is-active) .spoiler__final,
.spoiler__toggler .spoiler__toggler .spoiler:not(.is-active) .spoiler__final,
.spoiler__toggler .spoiler__toggler .spoiler__toggler:not(.is-active) .spoiler__final {
  display: none;
}

.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.px-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
@media (max-width: 768px) {
  .p-sm-0 {
    padding: 0px;
  }
  .py-sm-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pl-sm-0 {
    padding-left: 0px;
  }
  .pb-sm-0 {
    padding-bottom: 0px;
  }
  .pr-sm-0 {
    padding-right: 0px;
  }
  .pt-sm-0 {
    padding-top: 0px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-0 {
    margin: 0px;
  }
  .mt-sm-0 {
    margin-top: 0px;
  }
  .mr-sm-0 {
    margin-right: 0px;
  }
  .mb-sm-0 {
    margin-bottom: 0px;
  }
  .ml-sm-0 {
    margin-left: 0px;
  }
  .my-sm-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.p-1 {
  padding: 8px;
}
.pt-1 {
  padding-top: 8px;
}
.pr-1 {
  padding-right: 8px;
}
.pb-1 {
  padding-bottom: 8px;
}
.pl-1 {
  padding-left: 8px;
}
.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.px-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}
@media (max-width: 768px) {
  .p-sm-1 {
    padding: 8px;
  }
  .py-sm-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pl-sm-1 {
    padding-left: 8px;
  }
  .pb-sm-1 {
    padding-bottom: 8px;
  }
  .pr-sm-1 {
    padding-right: 8px;
  }
  .pt-sm-1 {
    padding-top: 8px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-1 {
  margin: 8px;
}
.mt-1 {
  margin-top: 8px;
}
.mr-1 {
  margin-right: 8px;
}
.mb-1 {
  margin-bottom: 8px;
}
.ml-1 {
  margin-left: 8px;
}
.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-1 {
    margin: 8px;
  }
  .mt-sm-1 {
    margin-top: 8px;
  }
  .mr-sm-1 {
    margin-right: 8px;
  }
  .mb-sm-1 {
    margin-bottom: 8px;
  }
  .ml-sm-1 {
    margin-left: 8px;
  }
  .my-sm-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.p-2 {
  padding: 16px;
}
.pt-2 {
  padding-top: 16px;
}
.pr-2 {
  padding-right: 16px;
}
.pb-2 {
  padding-bottom: 16px;
}
.pl-2 {
  padding-left: 16px;
}
.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.px-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .p-sm-2 {
    padding: 16px;
  }
  .py-sm-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .pl-sm-2 {
    padding-left: 16px;
  }
  .pb-sm-2 {
    padding-bottom: 16px;
  }
  .pr-sm-2 {
    padding-right: 16px;
  }
  .pt-sm-2 {
    padding-top: 16px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-2 {
  margin: 16px;
}
.mt-2 {
  margin-top: 16px;
}
.mr-2 {
  margin-right: 16px;
}
.mb-2 {
  margin-bottom: 16px;
}
.ml-2 {
  margin-left: 16px;
}
.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-2 {
    margin: 16px;
  }
  .mt-sm-2 {
    margin-top: 16px;
  }
  .mr-sm-2 {
    margin-right: 16px;
  }
  .mb-sm-2 {
    margin-bottom: 16px;
  }
  .ml-sm-2 {
    margin-left: 16px;
  }
  .my-sm-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.p-3 {
  padding: 24px;
}
.pt-3 {
  padding-top: 24px;
}
.pr-3 {
  padding-right: 24px;
}
.pb-3 {
  padding-bottom: 24px;
}
.pl-3 {
  padding-left: 24px;
}
.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.px-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (max-width: 768px) {
  .p-sm-3 {
    padding: 24px;
  }
  .py-sm-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .pl-sm-3 {
    padding-left: 24px;
  }
  .pb-sm-3 {
    padding-bottom: 24px;
  }
  .pr-sm-3 {
    padding-right: 24px;
  }
  .pt-sm-3 {
    padding-top: 24px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-3 {
  margin: 24px;
}
.mt-3 {
  margin-top: 24px;
}
.mr-3 {
  margin-right: 24px;
}
.mb-3 {
  margin-bottom: 24px;
}
.ml-3 {
  margin-left: 24px;
}
.my-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-3 {
    margin: 24px;
  }
  .mt-sm-3 {
    margin-top: 24px;
  }
  .mr-sm-3 {
    margin-right: 24px;
  }
  .mb-sm-3 {
    margin-bottom: 24px;
  }
  .ml-sm-3 {
    margin-left: 24px;
  }
  .my-sm-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.p-4 {
  padding: 32px;
}
.pt-4 {
  padding-top: 32px;
}
.pr-4 {
  padding-right: 32px;
}
.pb-4 {
  padding-bottom: 32px;
}
.pl-4 {
  padding-left: 32px;
}
.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.px-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (max-width: 768px) {
  .p-sm-4 {
    padding: 32px;
  }
  .py-sm-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .pl-sm-4 {
    padding-left: 32px;
  }
  .pb-sm-4 {
    padding-bottom: 32px;
  }
  .pr-sm-4 {
    padding-right: 32px;
  }
  .pt-sm-4 {
    padding-top: 32px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-4 {
  margin: 32px;
}
.mt-4 {
  margin-top: 32px;
}
.mr-4 {
  margin-right: 32px;
}
.mb-4 {
  margin-bottom: 32px;
}
.ml-4 {
  margin-left: 32px;
}
.my-4 {
  margin-top: 32px;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-4 {
    margin: 32px;
  }
  .mt-sm-4 {
    margin-top: 32px;
  }
  .mr-sm-4 {
    margin-right: 32px;
  }
  .mb-sm-4 {
    margin-bottom: 32px;
  }
  .ml-sm-4 {
    margin-left: 32px;
  }
  .my-sm-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.p-5 {
  padding: 40px;
}
.pt-5 {
  padding-top: 40px;
}
.pr-5 {
  padding-right: 40px;
}
.pb-5 {
  padding-bottom: 40px;
}
.pl-5 {
  padding-left: 40px;
}
.py-5 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.px-5 {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 768px) {
  .p-sm-5 {
    padding: 40px;
  }
  .py-sm-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pl-sm-5 {
    padding-left: 40px;
  }
  .pb-sm-5 {
    padding-bottom: 40px;
  }
  .pr-sm-5 {
    padding-right: 40px;
  }
  .pt-sm-5 {
    padding-top: 40px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-5 {
  margin: 40px;
}
.mt-5 {
  margin-top: 40px;
}
.mr-5 {
  margin-right: 40px;
}
.mb-5 {
  margin-bottom: 40px;
}
.ml-5 {
  margin-left: 40px;
}
.my-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-5 {
    margin: 40px;
  }
  .mt-sm-5 {
    margin-top: 40px;
  }
  .mr-sm-5 {
    margin-right: 40px;
  }
  .mb-sm-5 {
    margin-bottom: 40px;
  }
  .ml-sm-5 {
    margin-left: 40px;
  }
  .my-sm-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.p-6 {
  padding: 48px;
}
.pt-6 {
  padding-top: 48px;
}
.pr-6 {
  padding-right: 48px;
}
.pb-6 {
  padding-bottom: 48px;
}
.pl-6 {
  padding-left: 48px;
}
.py-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.px-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}
@media (max-width: 768px) {
  .p-sm-6 {
    padding: 48px;
  }
  .py-sm-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pl-sm-6 {
    padding-left: 48px;
  }
  .pb-sm-6 {
    padding-bottom: 48px;
  }
  .pr-sm-6 {
    padding-right: 48px;
  }
  .pt-sm-6 {
    padding-top: 48px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-6 {
  margin: 48px;
}
.mt-6 {
  margin-top: 48px;
}
.mr-6 {
  margin-right: 48px;
}
.mb-6 {
  margin-bottom: 48px;
}
.ml-6 {
  margin-left: 48px;
}
.my-6 {
  margin-top: 48px;
  margin-bottom: 48px;
}
@media (max-width: 768px) {
  .my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-6 {
    margin: 48px;
  }
  .mt-sm-6 {
    margin-top: 48px;
  }
  .mr-sm-6 {
    margin-right: 48px;
  }
  .mb-sm-6 {
    margin-bottom: 48px;
  }
  .ml-sm-6 {
    margin-left: 48px;
  }
  .my-sm-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

.p-7 {
  padding: 56px;
}
.pt-7 {
  padding-top: 56px;
}
.pr-7 {
  padding-right: 56px;
}
.pb-7 {
  padding-bottom: 56px;
}
.pl-7 {
  padding-left: 56px;
}
.py-7 {
  padding-top: 56px;
  padding-bottom: 56px;
}
.px-7 {
  padding-top: 56px;
  padding-bottom: 56px;
}
@media (max-width: 768px) {
  .p-sm-7 {
    padding: 56px;
  }
  .py-sm-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .pl-sm-7 {
    padding-left: 56px;
  }
  .pb-sm-7 {
    padding-bottom: 56px;
  }
  .pr-sm-7 {
    padding-right: 56px;
  }
  .pt-sm-7 {
    padding-top: 56px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-7 {
  margin: 56px;
}
.mt-7 {
  margin-top: 56px;
}
.mr-7 {
  margin-right: 56px;
}
.mb-7 {
  margin-bottom: 56px;
}
.ml-7 {
  margin-left: 56px;
}
.my-7 {
  margin-top: 56px;
  margin-bottom: 56px;
}
@media (max-width: 768px) {
  .my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-7 {
    margin: 56px;
  }
  .mt-sm-7 {
    margin-top: 56px;
  }
  .mr-sm-7 {
    margin-right: 56px;
  }
  .mb-sm-7 {
    margin-bottom: 56px;
  }
  .ml-sm-7 {
    margin-left: 56px;
  }
  .my-sm-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}

.p-8 {
  padding: 64px;
}
.pt-8 {
  padding-top: 64px;
}
.pr-8 {
  padding-right: 64px;
}
.pb-8 {
  padding-bottom: 64px;
}
.pl-8 {
  padding-left: 64px;
}
.py-8 {
  padding-top: 64px;
  padding-bottom: 64px;
}
.px-8 {
  padding-top: 64px;
  padding-bottom: 64px;
}
@media (max-width: 768px) {
  .p-sm-8 {
    padding: 64px;
  }
  .py-sm-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pl-sm-8 {
    padding-left: 64px;
  }
  .pb-sm-8 {
    padding-bottom: 64px;
  }
  .pr-sm-8 {
    padding-right: 64px;
  }
  .pt-sm-8 {
    padding-top: 64px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-8 {
  margin: 64px;
}
.mt-8 {
  margin-top: 64px;
}
.mr-8 {
  margin-right: 64px;
}
.mb-8 {
  margin-bottom: 64px;
}
.ml-8 {
  margin-left: 64px;
}
.my-8 {
  margin-top: 64px;
  margin-bottom: 64px;
}
@media (max-width: 768px) {
  .my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-8 {
    margin: 64px;
  }
  .mt-sm-8 {
    margin-top: 64px;
  }
  .mr-sm-8 {
    margin-right: 64px;
  }
  .mb-sm-8 {
    margin-bottom: 64px;
  }
  .ml-sm-8 {
    margin-left: 64px;
  }
  .my-sm-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

.p-9 {
  padding: 72px;
}
.pt-9 {
  padding-top: 72px;
}
.pr-9 {
  padding-right: 72px;
}
.pb-9 {
  padding-bottom: 72px;
}
.pl-9 {
  padding-left: 72px;
}
.py-9 {
  padding-top: 72px;
  padding-bottom: 72px;
}
.px-9 {
  padding-top: 72px;
  padding-bottom: 72px;
}
@media (max-width: 768px) {
  .p-sm-9 {
    padding: 72px;
  }
  .py-sm-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .pl-sm-9 {
    padding-left: 72px;
  }
  .pb-sm-9 {
    padding-bottom: 72px;
  }
  .pr-sm-9 {
    padding-right: 72px;
  }
  .pt-sm-9 {
    padding-top: 72px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-9 {
  margin: 72px;
}
.mt-9 {
  margin-top: 72px;
}
.mr-9 {
  margin-right: 72px;
}
.mb-9 {
  margin-bottom: 72px;
}
.ml-9 {
  margin-left: 72px;
}
.my-9 {
  margin-top: 72px;
  margin-bottom: 72px;
}
@media (max-width: 768px) {
  .my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-9 {
    margin: 72px;
  }
  .mt-sm-9 {
    margin-top: 72px;
  }
  .mr-sm-9 {
    margin-right: 72px;
  }
  .mb-sm-9 {
    margin-bottom: 72px;
  }
  .ml-sm-9 {
    margin-left: 72px;
  }
  .my-sm-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

.p-10 {
  padding: 80px;
}
.pt-10 {
  padding-top: 80px;
}
.pr-10 {
  padding-right: 80px;
}
.pb-10 {
  padding-bottom: 80px;
}
.pl-10 {
  padding-left: 80px;
}
.py-10 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.px-10 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  .p-sm-10 {
    padding: 80px;
  }
  .py-sm-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pl-sm-10 {
    padding-left: 80px;
  }
  .pb-sm-10 {
    padding-bottom: 80px;
  }
  .pr-sm-10 {
    padding-right: 80px;
  }
  .pt-sm-10 {
    padding-top: 80px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-10 {
  margin: 80px;
}
.mt-10 {
  margin-top: 80px;
}
.mr-10 {
  margin-right: 80px;
}
.mb-10 {
  margin-bottom: 80px;
}
.ml-10 {
  margin-left: 80px;
}
.my-10 {
  margin-top: 80px;
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-10 {
    margin: 80px;
  }
  .mt-sm-10 {
    margin-top: 80px;
  }
  .mr-sm-10 {
    margin-right: 80px;
  }
  .mb-sm-10 {
    margin-bottom: 80px;
  }
  .ml-sm-10 {
    margin-left: 80px;
  }
  .my-sm-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.p-11 {
  padding: 88px;
}
.pt-11 {
  padding-top: 88px;
}
.pr-11 {
  padding-right: 88px;
}
.pb-11 {
  padding-bottom: 88px;
}
.pl-11 {
  padding-left: 88px;
}
.py-11 {
  padding-top: 88px;
  padding-bottom: 88px;
}
.px-11 {
  padding-top: 88px;
  padding-bottom: 88px;
}
@media (max-width: 768px) {
  .p-sm-11 {
    padding: 88px;
  }
  .py-sm-11 {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  .pl-sm-11 {
    padding-left: 88px;
  }
  .pb-sm-11 {
    padding-bottom: 88px;
  }
  .pr-sm-11 {
    padding-right: 88px;
  }
  .pt-sm-11 {
    padding-top: 88px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-11 {
  margin: 88px;
}
.mt-11 {
  margin-top: 88px;
}
.mr-11 {
  margin-right: 88px;
}
.mb-11 {
  margin-bottom: 88px;
}
.ml-11 {
  margin-left: 88px;
}
.my-11 {
  margin-top: 88px;
  margin-bottom: 88px;
}
@media (max-width: 768px) {
  .my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-11 {
    margin: 88px;
  }
  .mt-sm-11 {
    margin-top: 88px;
  }
  .mr-sm-11 {
    margin-right: 88px;
  }
  .mb-sm-11 {
    margin-bottom: 88px;
  }
  .ml-sm-11 {
    margin-left: 88px;
  }
  .my-sm-11 {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

.p-12 {
  padding: 96px;
}
.pt-12 {
  padding-top: 96px;
}
.pr-12 {
  padding-right: 96px;
}
.pb-12 {
  padding-bottom: 96px;
}
.pl-12 {
  padding-left: 96px;
}
.py-12 {
  padding-top: 96px;
  padding-bottom: 96px;
}
.px-12 {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media (max-width: 768px) {
  .p-sm-12 {
    padding: 96px;
  }
  .py-sm-12 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .pl-sm-12 {
    padding-left: 96px;
  }
  .pb-sm-12 {
    padding-bottom: 96px;
  }
  .pr-sm-12 {
    padding-right: 96px;
  }
  .pt-sm-12 {
    padding-top: 96px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-12 {
  margin: 96px;
}
.mt-12 {
  margin-top: 96px;
}
.mr-12 {
  margin-right: 96px;
}
.mb-12 {
  margin-bottom: 96px;
}
.ml-12 {
  margin-left: 96px;
}
.my-12 {
  margin-top: 96px;
  margin-bottom: 96px;
}
@media (max-width: 768px) {
  .my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-12 {
    margin: 96px;
  }
  .mt-sm-12 {
    margin-top: 96px;
  }
  .mr-sm-12 {
    margin-right: 96px;
  }
  .mb-sm-12 {
    margin-bottom: 96px;
  }
  .ml-sm-12 {
    margin-left: 96px;
  }
  .my-sm-12 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.p-13 {
  padding: 104px;
}
.pt-13 {
  padding-top: 104px;
}
.pr-13 {
  padding-right: 104px;
}
.pb-13 {
  padding-bottom: 104px;
}
.pl-13 {
  padding-left: 104px;
}
.py-13 {
  padding-top: 104px;
  padding-bottom: 104px;
}
.px-13 {
  padding-top: 104px;
  padding-bottom: 104px;
}
@media (max-width: 768px) {
  .p-sm-13 {
    padding: 104px;
  }
  .py-sm-13 {
    padding-top: 104px;
    padding-bottom: 104px;
  }
  .pl-sm-13 {
    padding-left: 104px;
  }
  .pb-sm-13 {
    padding-bottom: 104px;
  }
  .pr-sm-13 {
    padding-right: 104px;
  }
  .pt-sm-13 {
    padding-top: 104px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-13 {
  margin: 104px;
}
.mt-13 {
  margin-top: 104px;
}
.mr-13 {
  margin-right: 104px;
}
.mb-13 {
  margin-bottom: 104px;
}
.ml-13 {
  margin-left: 104px;
}
.my-13 {
  margin-top: 104px;
  margin-bottom: 104px;
}
@media (max-width: 768px) {
  .my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-13 {
    margin: 104px;
  }
  .mt-sm-13 {
    margin-top: 104px;
  }
  .mr-sm-13 {
    margin-right: 104px;
  }
  .mb-sm-13 {
    margin-bottom: 104px;
  }
  .ml-sm-13 {
    margin-left: 104px;
  }
  .my-sm-13 {
    margin-top: 104px;
    margin-bottom: 104px;
  }
}

.p-14 {
  padding: 112px;
}
.pt-14 {
  padding-top: 112px;
}
.pr-14 {
  padding-right: 112px;
}
.pb-14 {
  padding-bottom: 112px;
}
.pl-14 {
  padding-left: 112px;
}
.py-14 {
  padding-top: 112px;
  padding-bottom: 112px;
}
.px-14 {
  padding-top: 112px;
  padding-bottom: 112px;
}
@media (max-width: 768px) {
  .p-sm-14 {
    padding: 112px;
  }
  .py-sm-14 {
    padding-top: 112px;
    padding-bottom: 112px;
  }
  .pl-sm-14 {
    padding-left: 112px;
  }
  .pb-sm-14 {
    padding-bottom: 112px;
  }
  .pr-sm-14 {
    padding-right: 112px;
  }
  .pt-sm-14 {
    padding-top: 112px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-14 {
  margin: 112px;
}
.mt-14 {
  margin-top: 112px;
}
.mr-14 {
  margin-right: 112px;
}
.mb-14 {
  margin-bottom: 112px;
}
.ml-14 {
  margin-left: 112px;
}
.my-14 {
  margin-top: 112px;
  margin-bottom: 112px;
}
@media (max-width: 768px) {
  .my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-14 {
    margin: 112px;
  }
  .mt-sm-14 {
    margin-top: 112px;
  }
  .mr-sm-14 {
    margin-right: 112px;
  }
  .mb-sm-14 {
    margin-bottom: 112px;
  }
  .ml-sm-14 {
    margin-left: 112px;
  }
  .my-sm-14 {
    margin-top: 112px;
    margin-bottom: 112px;
  }
}

.p-15 {
  padding: 120px;
}
.pt-15 {
  padding-top: 120px;
}
.pr-15 {
  padding-right: 120px;
}
.pb-15 {
  padding-bottom: 120px;
}
.pl-15 {
  padding-left: 120px;
}
.py-15 {
  padding-top: 120px;
  padding-bottom: 120px;
}
.px-15 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 768px) {
  .p-sm-15 {
    padding: 120px;
  }
  .py-sm-15 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pl-sm-15 {
    padding-left: 120px;
  }
  .pb-sm-15 {
    padding-bottom: 120px;
  }
  .pr-sm-15 {
    padding-right: 120px;
  }
  .pt-sm-15 {
    padding-top: 120px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-15 {
  margin: 120px;
}
.mt-15 {
  margin-top: 120px;
}
.mr-15 {
  margin-right: 120px;
}
.mb-15 {
  margin-bottom: 120px;
}
.ml-15 {
  margin-left: 120px;
}
.my-15 {
  margin-top: 120px;
  margin-bottom: 120px;
}
@media (max-width: 768px) {
  .my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-15 {
    margin: 120px;
  }
  .mt-sm-15 {
    margin-top: 120px;
  }
  .mr-sm-15 {
    margin-right: 120px;
  }
  .mb-sm-15 {
    margin-bottom: 120px;
  }
  .ml-sm-15 {
    margin-left: 120px;
  }
  .my-sm-15 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.p-16 {
  padding: 128px;
}
.pt-16 {
  padding-top: 128px;
}
.pr-16 {
  padding-right: 128px;
}
.pb-16 {
  padding-bottom: 128px;
}
.pl-16 {
  padding-left: 128px;
}
.py-16 {
  padding-top: 128px;
  padding-bottom: 128px;
}
.px-16 {
  padding-top: 128px;
  padding-bottom: 128px;
}
@media (max-width: 768px) {
  .p-sm-16 {
    padding: 128px;
  }
  .py-sm-16 {
    padding-top: 128px;
    padding-bottom: 128px;
  }
  .pl-sm-16 {
    padding-left: 128px;
  }
  .pb-sm-16 {
    padding-bottom: 128px;
  }
  .pr-sm-16 {
    padding-right: 128px;
  }
  .pt-sm-16 {
    padding-top: 128px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}
.m-16 {
  margin: 128px;
}
.mt-16 {
  margin-top: 128px;
}
.mr-16 {
  margin-right: 128px;
}
.mb-16 {
  margin-bottom: 128px;
}
.ml-16 {
  margin-left: 128px;
}
.my-16 {
  margin-top: 128px;
  margin-bottom: 128px;
}
@media (max-width: 768px) {
  .my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}
@media (max-width: 768px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-sm-auto {
    margin: auto;
  }
  .m-sm-16 {
    margin: 128px;
  }
  .mt-sm-16 {
    margin-top: 128px;
  }
  .mr-sm-16 {
    margin-right: 128px;
  }
  .mb-sm-16 {
    margin-bottom: 128px;
  }
  .ml-sm-16 {
    margin-left: 128px;
  }
  .my-sm-16 {
    margin-top: 128px;
    margin-bottom: 128px;
  }
}

.contacts-info {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-between;
}
@media (max-width: 1260px) {
  .contacts-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .contacts-info {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}
.contacts-info > li {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
}
.contacts-info > li .circe-m-regular {
  color: #6B7280;
}
.contacts-info > li > ul {
  display: flex;
  flex-direction: row;
  grid-gap: 24px;
  list-style-type: none;
}
.contacts-info > li > ul > li .icon {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #004de6;
}

.contacts-main-content {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 48px;
}
@media (max-width: 768px) {
  .contacts-main-content {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}
.contacts-main-content .left {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}
.contacts-main-content .left .contacts-radio {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #9CA3AF;
  display: flex;
  width: fit-content;
  max-width: 100%;
}
.contacts-main-content .left .contacts-radio input {
  appearance: none;
}
.contacts-main-content .left .contacts-radio label {
  padding: 12px 16px;
  border-radius: 4px;
  display: block;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}
.contacts-main-content .left .contacts-radio label:not(:first-of-type) {
  margin-left: 4px;
}
.contacts-main-content .left .contacts-radio input:checked + label {
  background-color: #004de6;
  color: #FFFFFF;
}
.contacts-main-content .left > ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}
.contacts-main-content .left > ul li {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
.contacts-main-content .left > ul li.contact-address::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background: url(../static/img/contacts/location.svg) no-repeat;
  background-size: contain;
}
.contacts-main-content .left > ul li.contact-telephone::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background: url(../static/img/contacts/call.svg) no-repeat;
  background-size: contain;
}
.contacts-main-content .left > ul li.contact-mail::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background: url(../static/img/contacts/email.svg) no-repeat;
  background-size: contain;
}

.contacts-department {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  list-style-type: none;
}
@media (max-width: 1260px) {
  .contacts-department {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
}
@media (max-width: 768px) {
  .contacts-department {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}
.contacts-department li {
  display: flex;
  flex-direction: column;
}
.contacts-department li .circe-m-regular + .manrope-2xs {
  margin-top: 4px;
}
.contacts-department li .manrope-2xs + .manrope-2xs {
  margin-top: 16px;
}
.contacts-department li .manrope-2xs + .circe-m-regular {
  margin-top: 16px;
}

.contacts-tour {
  height: 500px;
  width: 100%;
}
.contacts-tour iframe {
  border: 0;
}

.index-links {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
}
.index-links a {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
}

body {
  -webkit-text-size-adjust: none;
}

.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}