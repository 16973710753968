.news-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 68px 24px;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 0;
	}

	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		grid-gap: 16px;
		$parent: &;

		@media (max-width: $mobile) {
			margin: 16px 0;
		}

		&__img {
			@include absolute-container(464, 364);
			border-radius: 4px;
		}

		&__title {
			@include manrope-2xs;
			@include transition-base;

			&:hover {
				color: $primary600;
			}

			@media (max-width: $desktop-small) {
				@include manrope-3xs;
			}

			@media(min-width: $mobile) and (max-width: $tablet) {
				@include manrope-4xs;
			}
		}

		&__date {
			color: $base400;
			@include circe-xl;

			@media (max-width: $tablet) {
				@include circe-m;
			}
		}

		&__badge {
			position: absolute;
			top: 26px;
			left: 26px;

			@media (max-width: $mobile) {
				top: 13px;
				left: 13px;
			}

			.badge {
				@extend %badge--large;
			}
		}

		&:nth-child(3n - 2) {
			#{$parent}__badge {
				.badge {
					@extend %badge--green;
				}
			}
		}

		&:nth-child(3n - 1) {
			#{$parent}__badge {
				.badge {
					@extend %badge--yellow;
				}
			}
		}

		&:nth-child(3n) {
			#{$parent}__badge {
				.badge {
					@extend %badge--pink;
				}
			}
		}
	}
}