.learning {
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-gap: 24px;
	counter-reset: biba;
	padding-bottom: 100px;

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: url(../static/img/other/learning-bg-string.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		@media (max-width: $mobile) {
			transform: rotate(90deg) scale(2);
		}
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 60px;
	}

	&__item {
		counter-increment: biba;
		display: grid;
		text-align: center;

		&__text {
			@include circe-xl;

			@media /* (min-width: $mobile) and  */(max-width: $mobile) {
				@include circe-l;
			}
		}

		&::before {
			content: counter(biba);
			@include manrope-2xl;
			color: $primary600;
		}
	}
}