.program-grid {
	&__header {
		@include circe-l;
		padding-bottom: 14px;
		text-align: center;

		@media (max-width: $mobile) {
			display: none;
		}
	}

	&__body &__row {
		padding: 26px 0;
		border-bottom: 1px solid $base400;

		@media (max-width: $desktop-small) {
			padding: 20px 0;
		}

		@media (max-width: $mobile) {
			padding: 13px 0;
		}

		&:first-child {
			border-top: 1px solid $base400;
		}

		&__cell {
			&:first-child {
				@include manrope-xs;

				@media (max-width: $desktop-small) {
					@include manrope-2xs;
				}

				@media (max-width: $tablet) {
					@include manrope-4xs;
				}

				@media (max-width: $mobile) {
					@include circe-m(true);
				}
			}

			&:nth-child(n + 2) {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.badge {
				white-space: nowrap;
			}
		}
	}

	&__row {
		display: grid;
		grid-template-columns: 10fr 1fr 1fr;
		grid-gap: 12px;

		&__cell {}
	}
}