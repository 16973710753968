.catalog {
	display: flex;
	flex-direction: column;
	grid-gap: 48px;

	@media (max-width: $desktop-small) {
		grid-gap: 24px;
	}

	@media (max-width: $mobile) {
		grid-gap: 16px;
	}

	&__head {
		display: flex;
		align-items: center;
		grid-gap: 16px;

		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: stretch;
		}
	}

	&__search {
		@media (min-width: $mobile) {
			margin-left: auto;
		}

		.custom-input {
			width: 342px;

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	&__title {
		@include manrope-m;

		@media (max-width: $desktop-small) {
			@include manrope-xs;
		}

		@media (min-width: $mobile)and (max-width: $tablet) {
			@include circe-xl(true);
		}

		@media (max-width: $mobile) {
			@include manrope-2xs;
		}
	}

	&__body {
		display: grid;
		grid-template-columns: 1fr 3fr;
		grid-gap: 24px;

		@media (max-width: $tablet) {
			grid-template-columns: 1fr;
		}
	}

	&__footer {
		display: flex;
		justify-content: center;

		@media (min-width: $tablet) {
			grid-column: 2;
		}
	}

	&__filters {
		&__content {
			display: flex;
			flex-direction: column;
			grid-gap: 24px;

			@media (max-width: $mobile) {
				padding-top: 16px;
			}
		}

		&__title {
			@include circe-l;
		}

		&__toggler {
			display: flex;
			grid-gap: 12px;
			align-items: center;
			padding: 8px 16px;
			@include circe-l;
			border-top: 1px solid $base300 ;
			border-bottom: 1px solid $base300 ;

			span {
				flex-grow: 1;
			}

			.icon {
				--size: 16px;
			}

			&::before {
				content: "";
				@extend %icon, %icon--functional__filter;
				--size: 24px;
			}
		}

		&__item {
			&__title {
				@include manrope-2xs;
				display: flex;
				justify-content: space-between;
				align-items: center;
				grid-gap: 12px;
				padding-bottom: 8px;
			}

			&__content {
				display: flex;
				flex-direction: column;
				grid-gap: 16px;
				padding-top: 4px;
				overflow: auto;
				max-height: 424px;

				&__item {
					.custom-input {
						width: 100%;
					}

					.custom-checkbox {
						@extend %custom-checkbox--blue;
					}
				}
			}
		}
	}
}