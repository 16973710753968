.big-person-card {
	padding: 32px;
	display: grid;
	align-items: center;
	grid-template-columns: 2fr 15fr;
	grid-template-rows: min-content min-content 1fr;
	grid-gap: 0 32px;
	background: $base0;

	margin-bottom: 32px;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		padding: 16px;
	}

	&--dark {
		background: $base100;
	}

	&__img {
		@include absolute-container(1, 1);

		@media (min-width: $mobile) {
			grid-column: 1;
			grid-row: 1 / span all;
		}

		@media (max-width: $mobile) {
			margin-bottom: 32px;
		}
	}

	&__name {
		@include circe-2xl(true);
		margin-bottom: 4px;

		@media (max-width: $tablet) {
			@include circe-xl(true);
		}

		@media (min-width: $mobile) {
			grid-column: 2;
		}
	}

	&__post {
		@include circe-l;
		color: $base500;
		margin-bottom: 16px;

		@media (max-width: $tablet) {
			@include circe-m;
		}

		@media (min-width: $mobile) {
			grid-column: 2;
		}
	}

	&__text {
		@include circe-xl;

		@media (max-width: $desktop-small) {
			@include circe-l;
		}

		@media (max-width: $tablet) {
			@include circe-m;
		}

		@media (min-width: $mobile) {
			grid-column: 2;
		}
	}
}

.big-person-cards {
	display: grid;
	grid-gap: 32px;
}

.big-person-card:last-child {
	margin-bottom: 0;
}