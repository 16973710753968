.info-page {
	display: grid;
	grid-template-columns: 5fr 9fr;
	grid-gap: 140px;

	@media (max-width: $desktop-small) {
		grid-gap: 48px;
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 24px;
	}

	&__left {}

	&__right {
		@extend %text-content;
	}

	&__nav {
		display: flex;
		flex-direction: column;
		grid-gap: 32px;

		@media (max-width: $desktop-small) {
			grid-gap: 16px;
		}

		@media (max-width: $mobile) {
			grid-gap: 8px;
		}

		&__item {
			text-decoration: underline;
			@include circe-2xl;

			&:hover {
				text-decoration: none;
			}

			@media (max-width: $desktop-small) {
				@include circe-l;
			}

			&.is-active {
				color: $primary600;
				@include circe-2xl(true);

				@media (max-width: $desktop-small) {
					@include circe-l(true);
				}
			}
		}
	}
}