.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 0;

	padding-top: 32px;

	&__item {
		@include circe-m;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			color: $base400;
			cursor: pointer;

			&:hover {
				color: $primary600;
			}

			&::after {
				content: "";
				@extend %icon, %icon--arrow__chevron-right;
				--size: 16px;
				--color: #{$base400};
			}

			&:nth-child(n + 3) {
				@media (max-width: $mobile) {
					&::after {
						display: none;
					}
				}
			}
		}

		&:last-child {
			color: $base800;
			pointer-events: none;
		}

		&:nth-child(n + 4) {
			@media (max-width: $mobile) {
				display: none;
			}
		}
	}
}

.page__header .container .page__header__inner {
	margin-top: 0 !important;
}

@media (max-width: 768px) {
	.breadcrumbs {
	 	 padding-top: 24px;
	}
}
  
.about__page .breadcrumbs {
	margin-bottom: 0;
}