.mobile_menu_popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	@include transition-base;
	background: rgba($color: $base600, $alpha: 0.5);
	z-index: 9999;
	display: flex;
	justify-content: flex-end;
	opacity: 1;

	&:not(.show) {
		opacity: 0;
		pointer-events: none;
	}

	&__inner {
		background: $base0;
		height: 100%;
		overflow: auto;
		width: 100%;
		max-width: 333px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
		z-index: 10000;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 0;

		&__title {
			@include circe-xl(true);
		}
	}

	&__subheader {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__call a {
		@extend %button, %button--large, %button--text, %button--accent__blue;
		padding: 0;
	}

	&__nav {
		&__item {
			border-bottom: 1px solid $base300;
			padding: 8px 0;

			.button {
				align-self: center;
			}

			&__title {
				@include circe-l;
				display: block;
			}

			&__content {
				display: flex;
				flex-direction: column;
				gap: 10px;

				&__link {
					@include circe-m;
				}
			}

			.spoiler {
				&__toggler {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				&__content {
					.mobile_menu_popup__nav__item__content {
						padding-top: 16px;
					}
				}

				&.is-active .spoiler__toggler {
					color: $primary600;
				}
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;

		&__link {
			text-decoration: underline;
			@include circe-s;

			&:hover {
				text-decoration: none;
			}
		}
	}
}