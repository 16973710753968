.tabs {
	&__control {
		input {
			visibility: hidden;
			position: absolute;
			pointer-events: none;
		}

		&__visual {
			cursor: pointer;
			user-select: none;
		}
	}

	&--frame &__controls {
		border: 1px solid $base300;
		border-radius: 4px;
		display: inline-flex;
		gap: 4px;
		padding: 8px;
	}

	&--frame &__control {
		input:not(:checked) ~ .tabs__control__visual {
			@extend %button,
			%button--small,
			%button--text;
		}

		input:checked ~ .tabs__control__visual {
			@extend %button,
			%button--small,
			%button--primary;
		}
	}

	&--row &__controls {
		display: flex;
		align-items: flex-end;
		gap: 8px;
	}

	&--row &__control {
		flex: 1 1 0;
		@include circe-xl(true);
		display: flex;
		flex-direction: column;
		align-items: stretch;
		text-align: center;

		&__visual {
			&::after {
				content: "";
				border-radius: 24px;
				height: 4px;
			}
		}

		input:not(:checked) ~ .tabs__control__visual {
			color: $base400;

			&::after {
				background: $base200;
			}
		}

		input:checked ~ .tabs__control__visual {
			color: #4C4C4C;

			&::after {
				background: #004CE4;
			}
		}
	}

	&__contents {
		input {
			visibility: hidden;
			position: absolute;
			z-index: -9999;

			&:not(:checked) ~ .tabs__content__visual {
				display: none !important;
			}
		}
	}
}