.guarantees {
	display: grid;
	grid-gap: 48px;
	grid-template-columns: repeat(3, minmax(0, 305px));
	justify-content: space-between;

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		grid-gap: 24px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		&__img {
			width: 72px;
			height: 72px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&__text {
			@include circe-xl;
			white-space: initial;

			// max-width: 251px;
			@media (min-width: $mobile) and (max-width: $tablet) {
				@include circe-l;
			}
		}

		.icon {
			--size: 16px;
			--color:#{$base400};

			@media (max-width: $tablet) {
				display: none;
			}
		}
	}
}