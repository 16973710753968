.link {
	font-family: 'Circe';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-decoration: underline;
	color: $base800;
	@include transition-base;

	&:hover {
		text-decoration: none;
	}

	&:hover {
		color: $primary600;
	}

	&:active {
		color: $primary900;
	}
}