.text-card {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	$parent: &;
	height: 100%;
	@media (max-width: $mobile) {
		grid-gap: 8px;
	}

	&__img {
		min-width: 0;

		&--type-1 {
			@include absolute-container(10, 7);

			~ #{$parent}__text {
				@media (max-width: $mobile) {
					@include circe-xl(true);
				}
			}
		}

		&--type-2 {
			@include absolute-container(2, 1);

			@media (max-width: $tablet) {
				@include absolute-container(1, 1);
			}
		}
	}

	&__text {
		@include manrope-2xs;
		min-width: 0;

		&:hover {
			color: $primary600;
		}

		@media (max-width: $desktop-small) {
			@include manrope-3xs;
		}

		@media (max-width: $tablet) {
			@include manrope-4xs;
		}

		@media (max-width: $mobile) {
			@include circe-m(true);
		}
	}
}