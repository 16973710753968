.news-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	grid-gap: 16px;

	&__img {
		@include absolute-container(5, 4);
		border-radius: 4px;
	}

	&__text {
		@include manrope-4xs;

		&:hover {
			color: $primary600;
		}
	}
}