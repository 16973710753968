.custom-input {
	display: grid;
	position: relative;
	width: 100%;
	grid-gap: 8px;

	

	&--icon {
		input {
			--padding-right: calc(var(--padding-left) * 2 + 4px);
		}
	}

	&--tablet-compact &__label {
		@media (max-width: $desktop-small) and (min-width: $mobile) {
			display: none;
		}
	}

	&__icon {
		right: 16px;
		--size: 16px;
		transform: translateY(14px);
		position: absolute;
		grid-row: 1;
		--color:#{$base500};
	}

	&__label {
		order: 1;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		font-family: 'Circe';
		font-style: normal;
		color: inherit;

		~ .custom-input__icon {
			grid-row: 2;
		}
	}

	&__helper {
		order: 3;
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: inherit;
		@include transition-base;
	}

	%base {
		color: $base900;
		--padding-left: 16px;
		width: 100%;
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		background-color: white;
		padding: 0 var(--padding-right, var(--padding-left)) 0 var(--padding-left);
		order: 2;
		outline: none;
		@include transition-base;
		height: 46px;
		border-radius: 4px;
		border-color: $primary600;
		border-style: solid;
		border-width: 1px;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;

		&:disabled {
			background-color: $base50;
			border-color: $base300;

			~ .custom-input__icon {
				--color:#{$base300};
			}
		}

		&:not(:disabled) {

			&:focus,
			&:hover {
				box-shadow: 0 0 3px 2px rgba(34, 73, 237, 0.3);

				~ .custom-input__icon {
					--color:#{$primary600};
				}
			}
		}
	}

	select {
		@extend %base;
	}

	input,
	textarea {
		@extend %base;

		&::placeholder {
			color: $base300;
		}

		&:not(:disabled) {
			&:not(:focus):not(:hover):not(:placeholder-shown) {
				&:valid {
					border-color: $othergreen600;
					background-color: $othergreen50;

					~ .custom-input__helper {
						color: $othergreen600;
					}

					~ .custom-input__icon {
						--color:#{$othergreen600};
					}
				}

				&:invalid {
					border-color: $otherred600;
					background-color: $otherred50;

					~ .custom-input__helper {
						color: $otherred600;
					}

					~ .custom-input__icon {
						--color:#{$otherred600};
					}
				}
			}
		}
	}

	textarea {
		height: 92px;
		resize: none;
		padding: var(--padding-right, var(--padding-left)) var(--padding-left);
	}
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea {
	appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
}

.custom-input-1 {
	display: grid;
	position: relative;
	width: 100%;
	grid-gap: 8px;


	@media (max-width: $mobile) {
		grid-gap: 0;

		input {
			margin-top: var(--grid-gap);
		}
	}

	&--icon {
		input {
			--padding-right: calc(var(--padding-left) * 2 + 4px);
		}
	}

	&--tablet-compact &__label {
		@media (max-width: $desktop-small) and (min-width: $mobile) {
			display: none;
		}
	}

	&__icon {
		right: 16px;
		--size: 16px;
		transform: translateY(14px);
		position: absolute;
		grid-row: 1;
		--color:#{$base500};
	}

	&__label {
		order: 1;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		font-family: 'Circe';
		font-style: normal;
		color: inherit;

		~ .custom-input__icon {
			grid-row: 2;
		}
	}

	&__helper {
		order: 3;
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: inherit;
		@include transition-base;
	}

	%base {
		color: $base900;
		--padding-left: 16px;
		width: 100%;
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		background-color: white;
		padding: 0 var(--padding-right, var(--padding-left)) 0 var(--padding-left);
		order: 2;
		outline: none;
		@include transition-base;
		height: 46px;
		border-radius: 4px;
		border-color: white;
		border-style: solid;
		border-width: 1px;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;

		&:disabled {
			background-color: $base50;
			border-color: $base300;

			~ .custom-input__icon {
				--color:#{$base300};
			}
		}

		&:not(:disabled) {

			&:focus,
			&:hover {
				box-shadow: 0 0 3px 2px rgba(66, 66, 66, 0.3);

				~ .custom-input__icon {
					--color:#{$primary600};
				}
			}
		}
	}

	select {
		@extend %base;
	}

	input,
	textarea {
		@extend %base;

		&::placeholder {
			color: $base300;
		}

		&:not(:disabled) {
			&:not(:focus):not(:hover):not(:placeholder-shown) {
				&:valid {
					border-color: $othergreen600;
					background-color: $othergreen50;

					~ .custom-input__helper {
						color: $othergreen600;
					}

					~ .custom-input__icon {
						--color:#{$othergreen600};
					}
				}

				&:invalid {
					border-color: $otherred600;
					background-color: $otherred50;

					~ .custom-input__helper {
						color: $otherred600;
					}

					~ .custom-input__icon {
						--color:#{$otherred600};
					}
				}
			}
		}
	}

	textarea {
		height: 92px;
		resize: none;
		padding: var(--padding-right, var(--padding-left)) var(--padding-left);
	}
}