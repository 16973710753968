footer {
	margin-top: 64px;
	color: $base0;
	background: $primary900;
	padding: 48px 0;

	@media (max-width: $desktop-small) {
		margin-top: 32px;
		padding: 32px 0;
	}

	@media (max-width: $mobile) {
		padding: 24px 0;
	}
}

.footer {
	display: flex;
	justify-content: space-between;
	grid-gap: 56px;
	@include circe-m;

	& + & {
		margin-top: 24px;
	}

	@media (min-width: $mobile) and (max-width: $desktop-small) {
		display: grid;
		grid-template-columns: 2fr 1fr 1fr 1fr;
		@include circe-s;
		grid-gap: 24px;
	}

	@media (max-width: $mobile) {
		flex-direction: column;
		grid-gap: 0;
	}

	&__logo {
		width: 100%;
		max-width: 220px;
	}

	&__social {
		display: flex;
		grid-gap: 24px;

		&__item {
			--color: #{$base0};
			--size: 40px;

			@media (min-width: $mobile) and (max-width: $tablet) {
				--size: 32px;
			}
		}
	}

	&__img-link {
		display: flex;
		align-items: center;
		grid-gap: 8px;
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 28px;

		@media (min-width: $mobile) and (max-width: $tablet) {
			font-size: 16px;
		}

		@media (min-width: $mobile) {
			white-space: nowrap;
		}

		.icon {
			--size: 24px;
			--color:#{$base0};
		}
	}

	&__column {
		display: flex;
		flex-direction: column;
		grid-gap: 40px;

		@media (min-width: $mobile) and (max-width: $tablet) {
			grid-gap: 32px;
		}

		&:not(:nth-child(5)):not(:nth-child(4)) {
			@media (min-width: $mobile) and (max-width: $desktop-small) {
				grid-row: span 2;
			}
		}

		@media (max-width: $mobile) {
			grid-gap: 0;
		}

		&:nth-child(1) {
			@media (max-width: $mobile) {
				order: 0;
				margin-bottom: 24px;
			}

			.footer__block {
				&:first-of-type {
					@media (max-width: $mobile) {
						margin-bottom: 24px;
					}
				}
			}
		}

		&:nth-child(n+2) {
			@media (max-width: $mobile) {
				order: 2;
			}
		}

		&:nth-child(4) {
			@media (min-width: $mobile) and (max-width: $desktop-small) {
				grid-column: 4;
				grid-row: 2;
			}
		}

		&:last-child {
			@media (max-width: $mobile) {
				order: 1;
				margin-bottom: 24px;
			}

			@media (min-width: $mobile) and (max-width: $desktop-small) {
				grid-column: 4;
				grid-row: 1;

				.button {
					width: 100%;
				}
			}

			.footer__block__content {
				@media (max-width: $mobile) {
					flex-direction: row;
				}
			}

			.footer__block__item {
				display: flex;
				justify-content: flex-end;

				@media (max-width: $mobile) {
					justify-content: flex-start;
				}
			}
		}
	}

	&__block {
		&__title {
			@include manrope-4xs;

			@media (min-width: $mobile) and (max-width: $tablet) {
				@include circe-l(true);
			}
		}

		.spoiler {
			@media (max-width: $mobile) {
				border-bottom: 1px solid $base0;
			}
		}

		.spoiler &__title {
			@media (max-width: $mobile) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 8px 0;

				.icon {
					--color: #{$base0};
				}
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			grid-gap: 20px;

			@media (max-width: $tablet) {
				grid-gap: 16px;
			}

			@media (max-width: $mobile) {
				grid-gap: 8px;
			}

			a:hover {
				text-decoration: underline;
			}
		}

		.spoiler &__content {
			@media (max-width: $mobile) {
				padding: 12px 0;
			}
		}

		&__title {
			@media (min-width: $mobile) {
				margin-bottom: 24px;
			}
		}

		&__item {}
	}

	.button {
		white-space: nowrap;
		@extend %button--theme__blue, %button--accent__white;
	}
}