[data-popup] {
	&:not(.show) {
		pointer-events: none;
	}
}

.popup {
	&__selfcloser {
		cursor: pointer;
	}
}

.popup-controller {
	$parent: &;
	cursor: pointer;


	&--active {
		#{$parent}__initial {
			display: none !important;
		}
	}

	&:not(&--active) {
		#{$parent}__final {
			display: none !important;
		}
	}
}

.right-menu-item {
	height: 24px;
}

.right-menu-tablet {
	height: 24px;
}