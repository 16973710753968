.learning-card {
	&__img {
		width: 100%;

		img {
			width: 100%;
			height: auto;
			object-fit: contain;
			object-position: center;
		}
	}
}