 .header__courses-popup {
 	position: absolute;
 	top: 100%;
	max-height: calc(100vh - var(--header-height));
 	left: 0;
 	background: #fff;
 	width: 100vw;
 	z-index: 2;
 	transition: max-height 0.4s ease;
  	overflow: hidden;
 	box-shadow: 0 3px 3px 1px #00000040;

	 @media (max-width: 768px) {
		.header__courses-popup {
		  display: none;
		}
	  }

 	&:not(.show) {
 		width: calc(100* var(--vw));
 		max-height: 0;
 		pointer-events: none;
		box-shadow: none;
 	}

 	&__inner {
 		@extend %container;
 		display: grid;
 		grid-template-columns: 1fr 3fr;
 		position: relative;
		height: calc(100vh - var(--header-height));

 		&::before {
 			position: absolute;
 			top: 0;
 			left: 50%;
 			transform: translateX(-50%);
 			content: "";
 			height: 1px;
 			background: $primary600;
 			width: 100%;
 		}
 	}

 	&__nav {
 		display: flex;
 		flex-direction: column;
 		border-right: 1px solid $base400;

 		&__inner {
 			flex-basis: 0;
 			flex-grow: 1;
 			overflow: auto;
 			display: flex;
 			flex-direction: column;
 			padding: 19px;
 		}

 		&__item {
 			@include circe-l;
 			padding: 8px 0;
			width: fit-content;

 			&.is-active,
 			&:hover {
 				color: $primary600;
 				@include circe-l(true);
 			}
 		}
 	}

 	&__catalog {
 		padding: 24px 56px 0 0;
		position: relative;
 		
 		max-height: calc(100vh - var(--header-height, 0));
 		min-height: 360px;
 		grid-column: 2;
 		overflow: auto;
 		// opacity: 1;

 		// @include transition-base(opacity);
 		&:not(.is-active) {
 			// opacity: 0;
			display: none;
 			pointer-events: none;
 			position: absolute;
 		}

 		&__tags {
 			display: flex;
 			grid-gap: 12px;
 			align-items: center;
 			justify-content: flex-start;
 			flex-wrap: wrap;

			margin-bottom: 24px;
			padding-left: 32px;
 		}

 		&__categories {
 			// columns: 3;
 			display: grid;
 			grid-template-columns: repeat(3, 1fr);
 			grid-template-rows: min-content min-content 1fr;
 			grid-gap: 24px 48px;

			margin-bottom: 84px;
			padding-left: 32px;

 			&__block {
 				display: flex;
 				flex-direction: column;
 				grid-gap: 20px;

 				&:nth-child(1) {
 					grid-column: 1;
 					grid-row: 1 / span all;
 				}

 				&:nth-child(2) {
 					grid-column: 2;
 					grid-row: 1 / span all;
 				}

 				&:nth-child(n+3) {
 					grid-column: 3;
 				}

 				&__title {
 					@include manrope-4xs;
 					// margin-bottom: 4px;
 				}

 				&__item {
 					display: block;
 					@include circe-m;

 					&:hover {
 						color: $primary600
 					}
 				}

 				&__footer {
 					display: flex;
 					justify-content: flex-end;
 				}
 			}
 		}

 		&__footer {
			position: fixed;
			padding: 24px 56px 24px 0;
			width: calc((1440px * 0.75) + 48px);

			background-color: #fff; 
			bottom: 0;
			border-top: 1px solid #004de6;
			
 			display: flex;
			
 			justify-content: flex-end;
 			column-gap: 24px;

			@media (max-width: 1536px) {
				width: calc(((100vw - 96px) * 0.75) + 48px);
				right: 0;
				padding: 24px 104px 24px 0;
			}
 		}
 	}
}

.header__courses-popup:not(.show) .header__courses-popup__catalog__footer {
	display:none;
}