.modal {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: transform 0.2s ease;
	opacity: 1;
	overflow: hidden;
	background: $base0;
	z-index: 10;

	&:not(.show) {
		opacity: 0;
		pointer-events: none;
	}

	.kurs-group {
		display: grid;
		grid-template-rows: 1fr 1fr 1fr;
		grid-gap: 32px;
		justify-content: center;

		.kurs-group__item {
			display: flex;

			height: 64px;

			@media (max-width: $tablet) {
				height: 56px;
			}

			@media (max-width: $mobile) {
				height: 48px;
			}

			.kurs_check {
				position: relative;
				display: block;
				width: 32px;
				height: 32px;
				top: 50%;
	
				background-color: $otherred100;
				border-radius: 50%;
	
				margin-right: 24px;

				transform: translateY(-50%);
			}

			span {
				text-align: left;

				@media (max-width: $tablet) {
					font-size: 20px;
				}

				@media (max-width: $mobile) {
					font-size: 16px;
				}
			}
		}

		.kurs-group__item:last-child {
			justify-content: center;
			height: 56px;
		}

		.kurs_check {
			width: 32px;
			height: 32px;

			background-color: $otherred100;
			border-radius: 50%;

			margin-right: 24px;
		}
	}

	@media (min-width: 769px) {
		box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
		width: 1000px;
	}

	@media (max-width: $mobile) {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transform: none;
		overflow: visible;
    	transition: none;
	}

	&--mini {
		@media (min-width: $mobile) {
			width: 449px;
		}
	}

	&--mini &__inner {
		grid-gap: 24px;
	}

	&__socials {
		display: flex;
		grid-gap: 16px;
		justify-content: center;

		a {
			display: block;
			width: 40px;
			height: 40px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		grid-gap: 32px;
		padding: 32px;

		@media (max-width: $mobile) {
			grid-gap: 16px;
			padding: 16px;
		}
	}

	&__inner--blue {
		display: flex;
		flex-direction: column;
		grid-gap: 24px;
		padding: 16px 32px 32px;
		background: $primary600;
		color: $primary50;

		@media (max-width: $mobile) {
			grid-gap: 16px;
			padding: 16px;
		}
		
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.icon {
			--size: 32px;
		}
	}

	&__title {
		@include manrope-2xs;

		@media (max-width: $mobile) {
			@include manrope-3xs;
		}
	}

	&__subtitle {
		@include circe-xl;
		color: #979797;

		@media (max-width: $mobile) {
			@include circe-l;
		}
	}

	&__text-content {
		@include circe-l;

		&__title {
			@include circe-2xl(true);
		}

		p,
		ul,
		ol,
		img {
			margin: 16px 0;
		}

		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}
}

.person_modal {
	&__body {
		display: flex;
		flex-direction: column;
		grid-gap: 32px;

		@media (max-width: $mobile) {
			grid-gap: 16px;
		}
	}

	&__imgtext {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 32px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			grid-gap: 16px;
		}

		&__img {
			@include absolute-container(1, 1);
		}

		&__text {
			@include circe-l;
		}
	}

	&__slider {
		display: flex;
		flex-direction: column;
		grid-gap: 24px;
		padding: 16px 0;
		background: $base100;
		overflow: hidden;

		@media (max-width: $mobile) {
			margin: 0 -16px;
			padding: 16px;
		}

		&__title {
			@include manrope-2xs;
			padding: 0 16px;
		}

		&__slider {}
	}
}

.organization_modal {
	width: auto;
	&__body {}

	&__title-img {
		img {
			height: 65px;
			width: auto;
		}
	}

	&__imgtext {
		display: flex;
		flex-wrap: nowrap;
		grid-gap: 32px;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			grid-gap: 16px;
		}

		&__img {
			width: 450px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__text {
			display: flex;
			flex-direction: column;
			grid-gap: 24px;

			&__title {
				@include circe-xl(true);
			}

			&__text {
				width: 450px;
				@include circe-l;
			}
		}
	}
}

.modalPopupKurs {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	@include transition-base;
	opacity: 1;
	overflow: auto;
	background: $base0;
	z-index: 99999;

	&:not(.show) {
		opacity: 0;
		pointer-events: none;
	}

	.kurs-group {
		display: flex;
		flex-direction: column;
		// grid-gap: 32px;
		justify-content: center;

		@media (max-width: $mobile) {
			// grid-gap: 20px;
		}
	
		&__item {
			display: flex;

			height: 64px;

			+ .kurs-group__item {
				margin-top: 32px;

				@media (max-width: $mobile) {
					margin-top: 20px;
				}
			}

			@media (max-width: $tablet) {
				height: 56px;
			}

			@media (max-width: $mobile) {
				height: 48px;
			}
			
			&__check {
				position: relative;
				display: block;
				width: 32px;
				height: 32px;
				top: 50%;
	
				background-color: $otherred100;
				border-radius: 50%;
	
				margin-right: 24px;

				transform: translateY(-50%);

				@media (max-width: $tablet) {
					scale: .9;
					margin-right: 20px;
				}

				@media (max-width: 320px) {
					margin-right: 16px;
				}

			}

			p {
				text-align: left;
				position: relative;
				top: 50%;
				transform: translateY(-50%);

				@media (max-width: $tablet) {
					font-size: 20px;
				}

				@media (max-width: $mobile) {
					font-size: 16px;
				}

				@media (max-width: 320px) {
					font-size: 14px;
					height: 40px;
				}
			}

			&:last-child {
				justify-content: center;
				height: auto;
			}
		}
	}

	@media (min-width: $mobile) {
		
		box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
		width: 1000px;
	}

	@media (max-width: $mobile) {
		box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
		width: 300px;
	}

	@media (max-width: 400px) {
		box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
		width: 80vw;
	}

	@media (max-width: 290px) {
		box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
		width: 90vw;
	}

	&--mini {
		@media (min-width: $mobile) {
			width: 449px;
		}
	}

	&--mini &__inner {
		grid-gap: 24px;
	}

	&__socials {
		display: flex;
		grid-gap: 16px;
		justify-content: center;

		a {
			display: block;
			width: 40px;
			height: 40px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		grid-gap: 32px;
		padding: 32px;

		@media (max-width: $mobile) {
			grid-gap: 16px;
			padding: 16px;
		}
	}

	&__inner--blue {
		display: flex;
		flex-direction: column;
		grid-gap: 24px;
		padding: 16px 32px 32px;
		background: $primary600;
		color: $primary50;

		@media (max-width: $mobile) {
			grid-gap: 8px;
			padding: 16px;
		}
		
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.icon {
			--size: 32px;
		}
	}

	&__title {
		@include manrope-2xs;

		@media (max-width: $mobile) {
			@include manrope-3xs;
		}
	}

	&__subtitle {
		@include circe-xl;
		color: #979797;

		@media (max-width: $mobile) {
			@include circe-l;
		}
	}

	&__text-content {
		@include circe-l;

		&__title {
			@include circe-2xl(true);
		}

		p,
		ul,
		ol,
		img {
			margin: 16px 0;
		}

		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}
}

.modalPopupKurs-body {
	.text--center {
		@media (max-width: $mobile) {
			@include manrope-3xs;
		}		
	}
}