.header__free_popup {
	position: absolute;
	top: calc(100% + 16px);
	left: 50%;
	transform: translateX(-50%);
	padding: 16px;
	background: $base0;
	filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25)) drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15));
	@include transition-base;
	z-index: 10;
	white-space: nowrap;

	&:not(.show) {
		opacity: 0;
		pointer-events: none;
	}

	&.show {
		opacity: 1;
	}

	&__links {
		display: flex;
		flex-direction: column;
		grid-gap: 12px;

		a {
			@include circe-l;

			&:hover {
				color: $primary600;
			}
		}
	}
}