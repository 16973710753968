:root {
	--header-margin: 24px;
	--button-height: 56px;
	--section-padding: 64px;
	--container-padding: 48px;
	--vw: calc(1vw - var(--scrollbar-width) / 100);

	@media (max-width: $desktop-small) {
		--section-padding: 32px;
	}

	@media (max-width: $tablet) {
		--container-padding: 16px;
	}

	@media (max-width: $mobile) {
		--container-padding: 16px;
		--section-padding: 24px;
	}
}