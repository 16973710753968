.form-banner {
	border-radius: 12px;
	padding: 48px;
	display: grid;
	grid-template-columns: 9fr 10fr;
	
	grid-gap: 16px 80px;
	align-items: flex-start;
	$parent: &;

	@media (max-width: $desktop-small) {
		padding: 32px;
	}

	@media (max-width: $tablet) {
		grid-gap: 16px 48px;
	}

	@media (max-width: $mobile) {
		grid-template-columns: 1fr;
		padding: 16px;
		grid-gap: 0;

		* + *:not(.custom-input__label) {
			margin-top: 12px;
		}
	}

	&::before {
		content: "";
		position: absolute;
		bottom: 15px;
		right: 51%;
		width: 480px;
		height: 150px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: $desktop-small) {
			display: none;
		}
	}

	&--type-1 {
		grid-template-rows: min-content auto;
		grid-gap: 16px 16%;
		grid-template-columns: 446fr 668fr;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		#{$parent}__info {
			@media (min-width: $mobile) {
				grid-column: 2;
			}
		}

		#{$parent}__img {
			@media (max-width: $mobile) {
				display: none;
			}

			@media (min-width: $mobile) {
				grid-row: 1 / -1;
			}
		}

		#{$parent}__form {
			align-self: flex-end;
		}

		&::before {
			display: none;
		}
	}

	&__img {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__info {
		display: grid;
		grid-gap: 24px;

		@media (max-width: $mobile) {
			grid-gap: 0;
		}
	}

	&__title {
		@include manrope-m;

		@media (max-width: $desktop-small) {
			@include manrope-s;
		}

		@media (max-width: $tablet) {
			@include manrope-xs;
		}

		@media (max-width: $mobile) {
			@include manrope-2xs;
		}
	}

	&__link {
		@include circe-2xl(true);
		color: var(--accent);

		@media (max-width: $desktop-small) {
			@include circe-xl(true);
		}

		@media (max-width: $tablet) {
			@include circe-m(true);
		}
	}

	&__text {
		@include circe-2xl;

		@media (max-width: $desktop-small) {
			@include circe-xl;
		}

		@media (max-width: $tablet) {
			@include circe-m;
		}
	}

	&__form {
		grid-column: 2;

		@media (max-width: $mobile) {
			grid-column: 1;
		}

		.button {
			margin-left: auto;

			@media (max-width: $mobile) {
				margin-right: auto;
			}

			.icon {
				@extend %icon--arrow__chevron-right;
			}
		}
	}

	&--dark {
		background: $base800;
		color: $base0;

		.form-banner__title {
			color: var(--accent);
		}
	}

	&--light {
		background: $othergreen300;

		.custom-checkbox {
			input {
				&::before {
					border-color: $base900;
				}
			}
		}
	}

	&--blue {
		background: $primary600;
		color: $base0;
	}

	&--accent-green {
		--accent: #{$othergreen300};

		&::before {
			background-image: url(../static/img/form-banner-arrow/green.png);
		}

		.button {
			@extend %button--accent__green, %button--theme__dark;
		}
	}

	&--accent-green-without-image {
		--accent: #{$othergreen300};

		.button {
			@extend %button--accent__green, %button--theme__dark;
		}
	}

	&--accent-blue {
		--accent: #{$primary600};

		.button {
			@extend %button--accent__blue, %button--theme__light;
		}
	}

	&--accent-yellow {
		--accent: #{$secondary900};

		&::before {
			background-image: url(../static/img/form-banner-arrow/yellow.png);
		}

		.button {
			@extend %button--accent__yellow, %button--theme__dark;
		}
	}

	&--accent-pink {
		--accent: #{$otherred300};

		.button {
			@extend %button--accent__pink, %button--theme__light;
		}
	}
}