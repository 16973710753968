.tag {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 12px;
	position: relative;
	--color:#{$primary600};
	border-radius: 4px;
	color: var(--color);
	@include transition-base;
	cursor: pointer;
	user-select: none;
	font-family: 'Circe';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		border: 1px solid var(--color);
	}

	&:hover {
		--color:#{$primary900};
	}

	&:active,
	&.active {
		color: $base0;
		--color:#{$primary600};
		background: var(--color);
	}
}