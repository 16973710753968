.breadcrumbs {
	position: relative;

	@media (max-width: $mobile) {
		// display: none;
		position: static;
	}
}

.header-banner {
	background-size: cover !important;
	background-position: center !important;

	&__content {
		@extend %container;
		--v-padding: 72px;
		display: grid;
		grid-template-columns: 5fr 4fr;
		grid-gap: 32px;
		padding-top: 24px;
		padding-bottom: 32px;
		position: relative;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr !important;
			--v-padding: 16px;
			grid-gap: 16px;
			padding-bottom: 24px;
		}

		@media (max-width: $desktop-small) {
			grid-template-columns: 5fr 3fr;
		}
		
		@media (max-width: $tablet) {
			grid-template-columns: 5fr 4fr;
		}

		&__promo {
			position: absolute;
			top: 0;
			left: 50%;
			min-width: 320px;
			transform: translateX(-50%);
			background: $secondary600;
			display: flex;
			align-items: center;
			grid-gap: 24px;
			padding: 16px;
			border-radius: 4px;

			@media (max-width: $tablet) {
				grid-gap: 12px;
				left: initial;
				transform: none;
				right: 0;
				padding: 12px;
			}

			&__icon {
				.icon {
					--color: #{$base800};
					--size: 48px;

					@media (max-width: $tablet) {
						--size: 32px;
					}
				}
			}

			&__text {
				width: auto;

				p {

					&:first-child {
						@include manrope-3xs;

						@media (max-width: $tablet) {
							@include circe-xl(true);
						}
					}

					&:last-child {
						@include circe-xl;

						@media (max-width: $tablet) {
							@include circe-m;
						}
					}
				}

			}
		}

		&__left {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			grid-gap: 32px;

			@media (max-width: $desktop-small) {
				grid-gap: 24px;
			}

			@media (max-width: $mobile) {
				grid-gap: 16px;
			}

			&__footer {
				margin-top: 48px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				grid-gap: 32px;
				width: 100%;

				@media (max-width: $mobile) {
					margin-top: 0;
				}
			}
		}

		&__right {
			display: flex;
			align-items: flex-start;
			position: relative;

			@media (max-width: $tablet) {
				justify-content: center;
			}

			&:has(.header-banner__content__promo) {
				.header-banner__img {
					@media (min-width: $desktop-small) {
						padding-top: 115px;
					}

					@media (max-width: $desktop-small) {
						padding-top: 75px;
					}

					img {
						@media (max-width: $desktop-small) {
							max-height: 340px;
						}
					}
				}
			}
		}
	}

	&__img {
		img {
			width: 100%;
			height: auto;
		}
	}

	&--img-hide-mobile &__img img {
		@media (min-width: $mobile) {
			display: none !important;
		}
	}

	&__price {
		display: flex;
		align-items: flex-end;
		grid-gap: 16px;

		@media (min-width: $mobile) and (max-width: $tablet) {
			align-items: baseline;
			grid-gap: 8px;
		}

		span {
			&:first-child {
				@include manrope-s;

				@media (max-width: $desktop-small) {
					@include manrope-xs;
				}

				@media (max-width: $tablet) {
					@include manrope-2xs;
				}
			}

			&:not(:first-child) {
				font-family: 'Circe';
				font-style: normal;
				font-weight: 400;
				font-size: 32px;
				line-height: 40px;
				text-decoration: line-through;
				color: $base400;

				@media (max-width: $desktop-small) {
					font-size: 26px;
				}

				@media (min-width: $mobile) and (max-width: $tablet) {
					@include circe-xl;
				}

				@media (max-width: $mobile) {
					@include circe-2xl;
				}
			}
		}
	}

	&__badges {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 16px;
	}

	&__title {
		@include manrope-m;

		@media (max-width: $desktop-small) {
			@include manrope-xs;
		}

		@media (max-width: $tablet) {
			@include manrope-3xs;
			line-height: 140% !important;
		}
	}

	&__subtitle {
		@include circe-2xl;

		@media (max-width: $desktop-small) {
			@include circe-xl;
		}

		@media (max-width: $tablet) {
			@include circe-m;
		}

		@media (max-width: $mobile) {
			@include circe-l;
		}

		ul {
			list-style-type: none;

			li {
				display: flex;

				&::before {
					content: "•";
					margin-right: 12px;
				}
			}
		}
	}

	&__buttons {
		display: flex;
		grid-gap: 16px;

		@media (max-width: $mobile) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			grid-gap: 8px;
		}
	}

	.text-accent {
		color: var(--accent);
	}

	&__links {
		a {
			display: block;
			text-decoration: underline;
			@include circe-2xl;

			&:hover {
				text-decoration: none;
			}

			@media (max-width: $desktop-small) {
				@include circe-l;
			}

			@media (max-width: $mobile) {
				@include circe-m;
			}
		}
	}

	&--accent {
		&__green {
			--accent: #{$othergreen300};

			.button {
				@extend %button--accent__green, %button--theme__dark;
			}
		}

		&__blue {
			--accent: #{$primary600};

			.button {
				@extend %button--accent__blue;
			}
		}

		&__yellow {
			--accent: #{$secondary900};

			.button {
				@extend %button--accent__yellow;
			}
		}

		&__pink {
			--accent: #{$otherred300};

			.button {
				@extend %button--accent__pink;
			}
		}
	}
}

.header-banner__content__promo__text.is-visible {
	display: block;
}

.header-banner1 {
	// background: url(../static/img/header-banner/bg1.png);
	// background-size: cover !important;
	// background-position: center !important;

	@media (max-width: $mobile) {
		background: none !important;
	}

	&__content {
		@extend %container;
		--v-padding: 72px;
		display: grid;
		grid-template-columns: 3fr 4fr;
		grid-gap: 56px;
		padding-top: 24px;
		padding-bottom: 24px;
		position: relative;

		@media (min-width: 1536px) {
			margin-right: 0;
			margin-left: calc((100vw - 1536px) / 2);
			max-width: calc(100vw - ((100vw - 1536px) / 2));

			grid-template-columns: calc((1440px - 56px) / 7 * 3) 1fr;

			// @media (min-width: 1440px) {
			// 	max-width: calc((1440px - 56px) / 7 * 3);
			// }
		}

		@media (max-width: $mobile) {
			grid-template-columns: 1fr !important;
			--v-padding: 16px;
			grid-gap: 16px;
		}

		@media (max-width: $desktop-small) {
			grid-template-columns: 3fr 4fr;
		}
		
		&__promo {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background: $secondary600;
			display: flex;
			align-items: center;
			grid-gap: 24px;
			padding: 16px;
			border-radius: 4px;

			@media (max-width: $tablet) {
				grid-gap: 12px;
				left: initial;
				transform: none;
				right: 0;
				padding: 12px;
			}

			&__icon {
				.icon {
					--color: #{$base800};
					--size: 48px;

					@media (max-width: $tablet) {
						--size: 32px;
					}
				}
			}

			&__text {
				width: auto;

				p {

					&:first-child {
						@include manrope-3xs;

						@media (max-width: $tablet) {
							@include circe-xl(true);
						}
					}

					&:last-child {
						@include circe-xl;

						@media (max-width: $tablet) {
							@include circe-m;
						}
					}
				}

			}
		}

		&__left {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			grid-gap: 32px;

			@media (max-width: $desktop-small) {
				grid-gap: 24px;
			}

			@media (max-width: $mobile) {
				grid-gap: 16px;
			}

			&__footer {
				margin-top: auto;
				margin-bottom: auto;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				grid-gap: 32px;
				width: 100%;
			}
		}

		&__right {
			display: flex;
			align-items: flex-start;
			position: relative;

			@media (max-width: $mobile) {
				justify-content: center;
			}
		}
	}

	&__img {
		img {
			width: 100%;
			height: auto;
		}

		// @media (min-width: $mobile) {
		// 	display: none;
		// }
	}

	&--img-hide-mobile &__img img {
		@media (min-width: $mobile) {
			display: none !important;
		}
	}

	&__price {
		display: flex;
		align-items: flex-end;
		grid-gap: 16px;

		@media (min-width: $mobile) and (max-width: $tablet) {
			align-items: baseline;
			grid-gap: 8px;
		}

		span {
			&:first-child {
				@include manrope-s;

				@media (max-width: $desktop-small) {
					@include manrope-xs;
				}

				@media (max-width: $tablet) {
					@include manrope-2xs;
				}
			}

			&:not(:first-child) {
				font-family: 'Circe';
				font-style: normal;
				font-weight: 400;
				font-size: 32px;
				line-height: 40px;
				text-decoration: line-through;
				color: $base400;

				@media (max-width: $desktop-small) {
					font-size: 26px;
				}

				@media (min-width: $mobile) and (max-width: $tablet) {
					@include circe-xl;
				}

				@media (max-width: $mobile) {
					@include circe-2xl;
				}
			}
		}
	}

	&__badges {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 16px;
	}

	&__title {
		@include manrope-m;

		@media (max-width: $desktop-small) {
			@include manrope-xs;
		}

		@media (max-width: $tablet) {
			@include manrope-3xs;
		}
	}

	&__subtitle {
		@include circe-2xl;

		@media (max-width: $desktop-small) {
			@include circe-xl;
		}

		@media (max-width: $tablet) {
			@include circe-m;
		}

		@media (max-width: $mobile) {
			@include circe-l;
		}

		ul {
			list-style-type: none;

			li {
				display: flex;

				&::before {
					content: "•";
					margin-right: 12px;
				}
			}
		}
	}

	&__buttons {
		display: flex;
		grid-gap: 16px;

		@media (max-width: $mobile) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			grid-gap: 8px;
		}
	}

	.text-accent {
		color: var(--accent);
	}

	&__links {
		a {
			display: block;
			text-decoration: underline;
			@include circe-2xl;

			&:hover {
				text-decoration: none;
			}

			@media (max-width: $desktop-small) {
				@include circe-l;
			}

			@media (max-width: $mobile) {
				@include circe-m;
			}
		}
	}

	&--accent {
		&__green {
			--accent: #{$othergreen300};

			.button {
				@extend %button--accent__green, %button--theme__dark;
			}
		}

		&__blue {
			--accent: #{$primary600};

			.button {
				@extend %button--accent__blue;
			}
		}

		&__yellow {
			--accent: #{$secondary900};

			.button {
				@extend %button--accent__yellow;
			}
		}

		&__pink {
			--accent: #{$otherred300};

			.button {
				@extend %button--accent__pink;
			}
		}
	}
}